import { Box } from "@mui/material";
import { useAppRoute } from "../../nav/AppRoute";
import Drawer from "../../nav/Drawer";
import route from "../../Router";
import clubMenu from "./ClubMenu";
import Guide from "../../guides/Guide";

route.addRoute('club')
    .addUrlPath('page')
    .addUrlPath('subpage')
    .addUrlPath('id')
    .addUrlPath('tab');

function Body() {
    const [route, path, current, menuItem] = useAppRoute(clubMenu);
    return <>{menuItem?.element && menuItem.element()}</>;
}

export default function ClubView() {
    return <>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
            <Drawer open={true} menu={clubMenu} title="Настройки Клуба" />
            <Body />
        </Box>
        <Guide guide="clubMenu" />
    </>;
}
import { DesktopWindows, DirtyLens, Videocam } from '@mui/icons-material';
import { Box, Button, Paper } from '@mui/material';
import { Component } from 'react';
import Whiteboard from '../../whiteboard';
import ChatInfo from '../ChatInfo';
import { model, resolveHub } from '../Model';
import { Message, MessageDto } from '../entities';
import { MessageType } from '../enums';
import '../messenger.css';
import resolveWebRTC, { DeviceType, WebRTCApi } from '../webRTC';
import TextChat from './TextChat';
import StreamChat from './StreamChat';
import { ListenerDestructor } from '../../../models/Observer';

interface Props {
    chatId: number
    onClose?: Function
}

interface State {
    loading: boolean
    info?: boolean
    stream?: boolean
    whiteboard?: boolean
    chat?: any
}

export default class Chat extends Component<Props, State> {
    private hub: any
    private wrtc?: WebRTCApi;
    private _wrtcListener?: ListenerDestructor;

    constructor(p: any) {
        super(p);

        this.state = {
            loading: true
        };

        this.onClose = this.onClose.bind(this);
        this.onStartVideo = this.onStartVideo.bind(this);
        this.onStartCapture = this.onStartCapture.bind(this);
    }

    componentDidMount() {
        resolveHub().then(x => {
            this.hub = x; // ???

            this.wrtc = resolveWebRTC();
            this._wrtcListener = this.wrtc.listen(this.onWrtc.bind(this));

            this.setState({
                chat: model.getChat(this.props.chatId)
            });
        });
    }

    componentWillUnmount() {
        this._wrtcListener && this._wrtcListener();
    // this.wrtc && this.wrtc.stop();
    }

    onWrtc() {
        var chatId = this.wrtc!.getChatId();
        if (!chatId) {
            this.setState({
                stream: undefined
            });
        } else if (this.state.stream !== false) {
            this.setState({
                stream: chatId === this.props.chatId
            });
        }
    }

    toggleStream(type: DeviceType) {
        if (this.wrtc) {
            if (!this.state.chat.chat.activeStreamId) {
                var mes = new Message();
                mes.chatId = this.props.chatId;
                mes.type = MessageType.StreamStarted;

                var message = new MessageDto(mes);

                return this.hub.sendMessage(message)
                    .then((a: any, b: any, c: any) => this.toggleStream(type));
            }

            return this.wrtc.start(this.props.chatId, type).then(x => this.onWrtc());
        }
    }

    onStartWhiteboard(){
        this.setState({
            whiteboard: !this.state.whiteboard
        })
    }

    onStartVideo() {
        this.toggleStream('camera');
    }

    onStartCapture() {
        this.toggleStream('screen');
    }

    onClose() {
        if (!this.props.onClose || (this.props.onClose() !== false)) {
            // this.setState({ chatId: undefined });
        }
    }

    buildTopbar() {
        return <Paper elevation={1} sx={{ display: 'flex', zIndex: 1, alignItems: 'center', borderRadius: 0 }} className="chat-toolbar">
            {/**<IconButton onClick={this.onClose}><ChevronLeft /></IconButton>/**/}
            <h4 onClick={() => this.setState({ info: !this.state.info })}>{this.state.chat.displayName}</h4>
            <Box sx={{ flex: 1 }} />
            <Button startIcon={<DirtyLens />} onClick={this.onStartWhiteboard.bind(this)}>Доска</Button>
            <Button startIcon={<Videocam />} onClick={this.onStartVideo}>Видео</Button>
            <Button startIcon={<DesktopWindows />} onClick={this.onStartCapture}>Экран</Button>
        </Paper>;
    }

    render() {
        if (!this.state?.chat) {
            return <></>;
        }

        return <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
            {this.buildTopbar()}

            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', overflow: 'hidden' }}>
                <TextChat chatId={this.props.chatId} onClose={this.onClose} />

                {this.state.whiteboard ? <Whiteboard chatId={this.props.chatId} /> : null}

                {this.state.stream ? <StreamChat chatId={this.props.chatId} /> : undefined}

                {this.state.info ? <ChatInfo id={this.props.chatId} /> : undefined}
            </Box>
        </Box>;
    }
}
import { AttachFile, Done, Send, Videocam } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Input } from '@mui/material';
import localforage from 'localforage';
import moment from 'moment';
import { Component } from 'react';
import { getUser } from '../../../models/Identity';
import { getTenantId } from '../../../models/Tenant';
import { model, resolveHub } from '../Model';
import { Attachment, Message, MessageDto } from '../entities';
import { MessageType, systemMessageTypes } from '../enums';
import '../messenger.css';

interface Props {
    chatId: number
    onClose?: Function
}

interface State {
    data: MessageDto[],
    loading: boolean
    message?: string
    messageId?: number,
    whiteboard?: boolean
    viewAttachment?: {
        attachment: Attachment,
        url: string
    }
}

export default class TextChat extends Component<Props, State> {
    private hub: any

    private unreadedPending?: number
    private loadPending?: number

    private _onMessageRead?: Function
    private _onMessageReceived?: Function

    private _chat: any
    private _attachment: any

    private _attachInput?: HTMLInputElement
    private _listEl?: HTMLDivElement
    private lastHeight = 0
    private lastScroll?: number

    constructor(p: any) {
        super(p);

        this.state = {
            loading: true,
            data: []
        };

        this.buildMessageRow = this.buildMessageRow.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.attach = this.attach.bind(this);
        this.onAttachInput = this.onAttachInput.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    onMessage(m: MessageDto) {
        m = this.buildVM(m);
        if (this.props.chatId == m.message.chatId) {
            var indx = this.state.data.findIndex(x => x.message.id == m.message.id);
            if (indx >= 0) {
                this.state.data.splice(indx, 1, m);
            } else {
                this.state.data.push(m);
            }

            this.forceUpdate();

            this.scrollBottom();
        }
    }

    componentDidMount() {
        resolveHub().then(x => {
            this.hub = x;
            this.hub.onMessage(this.onMessage.bind(this));
            this.hub.onMessageUpdated(this.onMessage.bind(this));
            this.hub.onMessageDeleted((mid: number) => {
                var indx = this.state.data.findIndex(x => x.message.id == mid);
                if (indx >= 0) {
                    this.state.data.splice(indx, 1);

                    this.forceUpdate();
                }
            });

            this._onMessageRead = this.hub.onMessageRead((cid: number, mid: number) => {
                if (this.props.chatId == cid) {
                    var cdt = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
                    this.state.data
                        .filter(x => !x.message.readTs && x.message.id <= mid)
                        .forEach(x => x.message.readTs = cdt)
                    this.forceUpdate();
                }
            });

            this._onMessageReceived = this.hub.onMessageReceived((cid: number, mid: number) => {
                if (this.props.chatId == cid) {
                    var cdt = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
                    this.state.data
                        .filter(x => !x.message.deliveredTs && x.message.id <= mid)
                        .forEach(x => x.message.deliveredTs = cdt)
                    this.forceUpdate();
                }
            });

            this.loadData();
        });
    }

    componentWillUnmount() {
        this._onMessageRead && this._onMessageRead();
        this._onMessageReceived && this._onMessageReceived();
    }

    buildVM(mdto: MessageDto) {
        var d = mdto.message;
        if (!(mdto instanceof MessageDto)) {
            mdto = new MessageDto((mdto as MessageDto).message, (mdto as MessageDto).attachment);
        }

        d.dt = d.ts;
        d.ts = moment(d.ts, 'YYYY-MM-DDTHH:mm:ss', 'UTC').local().format('HH:mm:ss');
        d.readDate = d.readDate && moment(d.readTs, 'YYYY-MM-DDTHH:mm:ss', 'UTC').local().format('YYYY-MM-DDTHH:mm:ss');
        if (!d.attachment) {
            d.attachment = mdto.attachment;
        }

        return mdto;
    }

    loadData() {
        const loadPending = -this.state.data.map(x => -x.message.id).sort()[0] || undefined;
        if (this.loadPending && this.loadPending == loadPending) {
            return;
        }

        this.setState({ loading: true });

        this._chat = this._chat || model.getChat(this.props.chatId);

        this.loadPending = loadPending || 0;

        const storageKey = 'chat-' + getTenantId() + '-' + getUser().id + '-' + this.props.chatId;
        return localforage.getItem(storageKey)
            .then(stored => {
                if (!stored) {
                    stored = [];
                    localforage.setItem(storageKey, stored);
                }

                return this.hub
                    .messageList(this.props.chatId, 60, loadPending)
                    .then((x: any) => {
                        x.messages.sort((x: any, y: any) => x.ts > y.ts ? -1 : 1)
                            .map((d: any) => {
                                this.state.data.splice(0, 0, this.buildVM(d));
                                return d;
                            });

                        if (x.messages.length < 60) {
                            this.loadPending = Math.min(...x.messages.map((x: any) => x.message.id));
                        }

                        this.setState({
                            loading: false
                        }, () => this.loadPending ? this.applyHeight() : this.scrollBottom());
                    });
            });
    }

    scrollBottom() {
        if (this._listEl) {
            this._listEl.scrollTo(0, 99999999);
            this.onListScroll({ target: this._listEl });
        }
    }

    applyHeight() {
        if (this._listEl && (this.lastHeight != this._listEl?.scrollHeight)) {
            this._listEl?.scrollTo(0, this.lastScroll || 0 - this.lastHeight + this._listEl?.scrollHeight);
            this.lastHeight = this._listEl?.scrollHeight;
        }
    }

    sendMessage() {
        var m = this.state.message;
        if (!this._attachment) {
            if (!m || !(m = m.trim())) {
                return;
            }
        }

        var mes = new Message();
        mes.text = this.state.message;
        mes.chatId = this.props.chatId;

        var message = new MessageDto(mes, this._attachment);
        if (this.state.messageId) {
            message.message.id = this.state.messageId;
        }

        this.hub[this.state.messageId ? 'updateMessage' : 'sendMessage'](message)
            .then(() => {
                this.setState({
                    message: undefined,
                    messageId: undefined
                });
            });
    }

    attach() {
        if (this._attachInput) {
            this._attachInput.value = '';
            this._attachInput.click();
        }
    }

    onAttachInput(e: HTMLInputElement) {
        this._attachInput = e;
        if (e) {
            e.addEventListener('change', (e: any) => {
                if (e.target.value) {
                    this.hub.getFileUploadUrl().then((url: string) => {
                        const formData = new FormData();
                        formData.append('file0', e.target.files[0]);

                        fetch(url, {
                            method: 'PUT',
                            body: formData,
                            headers: {
                                // 'xauth': getToken()
                            }
                        }).then(x => x.json())
                            .then(fi => {
                                e.target.value = '';

                                if (fi && fi.result) {
                                    this._attachment = fi.result;

                                    this._attachment.title = this._attachment.name;
                                }
                            });
                    });
                }
            });
        }
    }

    onListRef(le: HTMLDivElement) {
        this._listEl = le;
        this.applyHeight();
    }

    onListScroll(e: any) {
        if (!document.hidden) {
            var t = e.target,
                ltb = [t.scrollTop + t.offsetTop];

            this.lastScroll = t.scrollTop;

            ltb.push(ltb[0] + t.offsetHeight);

            var unreaded = 0;
            t.childNodes.forEach((r: any) => {
                if (r.offsetTop > ltb[0] &&
                    (r.offsetTop + r.offsetHeight) < ltb[1] &&
                    r.classList.contains('unreaded') &&
                    !r.classList.contains('message-self')) {
                    unreaded = parseInt(r.getAttribute('data-id'));
                }
            });

            if (unreaded) {
                this.unreadedPending = unreaded;
                requestAnimationFrame(() => {
                    if (this.unreadedPending == unreaded) {
                        this.hub
                            .read(this.props.chatId, unreaded)
                            .then(() => {
                                this._chat.member.lastReaded = unreaded || 0;
                                this.forceUpdate();
                            });
                    }
                });
            }

            if (t.scrollTop < 150) {
                this.loadData();
            }
        }
    }

    getFileDownloadUrl(att: Attachment, fn: Function) {
        return this.hub.getFileDownloadUrl(att.provider, att.fileKey, att.title).then(fn);
    }

    downloadFile(att: Attachment) {
        return this.getFileDownloadUrl(att, (url: string) => window.open(url.replace('~', this.hub.endpoint)))
    }

    getMessageIcon(x: MessageDto) {
        switch (x.message.type) {
            case MessageType.StreamStarted:
                return <Videocam color="success" />;

            case MessageType.StreamEnded:
                return <Videocam color="error" />;
        }
    }

    buildMessageRow(x: MessageDto) {
        const cn = ['message-row'],
            mem = this._chat.member,
            isSystem = systemMessageTypes.indexOf(x.message.type) >= 0,
            isSelf = x.message.senderId == mem.userId;

        if (isSelf) {
            cn.push('message-self');
        } else if ((mem.lastReaded || 0) < x.message.id) {
            cn.push('unreaded');
        }

        const sx: any = {};

        if (isSystem) {
            cn.push('message-system');
            sx.backgroundColor = 'info.light';
            sx.color = 'white';
        } else if (isSelf) {
            sx.backgroundColor = 'secondary.main';
        } else {
            sx.backgroundColor = 'white';
            sx.outlineColor = 'secondary.main';
        }

        var card = <Box className="message" sx={sx}>
            {x.attachment ? <div className="img">
                {x.attachment.thumbnailUrl ?
                    <><img
                        src={x.attachment.thumbnailUrl.replace('~', this.hub.endpoint)}
                        onClick={() => this.getFileDownloadUrl(x.attachment!, (url: string) => this.setState({ viewAttachment: { attachment: x.attachment!, url } }))}
                        title={x.attachment.title} />
                        <span className="img-title">{x.attachment.title}</span>
                    </> :
                    <Button startIcon={<AttachFile />} onClick={() => this.downloadFile(x.attachment!)}>{x.attachment.title}</Button>}
            </div> : null}
            {this.getMessageIcon(x)}
            {x.message.text ? x.message.text.split('\n').map((x, i) => i > 0 ? [<br key={'br' + i} />, x] : x) : x.message.text}
            <span key="dt" className="message-status" title={moment(x.message.dt).toLocaleString()}>
                {x.message.ts}
                {isSelf && x.message.deliveredTs && !isSystem ? <Done color="success" /> : null}
                {isSelf && x.message.readTs && !isSystem ? <Done color="success" /> : null}
            </span>
        </Box>;

        if (!x.message.isDeleted && isSelf && (x.message.readTs || x.message.deliveredTs)) {
            var onClick: React.MouseEventHandler<HTMLButtonElement>;
            if (this.state.messageId == x.message.id) {
                cn.push('mdc-list-item mdc-list-item--selected');
                onClick = e => this.setState({ message: '', messageId: undefined });
            } else {
                onClick = e => this.setState({ message: x.message.text, messageId: x.message.id });
            }

            card = <div className="status-wrapper">
                {/**}<Button startIcon={<Edit />} onClick={onClick} />
                <Button startIcon={<Delete />} onClick={e => this.hub.deleteMessage(x.message.chatId, x.message.id)} />{/**/}
                {card}
            </div>;
        }

        return <Box key={'mes' + x.message.id} data-id={x.message.id} className={cn.join(' ')} message-type={x.message.type}>
            {card}
            {x.message.readDate ? null : <div />}
        </Box>
    }

    buildAttachmentView() {
        if (this.state.viewAttachment) {
            return <Dialog open={true} maxWidth="xl">
                <DialogContent>
                    <img src={this.state.viewAttachment.url.replace('~', this.hub.endpoint)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.downloadFile(this.state.viewAttachment!.attachment)}>Скачать</Button>
                    <Button onClick={() => this.setState({ viewAttachment: undefined })}>Закрыть</Button>
                </DialogActions>
            </Dialog>;
        }
    }

    onClose() {
        if (!this.props.onClose || (this.props.onClose() !== false)) {
            // this.setState({ chatId: undefined });
        }
    }

    render() {
        var messageRows = this.state.data.map(this.buildMessageRow);
        if (this.state.data.length) {
            var unreadedIndx = this._chat.member.lastReaded ? this.state.data.findIndex(x => x.message.id == this._chat.member.lastReaded) : -1;
            if (this.state.data.find((x, i) => i > unreadedIndx && x.message.senderId != this._chat.member.userId)) {
                messageRows.splice(unreadedIndx + 1, 0, <div key="unreaded-separator" className="unreaded-separator">Непрочитанные сообщения</div>);
            }
        }

        return <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', overflow: 'hidden' }}>
            <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }} className="chat">
                <Box sx={{ flex: 1, backgroundColor: 'secondary.light' }} className="message-list" ref={this.onListRef.bind(this)} onScroll={this.onListScroll.bind(this)}>
                    {messageRows}
                </Box>

                <Input
                    multiline={true}
                    onKeyPress={e => {
                        if (e.which == 13 && !e.shiftKey) {
                            this.sendMessage();
                        }
                    }}
                    sx={{ borderTop: '1px solid divider' }}
                    startAdornment={<IconButton onClick={e => this._attachInput && ((this._attachInput.value = '') || this._attachInput.click())}>
                        <AttachFile />
                        <input type="file" style={{ display: 'none' }} ref={this.onAttachInput} />
                    </IconButton>}
                    endAdornment={<IconButton onClick={this.sendMessage}><Send /></IconButton>}
                    onChange={e => this.setState({ message: e.target.value })}
                    value={this.state.message || ''} />
            </Box>

            {this.buildAttachmentView()}
        </Box>;
    }
}
import { ColumnData } from '../../components/data/DataTable';
import { EducationGroupListDto } from '../../models/Types';
import './groups.css'
import { Badge, LocationOn, School } from '@mui/icons-material';

export function cellSx(style?:any) {
    return { fontSize: '.8rem', py: 0, ...style };
}

export const groupColumns: ColumnData<EducationGroupListDto>[] = [{
    label: 'Наименование',
    name: 'name',
    filter: true,
    routerLink: true,
    sx: () => {
        return { py: .75 };
    },
    routerParams: p => {
        p.push(['clientId']);
        p.push(['hideForm']);
        return p;
    }
}, {
    label: 'Расписание текстом',
    name: 'scheduleText',
    sx: () => cellSx({ fontWeight: 'bold'}),
    filter: true
}, {
    label: 'Учащихся',
    name: 'count',
    filter: true,
    sx: cellSx,
    renderer: val => val + ' человек'
}, {
    label: 'Программа',
    name: 'program',
    filter: true,
    sx: () => cellSx({ color: 'error.main'}),
    renderer: val => val ? <><School sx={styles.listIcon} color="disabled" /> {val}</> : undefined,
}, {
    label: 'Преподователь',
    name: 'teacher',
    sx: cellSx,
    filter: true,
    renderer: val => val ? <><Badge sx={styles.listIcon} color="disabled" /> {val}</> : undefined,
}, {
    label: 'Локация',
    name: 'location',
    sx: cellSx,
    filter: true,
    renderer: val => val ? <><LocationOn sx={styles.listIcon} color="disabled" /> {val}</> : undefined,
}];

const styles = {
    listIcon: {
        height: '.75em',
        width: 'auto',
        mr: .5
    }
}
import { Call, Mail } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import Logo from "../../icons/Logo";
import Proxy from "../../models/Proxy";
import { Person, Contact } from "../../models/Types";

function getIcon(c: Contact) {
    switch (c.type) {
        case 'phone': return <Call color="error" sx={{ marginRight: 1 }} />
        case 'email': return <Mail color="error" sx={{ marginRight: 1 }} />;
    }
}

export interface PersonUserProps {
    typeName: React.ReactNode
    client?: Person
    contacts: Contact[]
    onCreate: (userId: number) => void
    onAddClick?: () => void
}

export function PersonUser({  typeName, contacts, client, onCreate, onAddClick }: PersonUserProps) {
    const [key, setKey] = useState<number[]>([]);
    const [edit, setEdit] = useState(false);
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [lp, setLp] = useState<{ userName: string, password: string }>();

    const theme = useTheme();

    let cs = contacts?.filter(x => x.type == 'phone' || x.type == 'email');
    if (!client || !client.id) {
        return <>
            <Box sx={styles.box}>
                <Logo style={{ float: 'left', height: '3em', marginRight: '1rem' }} color={theme.palette.grey[500]} />
                <Typography color="grey">После создания <b>{typeName}</b> может получить пользовательский доступ в ваш Клуб</Typography>
                {cs?.length ?
                    undefined :
                    <Typography variant="body2" color="grey">
                        Для приглашения пользователя
                        необходимо <Link color="primary" style={{ cursor: 'pointer' }} onClick={onAddClick}>задать</Link> хотя бы
                        один E-Mail/Телефон в разделе <b>Контакты</b>
                    </Typography>}

            </Box>
        </>;
    }

    function handleEdit() {
        setEdit(true);
    }

    function handleSend() {
        setSending(true);
        Proxy.post('PersonCreateUser', {
            id: client?.id,
            contacts: contacts
                .filter(x => x.type == 'phone' || x.type == 'email')
                .filter((x, i) => key.indexOf(i) >= 0)
        }).then(x => {
            if (x.success) {
                client && (client.userId = x.id);
                onCreate(x.id);
                setSent(true);
                setLp(x.result);
            }
        });
    }

    function handleChecked(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        var i = parseInt(e.target.name);
        if (checked) {
            setKey([...key, i])
        } else {
            setKey(key.filter(x => x != i));
        }
    }

    if (client.userId) {
        return <Box sx={styles.box}>
            <Logo style={{ float: 'left', height: '3em', marginRight: '1rem' }} />
            <Typography color="error">Участник ВКлубе</Typography>
            <Typography color="error" variant="body2" sx={{ whiteSpace: 'nowrap' }}>Может использовать Личный кабинет</Typography>
        </Box>;
    } else if (sent) {
        return <>
            <Typography color="error" variant="button" sx={styles.box}><Logo style={{ height: '1em' }} /> Приглашение выслано</Typography>
            <Dialog open={!!lp}>
                <DialogTitle>Пользователь успешно создан</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div>Логин: {lp?.userName}</div>
                        <div>Пароль: {lp?.password}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLp(undefined)}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </>
    } else {
        if (edit) {
            return <Card elevation={5}
                sx={{ position: 'absolute', bottom: -1, zIndex: 1/**, backgroundColor: 'secondary.main' /**/ }}
                style={{ width: 'calc(100% + .5rem)', marginLeft: '-.25rem', marginBottom: -3 }}>
                <CardContent>
                    <table style={{ width: '100%', maxWidth: 300 }}>
                        {cs.map((c, i) => <tr key={i}>
                            <td width={10}><Checkbox edge="start" name={i.toString()} disableRipple onChange={handleChecked} /></td>
                            <td><Typography>{c.type == 'phone' ? 'Телефон' : 'E-mail'}</Typography></td>
                            <td><Typography>{c.value}</Typography></td>
                        </tr>)}
                    </table>
                </CardContent>

                <CardActions sx={{ p: 2 }}>
                    <Button onClick={handleSend} disabled={sending} variant="contained">{sending ? 'Приглашаем ВКлуб...' : 'Выслать приглашение'}</Button>
                    {sending ? null : <Button style={{ marginLeft: 'auto' }} onClick={() => setEdit(false)}>Отмена</Button>}
                </CardActions>
            </Card>;
        } else {
            return <>
                <Box sx={styles.box}>
                    <Logo style={{ float: 'left', height: '3em', marginRight: '1rem' }} color={theme.palette.grey[500]} />
                    <Typography color="grey"><b>{typeName}</b> еще не в вашем Клубе</Typography>
                    <Typography variant="body2" color="grey">Введите все известные контактные данные и пригласите в ваш Клуб</Typography>
                    {cs?.length ?
                        undefined :
                        <Typography variant="body2" color="grey">Для приглашения пользователя необходимо задать хотя бы один E-Mail/Телефон в разделе <b>Контакты</b></Typography>}

                </Box>

                {cs?.length ?
                    <Button sx={styles.box}
                        color="error"
                        onClick={handleEdit}
                        startIcon={<Logo style={{ height: '1em' }} />}>
                        Создать Личный кабинет
                    </Button> : null}
            </>;
        }
    }
}

const styles = {
    box: {
        marginTop: 1
    }
}
import { Menu } from '@mui/icons-material';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme, Drawer as MuiDrawer, CSSObject, styled, Theme, DrawerProps as MuiDrawerProps } from '@mui/material';
import { useState } from 'react';
import { isMobile } from '../utils';
import { IAppMenu, useAppRoute } from './AppRoute';
import { useTenant, useUser } from '../models/Hooks';

export const drawerWidth = isMobile() ? '100%' : 220;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: isMobile() ? 0 : theme.spacing(5.5),
    [theme.breakpoints.up('sm')]: {
        width: isMobile() ? 0 : theme.spacing(6.5),
    },
});

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
        '& .MuiListItemIcon-root > svg': {
            width: '2rem',
            height: '2rem'
        },
        '& .MuiTypography-root': {
            whiteSpace: 'normal'
        }
    }),
);

interface DrawerProps extends MuiDrawerProps {
    title: string,
    menu: IAppMenu,
    open: boolean
}

export default function Drawer({ title, menu, open, children, ...props }: DrawerProps) {
    const [op, setOpened] = useState(open);
    const handleDrawerOpen = () => setOpened(true);
    const handleDrawerClose = () => setOpened(false);
    const [route, path, current, currentItem] = useAppRoute(menu);

    const tenant = useTenant();
    const user = useUser();
    const isAdmin = tenant?.ownerId == user.id;

    const mob = isMobile();
    return <StyledDrawer variant="permanent" open={op} PaperProps={{ sx: { position: 'relative' } }} {...props}>
        <List sx={{ padding: 0 }}>
            {mob ? null :
                <ListItem
                    key="hideclose"
                    disablePadding
                    sx={{ display: 'block' }}
                    onClick={op ? handleDrawerClose : handleDrawerOpen}>
                    <ListItemButton sx={styles.listItemButton(op)}>
                        <ListItemIcon sx={styles.listItemIcon(op)}><Menu sx={{ height: '1.3rem!important' }} /></ListItemIcon>
                        <ListItemText secondary={title} secondaryTypographyProps={{ sx: styles.listItemSecondary(op) }} />
                    </ListItemButton>
                </ListItem>}

            {menu
                .filter(x => !x?.tenanted || isAdmin)
                .filter(x => !x || x.icon || x.title)
                .map((x, i) => x?.path !== undefined ? <ListItem key={x.title?.toString()}
                    id={x.id}
                    disablePadding
                    sx={{ display: 'block' }}
                    onClick={() => { mob && handleDrawerClose(); route.setPath(x.path!.replace(/\/\*$/, '').replace(/\/:.*$/, '')); }}>
                    <ListItemButton sx={styles.listItemButton(op)}>
                        <ListItemIcon sx={styles.listItemIcon(op, currentItem == x)}>
                            {x.icon}
                        </ListItemIcon>
                        <ListItemText primary={x.title} primaryTypographyProps={{ sx: styles.listItemText(op, x, currentItem == x) }} />
                    </ListItemButton>
                </ListItem> :
                    (x?.title && op) ?
                        <ListItem id={x.id} key={x.title?.toString()}>
                            <ListItemText secondary={x.title} secondaryTypographyProps={{ sx: styles.listItemSecondary(op) }} />
                        </ListItem> :
                        <Divider key={i} />)}
        </List>

        {children}
    </StyledDrawer>;
}

const styles: { [name: string]: (open: boolean, ...props: any) => React.CSSProperties } = {
    listItemIcon: (open: boolean, current: boolean) => {
        return {
            transition: 'all 225ms',
            minWidth: 0,
            mr: open ? 1.5 : 0,
            opacity: current ? 1 : .8,
            color: current ? 'error.main' : undefined,
            justifyContent: 'center',
        }
    },
    listItemButton: (open: boolean) => {
        return {
            minHeight: 24,
            justifyContent: open ? 'initial' : 'center',
            py: .6,
        }
    },
    listItemText: (open: boolean, item: any, current: boolean) => {
        return {
            transition: 'all 225ms',
            opacity: open ? current ? 1 : .8 : 0,
            maxWidth: open ? 'auto' : 0,
            color: current ? 'error.main' : item?.color,
            fontSize: open ? '1.3rem' : 0,
            fontWeight: current ? undefined : 300
        }
    },
    listItemSecondary: (open: boolean) => {
        return {
            opacity: open ? .7 : 0,
            maxWidth: open ? 'auto' : 0,
            color: 'secondary',
            fontWeight: 300,
            fontSize: '0.9rem'
        }
    }
}
import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material";
import { Check, Close, } from "@mui/icons-material";

export const colorPallette = [
    undefined, '#898989', '#2B2A29', '#57A7B3',
    '#E31E24', '#AE4A84', '#FFED00', '#C5C6C6',
    '#A2D9F7', '#0075A2', '#B8D8BA', '#EF7F1A',
    '#66C3D0', '#5CA595', '#E5097F', '#66C3D0',
];

export default function ColorPicker(props: ToggleButtonGroupProps) {
    return <ToggleButtonGroup exclusive sx={styles.toggleGroup} {...props}>
        {colorPallette.map(c => {
            const selected = (props.value || '') == (c || '');
            return <ToggleButton
                value={c || ''}
                style={{ backgroundColor: c, borderRadius: 0, margin: 1 }}
                sx={styles.toggle}>
                {selected ? <Check color="error" style={styles.check} /> : c ? null : <Close />}
            </ToggleButton>;
        })}
    </ToggleButtonGroup>;
}

const styles = {
    toggleGroup: {
        width: 200,
        height: 200,
        display: 'block',
        backgroundColor: '#fff',
        border: '1px solid #fff',
        overflow: "hidden",
        borderRadius: '.5rem'
    },
    toggle: {
        width: 'calc(25% - 2px)',
        height: 'calc(25% - 2px)',
        borderWidth: 0,
        backgroundColor: 'secondary.main'
    },
    check: {
        filter: 'drop-shadow(rgba(255,255,255) 1px 1px 0px) drop-shadow(rgba(255,255,255) -1px -1px 0px) drop-shadow(rgba(255,255,255) -1px 1px 0px) drop-shadow(rgba(255,255,255) 1px -1px 0px)'
    }
}
export default ({ color, ...props }: any) => {
    color || (color = '#DC3522');
    return <svg viewBox="0 0 7.987 7.987" {...props}>
        <g>
            <g>
                <path fill={color} d="M3.994 0.427c-0.002,0 -0.004,0 -0.006,0 -0.116,0 -0.211,-0.095 -0.211,-0.211 0,-0.117 0.095,-0.211 0.211,-0.211 0.116,0 0.211,0.093 0.212,0.209 0,0 0,0.001 0,0.002 0,0.114 -0.092,0.208 -0.206,0.211l0 0z" />
            </g>
            <g>
                <polygon fill={color} points="4.23,0.806 4.75,0.035 4.666,0 4.175,0.508 3.994,0.508 3.994,0.508 3.813,0.508 3.321,0 3.238,0.035 3.758,0.806 3.754,1.329 3.601,2.599 3.744,2.599 3.994,1.542 4.243,2.599 4.386,2.599 4.233,1.329 " />
            </g>
            <g>
                <path fill={color} d="M3.998 7.562c0,0 0.001,0 0.001,0 0.117,0 0.211,0.095 0.211,0.211 0,0.117 -0.094,0.212 -0.211,0.212 -0.117,0 -0.211,-0.095 -0.211,-0.212 0,0 0,0 0,0 0,-0.116 0.094,-0.21 0.21,-0.211l0 0z" />
            </g>
            <g>
                <polygon fill={color} points="3.758,7.181 3.238,7.951 3.321,7.987 3.813,7.478 3.994,7.478 3.994,7.478 4.175,7.478 4.666,7.987 4.75,7.951 4.23,7.181 4.233,6.658 4.386,5.388 4.243,5.388 3.994,6.444 3.744,5.388 3.601,5.388 3.754,6.658 " />
            </g>
            <g>
                <path fill={color} d="M1.467 1.473c-0.04,0.04 -0.093,0.062 -0.15,0.062 -0.116,0 -0.211,-0.094 -0.211,-0.211 0,-0.116 0.095,-0.211 0.211,-0.211 0.057,0 0.11,0.022 0.15,0.062 0.039,0.04 0.062,0.093 0.062,0.149 0,0.056 -0.023,0.11 -0.062,0.149l0 0z" />
            </g>
            <g>
                <polygon fill={color} points="1.907,1.573 1.73,0.659 1.645,0.693 1.658,1.401 1.53,1.529 1.529,1.529 1.402,1.657 0.694,1.645 0.66,1.73 1.574,1.906 1.94,2.278 2.73,3.285 2.831,3.183 2.261,2.26 3.184,2.831 3.285,2.73 2.279,1.939 " />
            </g>
            <g>
                <path fill={color} d="M6.52 6.513c0.04,-0.039 0.094,-0.062 0.15,-0.062 0.117,0 0.211,0.095 0.211,0.211 0,0.117 -0.094,0.212 -0.211,0.212 -0.056,0 -0.11,-0.023 -0.15,-0.063 -0.039,-0.039 -0.061,-0.093 -0.061,-0.149 0,-0.055 0.022,-0.109 0.061,-0.149l0 0z" />
            </g>
            <g>
                <polygon fill={color} points="6.08,6.414 6.258,7.327 6.342,7.293 6.33,6.585 6.457,6.457 6.457,6.457 6.585,6.33 7.293,6.341 7.327,6.257 6.414,6.08 6.047,5.708 5.257,4.702 5.156,4.803 5.727,5.726 4.803,5.155 4.702,5.257 5.708,6.047 " />
            </g>
            <g>
                <path fill={color} d="M0.425 3.998c0,0.117 -0.094,0.211 -0.211,0.211 -0.117,0 -0.211,-0.094 -0.211,-0.211 0,-0.117 0.094,-0.211 0.211,-0.211 0,0 0.001,0 0.001,0 0.116,0 0.21,0.094 0.21,0.21 0,0 0,0.001 0,0.001l0 0z" />
            </g>
            <g>
                <polygon fill={color} points="0.807,3.758 0.036,3.237 0,3.32 0.509,3.813 0.509,3.993 0.509,3.994 0.509,4.174 0,4.666 0.036,4.75 0.807,4.23 1.329,4.233 2.599,4.386 2.599,4.243 1.543,3.994 2.599,3.744 2.599,3.601 1.329,3.753 " />
            </g>
            <g>
                <path fill={color} d="M7.562 3.988c0,-0.116 0.095,-0.211 0.212,-0.211 0.116,0 0.211,0.095 0.211,0.211 0,0.117 -0.095,0.212 -0.211,0.212 -0.001,0 -0.001,0 -0.001,0 0,0 0,0 0,0 -0.117,0 -0.211,-0.095 -0.211,-0.211 0,0 0,0 0,-0.001l0 0z" />
            </g>
            <g>
                <polygon fill={color} points="7.181,4.23 7.951,4.75 7.987,4.666 7.478,4.174 7.478,3.994 7.478,3.993 7.478,3.813 7.987,3.32 7.951,3.237 7.181,3.758 6.659,3.753 5.388,3.601 5.388,3.744 6.444,3.994 5.388,4.243 5.388,4.386 6.659,4.233 " />
            </g>
            <g>
                <path fill={color} d="M1.473 6.52c0.04,0.04 0.062,0.094 0.062,0.15 0,0.117 -0.094,0.211 -0.211,0.211 -0.116,0 -0.211,-0.094 -0.211,-0.211 0,-0.056 0.022,-0.11 0.062,-0.15 0.04,-0.039 0.093,-0.062 0.149,-0.062 0.056,0 0.11,0.023 0.149,0.062l0 0z" />
            </g>
            <g>
                <polygon fill={color} points="1.573,6.08 0.66,6.257 0.694,6.341 1.402,6.33 1.529,6.457 1.53,6.457 1.658,6.585 1.645,7.293 1.73,7.327 1.907,6.414 2.279,6.047 3.285,5.257 3.184,5.155 2.26,5.726 2.831,4.803 2.73,4.702 1.94,5.708 " />
            </g>
            <g>
                <path fill={color} d="M6.514 1.466c-0.039,-0.039 -0.061,-0.093 -0.061,-0.149 0,-0.116 0.094,-0.21 0.21,-0.21 0.056,0 0.11,0.022 0.149,0.062 0.04,0.039 0.062,0.093 0.062,0.148 0,0.117 -0.094,0.211 -0.211,0.211 -0.056,0 -0.109,-0.022 -0.149,-0.062l0 0z" />
            </g>
            <g>
                <polygon fill={color} points="6.414,1.906 7.327,1.73 7.293,1.645 6.585,1.657 6.457,1.529 6.457,1.529 6.33,1.401 6.342,0.693 6.258,0.659 6.08,1.573 5.708,1.939 4.702,2.73 4.803,2.831 5.727,2.26 5.156,3.183 5.257,3.285 6.047,2.278 " />
            </g>
        </g>
    </svg>;
}
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import './login.css';
import Logo from '../icons/Logo';
import Title from '../icons/Title';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { isAuthentificated, isInit, login, register } from '../models/Identity';
import route from '../Router';
import { useRoute } from '../models/Hooks';
import { useState } from 'react';
import { FormHelperText } from '@mui/material';

route.register('login')

const text: any = {
    login: 'Войти',
    register: 'Зарегистрироваться'
}

export default function Login() {
    const [route, path] = useRoute();
    const [error, setError] = useState<string>();
    const theme = useTheme();

    const mode = route.get('login');

    const submit = (e: any) => {
        const email = e.target.querySelector('[name=email]').value,
            password = e.target.querySelector('[name=password]').value;

        if (mode !== 'register' || (document.location.port != '3000' && document.location.hash != '#allowregister')) {
            login('email', { email, password }).then(x => {
                if (isAuthentificated()) {
                    route.setState('login');
                } else {
                    setError('Неверный логин/пароль');
                }
            });
        } else {
            register(email, password, e.target.querySelector('[name=password2]').value)
                .then(x => {
                    if (isAuthentificated()) {
                        route.setState([['guide', 'welcome'], ['login']]);
                    } else switch (x.result?.errors?.map((x:any) => x.code)[0]) {
                        case 'DuplicateUserName': setError('Пользователь с таким логином уже существует'); break;
                        default: setError('Некорректные данные'); break;
                    }
                });
        }

        setError(undefined);

        e.stopPropagation();
        e.preventDefault();
    }

    let items: React.ReactNode;
    if (isInit()) {
        items = <>
            <FormControl>
                <FormLabel>Логин</FormLabel>
                <OutlinedInput name="email" />
            </FormControl>

            <FormControl>
                <FormLabel>Пароль</FormLabel>
                <OutlinedInput name="password" type="password" />
                {error && mode !== 'register' ? <FormHelperText error>{error}</FormHelperText> : null}
            </FormControl>

            {mode == 'register' ?
                <FormControl>
                    <FormLabel>Подтвердите пароль</FormLabel>
                    <OutlinedInput name="password2" type="password" />
                    {error ? <FormHelperText error>{error}</FormHelperText> : null}
                </FormControl> :
                null}

            <Button color="secondary" variant="contained" type='submit'>{text[mode || 'login']}</Button>

            {/**<Typography fontSize="sm" sx={styles.typography}>
                <a href="#" style={{ color: theme.palette.action.active }}>Восстановить пароль</a>
            </Typography>/**/}

            {mode != 'register' ? <Typography fontSize="sm" sx={styles.typography} style={{ marginTop: 48 }}>Еще не ВКлубе?</Typography> : null}
            <Typography fontSize="sm" sx={styles.typography}>
                <a href="#"
                    onClick={() => route.setState([['login', mode == 'register' ? 'login' : 'register']])}
                    style={{ color: theme.palette.error.main }}>
                    {mode == 'register' ? text.login : text.register}
                </a>
            </Typography>
        </>;
    }

    return <div id='login-wrapper'>
        <form id='login-form' onSubmit={submit}>
            <Logo />
            <Title />

            {items}
        </form>
    </div>;
}

const styles = {
    typography: {
        alignSelf: 'center'
    }
}
import { FormGroup, styled } from "@mui/material";

const RowFormGroup = styled(FormGroup)`
    & {
        display: flex;
        flex-direction: row;
    }

        & > .MuiOutlinedInput-root:not(:last-child) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

            &:not(:hover):not(:active):not(:focus) > .MuiOutlinedInput-root:not(:last-child):not(.Mui-focused) > .MuiOutlinedInput-notchedOutline {
                border-right-color: #fff;
            }

        & > .MuiOutlinedInput-root:not(:first-child),
        & > .MuiOutlinedInput-root:not(:first-child) > * {
            border-top-left-radius: 0!important;
            border-bottom-left-radius: 0!important;
        }
`;

export default RowFormGroup;
import { useEffect, useState } from "react";
import { model } from "../components/messenger/Model";
import route, { UrlRoute } from "../Router";
import { getUser, listenUser } from "./Identity";
import { getTenant, listenTenant } from "./Tenant";
import Observer from "./Observer";

export function useTenant() {
    const [tenant, setTenant] = useState(getTenant);
    useEffect(() => listenTenant(setTenant));
    return tenant;
}

export function useTenantId() {
    const [tenant, setTenant] = useState(getTenant);
    useEffect(() => listenTenant(setTenant));
    return tenant?.id;
}

export function useUser() {
    const [user, setUser] = useState(getUser);
    useEffect(() => listenUser(setUser));
    return user;
}

export function useRoute(): [UrlRoute, string] {
    const [path, setPath] = useState(route.getPath);
    useEffect(() => route.listen(r => setPath(r.getPath())));
    return [route, path];
}

const observers: { [name: string]: Observer } = {};
export function useObserver<TParams>(name: string, params?: TParams): [TParams | undefined, (params?: TParams) => void] {
    if (!observers[name]) {
        observers[name] = new Observer();
    }

    const [p, sp] = useState(params);
    useEffect(() => observers[name].listen('l', (p?: TParams) => sp(p)));
    return [p, p => observers[name].fire('l', [p])];
}
import { Add, FilterAltOffOutlined, FilterAltOutlined, Help } from '@mui/icons-material';
import { Box, Button, IconButton, InputAdornment, OutlinedInput, SxProps, Theme, Typography } from "@mui/material";
import DataTable, { DataTableProps } from './DataTable';
import { genId, isMobile } from '../../utils';
import './dataview.css';
import { BaseHierarchicalEntity, IEntity } from '../../models/Types';
import { buildViewModes, ViewMode } from './ViewModes';
import route from '../../Router';
import ObservableComponent from '../base/ObservableComponent';
import { ListApiRef } from './ApiRef';
import Guide, { guides } from '../../guides/Guide';
import DataTree from './DataTree';

route.register('hideForm', undefined, undefined, x => x && (x.toLowerCase() == 'true' || x == '1'));
route.register('parentId', undefined, undefined, x => x ? parseInt(x) : undefined);

export interface ListViewProps<TListDto extends IEntity> {
    typeId?: string;
    title: string;
    newTitle?: string;
    guide?: string;
    hideAnyFilter?: boolean;
    filterByColumns?: boolean
    titleMultiple?: React.ReactNode;
    modes?: ViewMode<TListDto>[];
    tableProps: DataTableProps<TListDto>;
    sx?: SxProps<Theme>
    className?: string
    emptyContent?: React.ReactNode
    route?: {
        id?: string
        form?: string
        root?: string
        getId?: () => (number | undefined)
    }
}

export interface DataListViewState {
    detailedFiter?: boolean
    anyFilter?: string
    viewMode?: string
    guide?: boolean
    showEmptyContent?: boolean
}

export interface DataListViewProps<TListDto extends IEntity> extends ListViewProps<TListDto> {
    compact?: boolean
    apiRef?: (api?: ListApiRef<TListDto>) => void
}

function newTitle(title: string) {
    if (!title) {
        return title;
    }

    let lc = title[title.length - 1].toLowerCase();
    let newText = 'Новый';
    switch (lc) {
        case 'я': case 'а': newText = 'Новая'; break;
        case 'е': newText = 'Новое'; break;
    }

    return <span><span className="newbutton-newtext">{newText}</span> {title}</span>;
}

export function getId<TListDto extends IEntity>(props: ListViewProps<TListDto>) {
    const id = (props.route?.getId ? props.route.getId() : route.get(props.route?.id || 'id'));
    return id === 'new' ? 0 : (id ? id as number : undefined);
}

export default class DataListView<TListDto extends IEntity, P extends DataListViewProps<TListDto> = DataListViewProps<TListDto>, S extends DataListViewState = DataListViewState> extends ObservableComponent<P, S> {
    protected api?: ListApiRef<TListDto>;
    protected id: string;

    constructor(p: P) {
        super(p);

        this.apiRef = this.apiRef.bind(this);
        this.onAfterLoad = this.onAfterLoad.bind(this);
        this.handleColumnFilter = this.handleColumnFilter.bind(this);

        this.id = genId('data-list-view');
        this.state = {
            viewMode: p.modes?.length ? p.modes[0].mode : undefined
        } as S;
    }

    componentDidMount(): void {
        this._unlisten.push(route.listen(() => this.forceUpdate()));
    }

    protected apiRef(api?: ListApiRef<TListDto>) {
        this.api = api;
        this.props.apiRef && this.props.apiRef(api);
    }

    protected onAfterLoad(data: TListDto[]) {
        this.props.tableProps?.onAfterLoad && this.props.tableProps?.onAfterLoad(data);
        this.setState({ showEmptyContent: !data.length && !!this.props.emptyContent });
    }

    protected buildView() {
        const mode = this.getMode();
        const route = this.props.route && {
            id: this.props.route.id,
            root: this.props.route.root
        }

        if (!mode?.view) {
            const props: DataTableProps<TListDto> = {
                typeId: this.props.typeId,
                showFilter: !!this.state.detailedFiter,
                rowSelector: "routerLink",
                routerParams: routeParams => {
                    routeParams.push(['hideForm', true]);
                    return routeParams;
                },
                ...this.props.tableProps,
                onAfterLoad: this.onAfterLoad,
                route: route,
                selectedId: getId(this.props),
                apiRef: this.apiRef,
                anyFilter: this.state.anyFilter
            };

            switch (mode ? mode.mode : 'list') {
                case 'list':
                    return <DataTable<TListDto>{...props} />;

                case 'tree':
                    return <DataTree<TListDto & BaseHierarchicalEntity> {...((props as any) as DataTableProps<TListDto & BaseHierarchicalEntity>)} />;
            }
        }

        if (mode?.view) {
            return mode.view(this.apiRef);
        }
    }

    protected getMode() {
        return this.props.modes?.find(x => x.mode == this.state.viewMode);
    }

    protected buildAddButton(newTitleText: React.ReactNode, props?: any): React.ReactNode | undefined {
        return <Button
            className={'button-add' + (newTitleText ? '' : ' button-empty-text')}
            color="success"
            variant="contained"
            startIcon={<Add />}
            sx={{ margin: 1 }}
            onClick={() => {
                var pars: [string, any?][] | undefined = [[this.props.route?.id || 'id', 'new']];
                this.props.tableProps.routerParams && (pars = this.props.tableProps.routerParams(pars));
                if (this.getMode()?.mode == 'tree') {
                    (pars || (pars = [])).push(['parentId', route.get('id')]);
                }

                pars && route.setState(pars.concat([['hideForm']]));
            }}
            {...props}>
            {newTitleText}
        </Button>;
    }

    private handleColumnFilter() {
        this.setState({ detailedFiter: !this.state.detailedFiter })
    }

    protected buildAnyFilter() {
        if (this.props.hideAnyFilter !== true) {
            return <OutlinedInput
                placeholder="Поиск по любому полю"
                sx={{ flex: 1, margin: 1 }}
                onChange={e => this.setState({ anyFilter: e.target.value })}
                endAdornment={this.buildFilterByColumns() || undefined}
            />;
        }
    }

    protected buildFilterByColumns(): React.ReactNode | undefined | void {
        if (this.props.filterByColumns !== false) {
            return <InputAdornment position="end">
                {document.body.offsetWidth < 1400 ?
                    <IconButton onClick={this.handleColumnFilter}>
                        {this.state.detailedFiter ? <FilterAltOffOutlined /> : <FilterAltOutlined />}
                    </IconButton> :
                    <Button color={this.state.detailedFiter ? 'primary' : 'inherit'} onClick={this.handleColumnFilter}>
                        фильтр по столбцам
                    </Button>}
            </InputAdornment>;
        }
    }

    protected buildViewModes(modes?: ViewMode<TListDto>[], viewMode?: string, setViewMode?: ((viewMode: string) => void)) {
        return buildViewModes(modes, viewMode, setViewMode);
    }

    protected buildTitle(): React.ReactNode {
        return <Typography color="error" variant="h4" sx={{ fontWeight: 500, fontSize: '2.25rem' }}>
            {this.props.titleMultiple || (this.props.title + 'ы')}
        </Typography>;
    }

    protected buildGuide(): React.ReactNode {
        if (this.props.guide) {
            const steps = guides[this.props.guide];
            if (steps) {
                return <><Guide steps={steps} run={!!this.state.guide} onClose={e => this.setState({ guide: false })} />
                    <IconButton color="primary" onClick={e => this.setState({ guide: true })} ><Help /></IconButton>
                </>;
            }
        }
    }

    protected buildEmptyContent() {
        if (this.props.emptyContent) {
            return <Box sx={{ position: 'absolute', zIndex: 1, alignSelf: 'center' }}>{this.props.emptyContent}</Box>;
        }
    }

    render() {
        const mobile = isMobile();
        const id = getId(this.props);
        if (!mobile || id === undefined || !(id >= 0)) {
            let props = this.props;
            let newTitleText = props.newTitle === undefined ? newTitle(props.title) : props.newTitle;
            let className = this.props.compact || (this.props.compact === undefined && mobile) ? 'datatable-compact' : '';
            props.className && (className += ' ' + props.className);

            return <Box key="view"
                id={this.id}
                sx={{ display: "flex", flexDirection: "column", flex: 1, overflow: 'hidden', position: 'relative', ...props.sx }}
                data-list-view-typeid={this.props.typeId}
                className={className}>
                <div key="toolbar" className='datatable-toolbar'>
                    {this.buildTitle()}
                    {this.buildGuide()}
                    {this.buildViewModes(props.modes, this.state.viewMode, viewMode => this.setState({ viewMode }))}
                    {this.buildAnyFilter()}
                    {this.buildAddButton(newTitleText)}
                </div>
                <div key="table" style={{ flex: 1, overflow: 'hidden', position: 'relative', display: 'flex', justifyContent: 'center' }}>
                    {this.buildView()}
                    {this.state?.showEmptyContent ? this.buildEmptyContent() : null}
                </div>
            </Box>;
        }

        return null;
    }
}
import { Person } from "../../models/Types";
import RemoteCombo, { RemoteComboProps } from "../../components/form/RemoteCombo";

export default function EmployeeCombo({ name, value, onDataLoad, onChange, allowAdd, multiple }: RemoteComboProps<Person>) {
    return <RemoteCombo<Person>
        name={name}
        typeId="Employee"
        allowAdd={allowAdd}
        multiple={multiple}
        value={value}
        onDataLoad={onDataLoad}
        onChange={onChange} />;
}
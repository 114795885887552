import { FormControl, FormLabel, OutlinedInput, SelectChangeEvent, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput } from "../../components/form/TextInput";
import { EventDto, EventParticipant } from "../../models/Types";
import EmployeeCombo from "../employees/EmployeeCombo";
import GroupCombo from "../groups/GroupCombo";
import LocationCombo from "../locations/LocationCombo";
import { PriceField } from "../currency/PriceInput";

const minDate = new Date(2019, 1, 1);

export class EventForm extends AbstractTypedDataForm<EventDto, { params?: any }, { duration?: number }> {
    options: {
        start?: string
        end?: string
    }

    constructor(p: any) {
        super(p);

        this.options = {
            start: p?.params?.start,
            end: p?.params?.end
        };

        this.onTeacherChange = this.onTeacherChange.bind(this);
    }

    componentWillReceiveProps(n: Readonly<{ id: number, params?: any }>, nextContext: any): void {
        this.options.start = n.params?.start;
        this.options.end = n.params?.end;

        const p = this.props;
        if (p.id == n.id && (p.params?.start != this.options.start || p.params?.end != this.options.end)) {
            this.loadData(n.id);
        }

        super.componentWillReceiveProps(n, nextContext);
    }

    clear(): void {
        this.setEntity({
            id: 0,
            start: this.options.start || new Date(),
            duration: 20
        });
    }

    protected getTypeId() {
        return 'Event';
    }

    protected createEntity() {
        return new EventDto();
    }

    protected dataLoaded(response: any) {
        return this.setEntity(response.result);
    }

    protected setEntity(entity: any) {
        var duration: number | undefined;
        var e = entity;
        (this.options.start || e.start) && (e.start = new Date(this.options.start || e.start));
        (this.options.end || e.end) && (e.end = new Date(this.options.end || e.end));

        if (e.start) {
            if (e.end) {
                duration = this.calcDuration(e.start, e.end);
            }
            else if (e.duration) {
                duration = e.duration;
                e.end = this.calcEnd(e.start, e.duration);
            }
        }

        const eventDto = new EventDto()
        Object.assign(eventDto, entity);
        this.setState({
            id: entity.id,
            entity: eventDto,
            duration
        });

        return eventDto;
    }

    protected onTeacherChange(e: SelectChangeEvent<any>, child: React.ReactNode) {
        return this.onChange({
            ...e,
            target: {
                name: 'participants',
                value: this.state.entity.participants.filter(x => x.type != 'Employee')
                    .concat((e.target.value as number[])?.map(v => EventParticipant.Create('Employee', v)))
            }
        });
    }

    calcDuration(s: Date, e: Date) {
        return moment(e).diff(moment(s), 'minute');
    }

    calcEnd(s: Date, d: number) {
        return moment(s).add(d, 'minute').toDate();
    }

    protected buildItems(entity?: EventDto) {
        return <>
            <FormTextInput required={true} label="Наименование" name="name" form={this} entity={entity} />

            <div style={{ display: 'flex' }}>
                <FormControl fullWidth={false}>
                    <FormLabel>Начало</FormLabel>
                    <DateTimePicker
                        minDate={minDate}
                        value={entity?.start || undefined}
                        onChange={value => {
                            this.onFieldChange('start', value);
                            if (value) {
                                if (this.state.duration) {
                                    this.onFieldChange('end', this.calcEnd(value, this.state.duration));
                                } else if (this.state.entity.end) {
                                    this.setState({ duration: this.calcDuration(value, this.state.entity.end) })
                                    return;
                                }
                            }

                            this.forceUpdate();
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ width: 205 }} />} />
                </FormControl>

                <FormControl fullWidth={false} sx={{ flex: 1, mx: 1 }} >
                    <FormLabel>Длительность</FormLabel>
                    <OutlinedInput
                        name="duration"
                        type="number"
                        endAdornment="мин"
                        value={this.state?.duration}
                        onChange={e => {
                            var duration = parseInt(e.target.value) || undefined;
                            if (duration && this.state?.entity?.start) {
                                this.onFieldChange('end', this.calcEnd(this.state.entity.start, duration));
                            }

                            this.setState({ duration });
                        }} />
                </FormControl>

                <FormControl fullWidth={false}>
                    <FormLabel sx={{ textAlign: 'right' }}>Завершение</FormLabel>
                    <DateTimePicker
                        minDate={minDate}
                        value={entity?.end || undefined}
                        onChange={value => {
                            this.onFieldChange('end', value);
                            if (value) {
                                if (this.state.entity.start) {
                                    this.setState({ duration: this.calcDuration(this.state.entity.start, value) })
                                    return;
                                }
                            }

                            this.forceUpdate();
                        }}
                        renderInput={(params) => <TextField {...params} sx={{ width: 205 }} />} />
                </FormControl>
            </div>

            <FormControl>
                <FormLabel>Преподаватели</FormLabel>
                <EmployeeCombo multiple={true} value={entity?.participants?.filter(x => x.type == 'Employee').map(x => x.participantId)} onChange={this.onTeacherChange} />
            </FormControl>
            <FormControl>
                <FormLabel>Группы</FormLabel>
                <GroupCombo name="groupParticipants" multiple={true} value={entity?.groupParticipants} onChange={this.onChange} />
            </FormControl>
            <FormControl>
                <FormLabel>Локация</FormLabel>
                <LocationCombo name="locationId" value={entity?.locationId} onChange={this.onChange} />
            </FormControl>

            <PriceField title="Услуга"
                excludeСurrency={true}
                amount={{ value: entity?.amount, onChange: this.onChange }}
                currency={{ value: entity?.currencyId, onChange: this.onChange }} />
        </>;
    }
}
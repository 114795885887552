import { Checkbox, FormControl, FormControlLabel, FormLabel, OutlinedInput, Typography } from "@mui/material";
import { ClientStatus } from "../../models/Types";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import ColorPicker from "../../components/ColorPicker";
import { FormTextInput } from "../../components/form/TextInput";

export class ClientStatusForm extends AbstractTypedDataForm<ClientStatus> {
    protected getTypeId() {
        return 'PersonStatus';
    }

    protected getTypeName(): React.ReactNode {
        return 'Статус Клиента';
    }

    protected createEntity() {
        return new ClientStatus();
    }

    protected buildItems(entity?: ClientStatus) {
        return <>
            <FormTextInput required={true} label="Наименование" name="name" form={this} entity={entity} />

            <FormControl>
                <FormLabel>Цвет в интерфейсе</FormLabel>
                <ColorPicker value={entity?.color} onChange={(e, value) => { this.onChange({ target: { name: 'color', value } }); this.forceUpdate() }} />
            </FormControl>

            <FormControlLabel
                sx={{ marginTop: 1 }}
                label={<><div>Конечный</div><Typography variant="caption">Отказ клиента от услуг Клуба</Typography></>}
                control={<Checkbox defaultChecked={entity?.isFinished} onChange={(e, value) => this.onChange({ target: { name: "isFinished", value } })} />} />
        </>;
    }
}
import { ViewKanban, ViewKanbanOutlined, ViewListOutlined } from '@mui/icons-material';
import { clientListColumns, ClientListDto } from "./ClientList";
import './person.css';
import ClientBoard from '../../screens/clients/ClientBoard';
import DataView, { ViewProps } from '../../components/data/DataView';
import { ClientForm } from './ClientForm';
import { GroupsTab } from './GroupsTab';
import { Logs } from '../../components/Logs';
import ExchangeOperationsTab from './ExchangeOperationsTab';
import { guides } from '../../guides/Guide';
import AccountTab from './AccountTab';

guides.clients = [
    {
      target: '[data-list-view-typeid="Person"] .datatable-toolbar',
      disableBeacon: true,
      title: 'Клиенты',
      content: <>Раздел <b>Клиенты</b> предназначен для ведения всех клиентов от лида, до заключение договора.</>,
    },
    {
      target: 'th[data-name="statusId"]',
      placementBeacon: 'right-start',
      title: 'Клиенты. Статус',
      content: <>Клиентов можно фильтровать в том числе по <b>Статусам клиента</b></>,
    },
    {
      target: '.datatable-toolbar > [role="group"]',
      placementBeacon: 'right-start',
      title: 'Клиенты. Канбан',
      content: <>Для наглядного разделения <b>Клиентов</b> по <b>Статусам</b> предусмотрен вид <ViewKanban /> Канбан доски
        <video autoPlay={true} playsInline={true} loop={true} style={{maxWidth:'100%'}}>
          <source src="https://app.inclub.pro/video/clientsboard.mp4" type="video/mp4" />
        </video>
      </>,
    }
];

export const clientViewConfig: ViewProps<ClientListDto> = {
    title: "Клиент",
    titleMultiple: 'Клиенты',
    typeId: "Person",
    modes: [{
        mode: 'list',
        text: <ViewListOutlined />
    }, {
        mode: 'board',
        text: <ViewKanbanOutlined />,
        view: () => <ClientBoard />
    }],
    guide:'clients',
    form: [{
        title: 'Клиент',
        element: (id, apiRef, params, setTabApi) => <ClientForm key="main" id={id} apiRef={apiRef} setTabApi={setTabApi} />
    }, {
        title: 'Группы',
        disabled: 'new',
        element: (id, apiRef) => <GroupsTab key="groups" id={id} />
    }, {
        title: 'Лента',
        disabled: 'new',
        element: (id, apiRef) => <Logs refType="Person" id={id} />
    }, {
        title: 'Баланс',
        disabled: 'new',
        element: id => <AccountTab key="Account" id={id} />
    }/**, {
        title: 'Чат',
        element: (id, apiRef) => <Chat key="Chat" chatId={id} />
    }/**, {
        title: 'Операции',
        disabled: 'new',
        element: id => <ExchangeOperationsTab key="ExchangeOperations" id={id} />
    }/**/],
    tableProps: {
        cmd: 'PersonClientList',
        columns: clientListColumns,
        dataParser: ClientListDto.parse
    }
}

export default () => <DataView<ClientListDto> {...clientViewConfig} />
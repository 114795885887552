import { FormControl, FormHelperText, FormLabel, OutlinedInput, OutlinedInputProps } from "@mui/material";
import { ReactNode, useState } from "react";
import { IEntity } from "../../models/Types";
import { AbstractDataForm } from "../data/DataForm";

interface BaseTextInputProps {
    label: string
    required?: boolean
    validator?: (value: any) => string | undefined
    inputProps?: OutlinedInputProps
    children?: ReactNode
}

interface TextInputProps extends BaseTextInputProps {
    name: string | number | symbol
    onChange: (e: any) => void
    value: any
    refValidator?: (validator: (value?: string | undefined) => string | undefined) => void
    component?: (onChange: (e: any) => void, error?: string) => ReactNode
}

interface FormTextInputProps<T extends IEntity> extends BaseTextInputProps {
    name: keyof T
    entity?: T
    form: AbstractDataForm<T>
}

function validate(value: any, props: TextInputProps) {
    return props.validator ? props.validator(value) :
        (props.required && !value ? 'Поле обязательно для заполнения' : undefined);
}

export function BaseInput(props: TextInputProps) {
    const [error, setError] = useState(props.validator && props.validator(props.value));

    var onChange = props.onChange;

    if (props.validator || props.required) {
        let validator = (value?: string) => {
            var err = validate(value, props);
            setError(err);
            return err;
        }

        props.refValidator && props.refValidator(validator);

        onChange = (e: any) => {
            validator(e.target.value);
            props.onChange(e);
        };
    }

    return <FormControl>
        <FormLabel required={props.required}>{props.label}</FormLabel>
        {props.component && props.component(onChange, error)}
        {props.children}
        {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>;
}

export function TextInput(props: TextInputProps) {
    return <BaseInput
        {...props}
        component={props.component || (
            props.children ?
                undefined :
                (onChange, error) => <OutlinedInput
                    name={props.name.toString()}
                    defaultValue={props.value}
                    onChange={onChange}
                    error={!!error}
                    {...props.inputProps} />)} />;
}

export function FormTextInput<T extends IEntity>(props: FormTextInputProps<T>) {
    const e = props.entity || props.form.state?.entity;
    const textFieldProps: TextInputProps = {
        onChange: props.form.onChange,
        value: e && e[props.name]?.toString() || '',
        refValidator: val => props.form.addValidator('__formTextField' + props.name.toString(), props.name, (v?: any) => val(v?.toString())),
        ...props
    }

    return <TextInput {...textFieldProps} />;
}

export function FormNumberInput<T extends IEntity>(props: FormTextInputProps<T>) {
    const e = props.entity || props.form.state?.entity;
    const textFieldProps: TextInputProps = {
        onChange: e => props.form.onChange({ ...e, target: { ...e.target, name: e.target.name, value: e.target.value && parseInt(e.target.value) } }),
        value: e && e[props.name],
        inputProps: {
            type: 'number',
            inputProps: {
                step: 'any',
                ...props.inputProps?.inputProps
            },
            ...props.inputProps
        },
        refValidator: val => props.form.addValidator('__formNumberField' + props.name.toString(), props.name, (v?: any) => val(v?.toString())),
        ...props
    }

    return <TextInput {...textFieldProps} />;
}
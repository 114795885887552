import { Add, Check, SportsSoccerSharp } from "@mui/icons-material";
import { Button, FormControl, FormLabel, IconButton, LinearProgress, Link, OutlinedInput, TextField, Theme, Toolbar, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useState } from "react";
import { LoadingForm } from "../../components/base/LoadingForm";
import RemoteCombo from "../../components/form/RemoteCombo";
import Proxy from "../../models/Proxy";
import { BaseEntity } from "../../models/Types";

class ServiceAgreement extends BaseEntity {
    number!: string
    personId!: number
    groupId!: number
    group!: string
    start?: Date
    end?: Date

    static parse(x: any) {
        const end = x.end && moment(x.end).toDate();
        const start = x.start && moment(x.start).toDate();
        var group = new ServiceAgreement();
        Object.assign(group, x, { start, end });
        return group;
    }
}

interface CardProps {
    group: ServiceAgreement;
    onSave: (group: ServiceAgreement, num: string, date?: Date) => Promise<any>;
    onCancel?: Function
    past?: boolean
}

function GroupCard({ group, onSave, past }: CardProps) {
    var [edit, setEdit] = useState(false);
    var [start, setStart] = useState(group.start);
    var [number, setNumber] = useState(group.number);
    var theme = useTheme();

    const primaryColor = theme.palette.primary.main;
    const errorColor = theme.palette.error.main;
    const editStyle: any = { ...styles.editLink, color: primaryColor };

    function setNumDate() {
        onSave(group, number, start)
            .then(x => {
                if (x.result) {
                    group.start = start;
                    group.number = number;
                    setEdit(false);
                }
            })
    }

    var numDate: React.ReactNode;
    if (past) {
        numDate = <>
            {group.number}
            {group.start && <> от {moment(group.start).format('L')}</>}
        </>;
    } else if (edit) {
        numDate = <>
            <OutlinedInput style={{ width: 75 }} defaultValue={number || group.number} onChange={e => setNumber(e.target.value)} />
            от
            <DatePicker
                value={start || group.start || null}
                onChange={v => setStart(v || undefined)}
                renderInput={(params) => <TextField style={{ width: 150 }} {...params} placeholder=" " />} />
            <IconButton color="success" onClick={setNumDate}><Check /></IconButton>
        </>;
    } else {
        numDate = <>
            <b onClick={() => setEdit(true)} style={editStyle}>{group.number}</b>
            {group.start && <> от <b onClick={() => setEdit(true)} style={editStyle}>{moment(group.start).format('L')}</b></>}
        </>
    }

    return <div key={'num' + edit} style={styles.form(theme)}>
        <Typography color={past ? theme.palette.text.secondary : 'primary'} fontStyle={past ? 'italic' : undefined} variant="h6">{group.group}</Typography>
        <table className="client-group">
            <tbody>
                <tr>
                    <td key="left" style={past ? { color: theme.palette.text.secondary, fontStyle: 'italic' } : undefined}>
                        {group.start && <span>В группе с {moment(group.start).format('L')}</span>}
                        <div>Остаток занятий <b>?</b></div>
                        <div>Абонемент до <b>?</b></div>
                        <div style={past ? { color: theme.palette.text.primary, fontStyle: 'normal' } : edit ? undefined : { margin: '8px 0 10px 0' }}>
                            Договор № {numDate}
                        </div>
                    </td>
                    {edit ? null :
                        <td key="right" className="client-group-actions">
                            <div>
                                <div />
                                <Link href="/" style={{ color: primaryColor }}>Посещения</Link>
                                <Link href="/" style={{ color: primaryColor }}>Расчеты</Link>
                                {past ? null : <Link href="/" style={{ color: errorColor }}>Исключить из группы</Link>}
                            </div>
                        </td>}
                </tr>
            </tbody>
        </table>
    </div>;
}

function NewCard({ group, onSave, onCancel }: CardProps) {
    var [start, setStart] = useState(group.start || new Date());
    var theme = useTheme();

    function save(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        group.start = start;
        onSave(group, group.number, start);
    }

    return <form style={styles.form(theme)} onSubmit={save}>
        <FormControl>
            <FormLabel>Группа</FormLabel>
            <RemoteCombo typeId="EducationGroup" onChange={e => group.groupId = parseInt(e.target.value as string) || 0} />
        </FormControl>

        <Box sx={{ display: 'flex' }}>
            <FormControl fullWidth={false} sx={{ flex: 1 }}>
                <FormLabel>№ договора</FormLabel>
                <OutlinedInput name="number" defaultValue={group.number} onChange={e => group.number = e.target.value} />
            </FormControl>

            <FormControl fullWidth={false} sx={{ width: 155, ml: 1 }}>
                <FormLabel>Дата договора</FormLabel>
                <DatePicker value={start} onChange={v => v && setStart(v)} renderInput={(params) => <TextField {...params} />} />
            </FormControl>
        </Box>

        <Box sx={{ mt: 1 }}>
            <Button type="submit" startIcon={<Check />} variant="contained" color="success">Сохранить</Button>
            <Button sx={{ float: 'right' }} onClick={() => onCancel && onCancel()}>Отмена</Button>
        </Box>
    </form >;
}

export class GroupsTab extends LoadingForm<{}, { add?: ServiceAgreement, groups: ServiceAgreement[] }> {
    constructor(p: any) {
        super(p);

        this.onSave = this.onSave.bind(this);
    }

    protected getCmdGet(): string {
        return 'ServiceAgreementByPerson';
    }

    protected dataLoaded(response: any) {
        this.setState({
            groups: response.result.map(ServiceAgreement.parse)
        });
    }

    private onSave(group: ServiceAgreement, number: string, start?: Date) {
        const params: any = {
            id: group.id,
            groupId: group.groupId,
            number,
            start
        };

        if (!group.id) {
            params.groupId = group.groupId;
            params.personId = this.props.id;
        }

        return this.withLoading(() => Proxy.post('ServiceAgreement' + (group.id ? 'SetNumDate' : 'Save'), params)
            .then(x => {
                if (!group.id && x.success) {
                    group.id = x.result;
                    this.state.groups.push(group);
                    this.setState({ add: undefined });
                }

                return x;
            }));
    }

    render() {
        const groups = this.state?.groups;
        const items: React.ReactNode[] = [];
        if (groups) {
            let now = new Date();
            let current = groups.filter(x => !x.end || x.end <= now);
            let past = groups.filter(x => x.end && x.end > now);

            current.forEach(x => items.push(<GroupCard group={x} onSave={this.onSave} />));

            if (this.state.add !== undefined) {
                items.push(<NewCard group={this.state.add} onSave={this.onSave} onCancel={() => this.setState({ add: undefined })} />);
            } else {
                items.push(<Button color="success" startIcon={<SportsSoccerSharp />} onClick={() => this.setState({ add: new ServiceAgreement() })}>Добавить в группу</Button>);
            }

            if (past.length) {
                items.push(<div><Typography variant="subtitle1">Завершил обучение в группах</Typography></div>);
                past.forEach(x => items.push(<GroupCard group={x} onSave={this.onSave} past={true} />));
            }
        }

        return <Box key={this.props.id} sx={{ overflowY: 'auto', maxHeight: '100%' }}>
            <LinearProgress sx={{ p: 0, m: -3, marginBottom: 3 }} style={{ opacity: this.state?.loading == 2 ? 1 : 0 }} />

            {items}
        </Box >
    }
}

const styles = {
    editLink: {
        cursor: 'pointer',
        textDecoration: 'underline',
        textDecorationStyle: 'dotted'
    },
    form: (theme: Theme) => {
        return {
            backgroundColor: theme.palette.background.paper + 'a',
            padding: theme.spacing(1),
            marginTop: 1
        }
    }
};
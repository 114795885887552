import { Person } from "../../models/Types";
import { PersonForm } from "../../components/person/PersonForm";
import PositionCombo from "../positions/PositionCombo";
import { TextInput } from "../../components/form/TextInput";

export class EmployeeForm extends PersonForm {
    protected getTypeId() {
        return 'Employee';
    }

    protected getTypeName(): React.ReactNode {
        return 'Сотрудник';
    }

    protected buildItems(entity?: Person) {
        const items = super.buildItems.call(this, entity);
        items.splice(2, 0,
            <TextInput
                required={true}
                value={entity?.positionIds}
                label="Должности"
                name="positionIds"
                onChange={this.onChange}
                validator={v => (v as number[])?.length ? undefined : 'Необходимо выбрать должности сотрудника'}
                refValidator={val => this.addValidator('positionIds', 'positionIds', v => val(v))}
                component={(onChange, error) => <PositionCombo
                    name="positionIds"
                    multiple={true}
                    error={!!error}
                    value={entity?.positionIds}
                    onChange={onChange} />} />);

        return items;
    }
}
import DataView from '../../components/data/DataView';
import { ColumnData } from '../../components/data/DataTable';
import { Location } from '../../models/Types';
import { LocationForm } from './LocationForm';
import { LocationMap } from './LocationMap';
import { guides } from '../../guides/Guide';
import NavLink from '../../components/NavLink';
import route from '../../Router';

route.register('lat', undefined, undefined, x => parseFloat(x));
route.register('lon', undefined, undefined, x => parseFloat(x));

guides.locationsGuide = [
    {
        target: '[data-list-view-typeid="Location"] .datatable-toolbar',
        disableBeacon: true,
        title: 'Локации',
        content: <>В разделе <b>Локации</b> можно настраивать учебные классы, тренировочные площадки, места сбора или любые геолокационные точки.
            <p>В <NavLink route={[['page', 'schedule'],['id'],['tab']]}>Расписании</NavLink> можно указывать <b>Локации</b> для любого занятия</p>
        </>,
    },
    {
        target: '.datatable-toolbar > [role="group"]',
        placementBeacon: 'right',
        title: 'Карта',
        content: <><b>Локации</b> можно просматривать на <b>карте</b>, а также добавлять и редактировать, выбирая метки на <b>карте</b>.
        </>,
    }
];

const columns: ColumnData<Location>[] = [{
    label: 'Наименование',
    name: 'name',
    filter: true,
    routerLink: true
}, {
    label: 'Адрес',
    name: 'address',
    filter: true
}];

export default () => <DataView<Location>
    title="Локация"
    titleMultiple="Локации"
    typeId="Location"
    guide="locationsGuide"
    modes={[{
        mode: 'list',
        text: <>Список</>
    }, {
        mode: 'board',
        text: <>Карта</>,
        view: () => <LocationMap />
    }]}
    form={[{
        title: 'Локация',
        element: (id, apiRef) => <LocationForm id={id} apiRef={apiRef} lat={route.get('lat')} lon={route.get('lon')} />
    }]}
    tableProps={{
        columns: columns
    }} />
import Observer from "./Observer";

const stores: { [name: string]: (CachedStore) } = {};

export default class CachedStore<T = any> {
    private name: string;
    private timeout: number;
    private loadFn?: (() => Promise<T[]>)

    private observer: Observer;

    private currentLoad?: Promise<T[]>;
    private ts: Date

    data?: T[]

    constructor(name: string, loadFn?: (() => Promise<T[]>), timeout: number = 15) {
        this.name = name;
        this.loadFn = loadFn;
        this.timeout = timeout;
        this.ts = new Date(1990, 1, 1);

        this.observer = new Observer();
    }

    static Configure<T>(name: string, loadFn?: (() => Promise<T[]>), timeout: number = 15) {
        if (stores[name]) {
            stores[name].name = name;
            stores[name].timeout = timeout;
            stores[name].loadFn = loadFn;
        } else {
            stores[name] = new CachedStore<T>(name, loadFn, timeout);
        }

        return stores[name] as CachedStore<T>;
    }

    static Get<T>(name: string) {
        return stores[name];
    }

    load(loadFn?: (() => Promise<T[]>)): (Promise<T[]> | undefined) {
        if (!loadFn) {
            loadFn = this.loadFn;
        }

        if (this.ts > new Date()) {
            return Promise.resolve<T[]>(this.data!);
        }

        if (this.currentLoad) {
            return this.currentLoad;
        }

        if (loadFn) {
            return this.currentLoad = loadFn().then(x => {
                delete this.currentLoad;

                this.data = x;
                this.ts = new Date();
                this.ts = new Date(this.ts.getFullYear(), this.ts.getMonth(), this.ts.getDate(), this.ts.getHours(), this.ts.getMinutes(), this.ts.getSeconds() + this.timeout);

                this.observer.fire('load', [x]);

                return x;
            })
        }
    }

    onLoad(fn: Function) {
        return this.observer.listen('load', fn);
    }
}
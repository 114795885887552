import { Close } from "@mui/icons-material";
import { Badge, Button, Dialog, DialogTitle, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import StyledMessenger from "../components/messenger/Messenger";
import { resolveHub, useChatStatus } from "../components/messenger/Model";
import route from "../Router";
import { Step } from "react-joyride";
import Guide from "../guides/Guide";
import NavLink from "../components/NavLink";

export const chatStatusGuide: Step[] = [{
    target: '#chat-status',
    title: 'Чаты',
    placementBeacon: 'right-start',
    content: <>
        Чаты решают широкий спектр задач взаимодействия:
        <ul>
            <li>Между клиентами и сотрудниками Клуба, в том числе проведение онлайн занятий</li>
            <li>Оповщения для конкретного участника Клуба, групп или общией для всех. В том числе автоматические</li>
            <li>Внутреннии коммуникации между сотрудниками</li>
            <li>Техподдержка <Typography color="error" display="inline">ВКлубе</Typography> для всех пользователей</li>
        </ul>
        Пользоваться чатами могут любые пользователи Клуба, для этого <NavLink route={[['page', 'emploees']]}>Сотрудника</NavLink> или <NavLink route={[['page', 'clients']]}>Клиента</NavLink> необходимо пригласить в Клуб. Это можно сделать в его личной карточке.
    </>
}];

const colors: any = {
    'Connecting': 'warning',
    'Connected': 'info',
    'Disconnected': 'error'
}

export default function ChatStatus() {
    const status = useChatStatus();
    const [count, setCount] = useState<number>();
    const [open, setOpen] = useState<boolean>();

    if (count === undefined && status == 'Connected') {
        resolveHub().then(x => x.unreadedCount().then((count: number) => setCount(count)));
    }

    function buildWin() {
        if (open != undefined) {
            return <Dialog onClose={() => setOpen(false)} open={open}>
                <DialogTitle>Чаты <IconButton onClick={() => setOpen(false)} sx={{ float: 'right' }}><Close /></IconButton></DialogTitle>
                <StyledMessenger />
            </Dialog>;
        }
    }

    return <>
        <Button id="chat-status" variant="outlined" color={(status ? colors[status] : null) || 'warning'} sx={{ m: 1 }} onClick={() => route.setState('page', 'chat')}>
            {count ? <Badge badgeContent={count} color="primary" sx={{ right: -.5, top: -.5, position: "absolute" }} /> : null}
            Чаты
        </Button>
        {buildWin()}
        <Guide guide="chatStatus" />
    </>;
}
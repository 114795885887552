import DataView, { Form as DataViewForm } from '../../components/data/DataView';
import { ColumnData } from '../../components/data/DataTable';
import { Event } from '../../models/Types';
import { EventForm } from './EventForm';
import { CalendarMonth } from '@mui/icons-material';
import Calendar from '../../components/calendar/Calendar';
import route from '../../Router';
import { EventParticipantsForm } from './EventParticipantsForm';
import { Box } from '@mui/material';
import { ListApiRef } from '../../components/data/ApiRef';
import { useRoute } from '../../models/Hooks';
import { useState } from 'react';

const columns: ColumnData<Event>[] = [{
    label: 'Наименование',
    name: 'name',
    filter: true,
    routerLink: true
}];

route.register('start', undefined, s => s?.toISOString(), s => s && new Date(s));
route.register('end', undefined, s => s?.toISOString(), s => s && new Date(s));

export default () => {
    return <DataView<Event>
        title="Событие"
        titleMultiple="Расписание"
        typeId="EducationGroup"
        hideFormAfterSave={false}
        filterByColumns={false}
        form={[{
            title: 'Событие',
            element: (id, apiRef, params, setTabApi) => <EventForm id={id} apiRef={apiRef} params={{ start: route.get('start'), end: route.get('end'), ...params }} setTabApi={setTabApi} />
        }, {
            title: 'Участники',
            element: (id) => <EventParticipantsForm id={id} />
        }]}
        listView={(props, apiRef)=><Box sx={{ flex: 1 }}>
        <Calendar
            apiRef={apiRef}
            eventDrop={(e) => route.setState([['id', e.event.id], ['hideForm'], ['start', e.event.start], ['end', e.event.end]])}
            eventClick={(info: any) => route.setState([['id', info.event.id], ['hideForm'], ['start'], ['end']])}
            select={(info: any) => route.setState([['id', 'new'], ['tab', 'Событие'], ['hideForm'], ['start', info.start], ['end', info.end]])}
        />
    </Box>}
        tableProps={{
            columns: columns
        }} />
}
import { AttachFile, ChevronLeft, Delete, DesktopWindows, Done, Edit, Send, Videocam } from '@mui/icons-material';
import { Box, Button, FormControl, FormLabel, Grid, IconButton, Input, OutlinedInput, Paper, SxProps, Theme, Toolbar, useTheme } from '@mui/material';
import moment from 'moment';
import { Component } from 'react';
import { Attachment, Chat, Message, MessageDto } from './entities';
import { model, resolveHub } from './Model';
import './messenger.css'
import { AbstractDataForm, AbstractTypedDataForm } from '../data/DataForm';
import { LoadingForm } from '../base/LoadingForm';
import { FormTextInput } from '../form/TextInput';

interface Props {
    chatId: number
    onClose?: Function
}

interface ThemedProps extends Props {
    theme: Theme
}

interface State {
    data: MessageDto[],
    loading: boolean
    message?: string
    messageId?: number,
    viewAttachment?: {
        attachment: Attachment,
        url: string
    }
}

export default class ChatInfo extends LoadingForm<{}, { entity?: Chat }> {
    protected getCmdGet(): string {
        throw new Error('Method not implemented.');
    }
    protected dataLoaded(response: any) {
        throw new Error('Method not implemented.');
    }

    private hub: any

    protected loadData(id: number) {
        return this.withLoading(() => resolveHub().then(x => {
            this.hub = x;
            this.hub.getChat(id);
        }), 500);
    }

    render() {
        const entity = this.state?.entity
        return <Box sx={{}}>
            <Grid container rowSpacing={2} sx={{ overflow: 'auto' }}>
                <Grid item xs={4}>
                    <img src="https://dscontrol.ru/wp-content/uploads/promo-img.png" style={{ width: '100%' }} />
                </Grid>
            </Grid>

            <FormControl>
                <FormLabel>Примечание</FormLabel>
                <OutlinedInput name="description" multiline={true} defaultValue={entity?.name} />
            </FormControl>
        </Box>
    }
}
import { MenuItem, OutlinedInput, Select } from "@mui/material";
import { Contact } from "../../models/Types";
import { DataGrid, DataGridApiProps } from "../form/DataGrid";

export function Contacts({ contacts, ...props }: { contacts: Contact[] } & DataGridApiProps<Contact>) {
    return <DataGrid<Contact> 
        {...props}
        label="Контакты"
        addText="контакт"
        create={() => { return {}; }}
        entities={contacts}
        row={(c, i) => <>
            <Select defaultValue={c.type} placeholder="Тип" onChange={e => c.type = e.target.value}>
                <MenuItem value="phone">Телефон</MenuItem>
                <MenuItem value="email">E-mail</MenuItem>
                <MenuItem value="link">Ссылка</MenuItem>
                <MenuItem value="telegram">Telegram</MenuItem>
                <MenuItem value="instagram">Instagram</MenuItem>
            </Select>
            <OutlinedInput onChange={e => c.value = e.target.value} defaultValue={c.value} />
            <OutlinedInput onChange={e => c.description = e.target.value} defaultValue={c.description} placeholder="Примечание" />
        </>} />;
}
import { FormControl, FormLabel, OutlinedInput, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { ServiceAgreement } from "../../models/Types";

export class ServiceAgreementForm extends AbstractTypedDataForm<ServiceAgreement> {
    protected getTypeId(): string {
        return 'ServiceAgreement';
    }

    protected createEntity(): ServiceAgreement {
        return new ServiceAgreement();
    }

    protected buildItems(entity?: ServiceAgreement | undefined): React.ReactNode {
        return <>
            <FormControl>
                <FormLabel>Номер</FormLabel>
                <OutlinedInput name="number" defaultValue={entity?.number} onChange={this.onChange} />
            </FormControl>
            <FormControl fullWidth={false}>
                <FormLabel>Дата</FormLabel>
                <DatePicker
                    value={entity?.start}
                    maxDate={entity?.end}
                    onChange={value => {
                        this.onChange({ target: { name: 'start', value } });
                        this.forceUpdate();
                    }}
                    renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, placeholder: undefined }} />} />
            </FormControl>
        </>;
    }
}
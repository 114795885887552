import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import daygrid from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import Proxy from '../../models/Proxy';
import timegrid from '@fullcalendar/timegrid';
import list from '@fullcalendar/list';
import './calendar.css'
import { CalendarApi, CalendarOptions } from '@fullcalendar/core';
import ru from '@fullcalendar/core/locales/ru';
import { ListApiRef } from '../data/ApiRef';
import { Event } from '../../models/Types';
import { arrayReplace } from '../../utils';

interface CalendarProps extends CalendarOptions {
    apiRef?: (api?: ListApiRef<Event>) => void
}

export default class Calendar extends React.Component<CalendarProps> {
    private api?: CalendarApi

    lastResult?: {
        startStr: string,
        endStr: string
        result: any
    }

    changed: { [id: number]: any }

    constructor(p: CalendarProps) {
        super(p);

        this.changed = {};
        this.onRef = this.onRef.bind(this);
    }

    reset(id: number) {
        const e = this.api?.getEventById(id.toString());
        const props = this.changed[id];
        if (e && props) {
            for (var f in props) {
                e.setProp(f, props[f]);
            }

            e.setProp('backgroundColor', undefined);
            e.setProp('textColor', undefined);
        }

        delete this.changed[id];
    }

    onRef(c: FullCalendar) {
        this.api = c?.getApi();
        if (c) {
            this.props.apiRef && this.props.apiRef({
                change: (id, field, value) => {
                    const e = this.api?.getEventById(id.toString());
                    if (!e) {
                        return;
                    }

                    switch (field) {
                        case 'start': e.setStart(value?._d || value); break;
                        case 'end': e.setStart(value?._d || value); break;
                        case 'name': e.setProp('title', value); break;
                        default: return;
                    }

                    if (!this.changed[id]) {
                        this.changed[id] = {};
                    }

                    if (this.changed[id][field] === undefined) {
                        this.changed[id][field] = e.extendedProps[field];
                    }

                    e.setProp('backgroundColor', 'var(--fc-button-active-bg-color)');
                    e.setProp('textColor', 'var(--fc-event-bg-color)');
                },
                reset: (id) => {
                    delete this.changed[id]
                },
                update: (id: number | number[]) => Proxy.get('EventList', {
                    query: JSON.stringify({
                        filters: [{
                            property: 'Entity.Id',
                            value: id
                        }]
                    })
                }).then((x: any) => {
                    var e = x.result.data[0];
                    if (e) {
                        delete this.changed[e.id];

                        e.title = e.name;

                        const ce = this.api?.getEventById(e.id);
                        ce?.remove();

                        this.api?.addEvent(e);
                        return Object.assign(new Event(), e);
                    }
                })
            })
        };
    }

    render() {
        return (
            <FullCalendar
                height="100%"
                plugins={[daygrid, timegrid, list, interactionPlugin]}
                locale={ru}
                themeSystem=''
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                }}
                events={(info: any, successCallback: Function, failureCallback: Function) => {
                    if (this.lastResult && this.lastResult.startStr == info.startStr && this.lastResult.endStr == info.endStr) {
                        return successCallback(this.lastResult?.result);
                    }

                    Proxy.get('EventList', {
                        query: JSON.stringify({
                            filters: [{
                                property: 'Entity.Start',
                                operator: 'lte',
                                value: info.end
                            }, {
                                property: 'Entity.End',
                                operator: 'gte',
                                value: info.start
                            }]
                        })
                    }).then((x: any) => {
                        this.lastResult = {
                            startStr: info.startStr,
                            endStr: info.endStr,
                            result: x.result.data.map((x: any) => {
                                x.title = x.name;
                                return x;
                            })
                        }

                        this.api?.removeAllEvents();
                        successCallback(this.lastResult.result);
                    });
                }}
                initialView='timeGridWeek'
                nowIndicator={true}
                weekNumbers={true}
                selectable={true}
                droppable={true}
                navLinks={true}
                editable={true}
                dayMaxEvents={true}
                ref={this.onRef}
                {...this.props}
            />
        )
    }
}
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { ReactNode } from "react";
import { forwardRef } from "react";
import DataTable from "./data/DataTable";
import { IEntity } from "../models/Types";
import { DataTableProps } from "./data/DataTable";
import { useState } from "react";
import Proxy from "../models/Proxy";

interface ModalDialogProps extends DialogProps {
    header?: ReactNode
    body?: ReactNode
    actions?: ReactNode
}

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export function ModalDialog({ header, body, actions, ...props }: ModalDialogProps) {
    return <Dialog
        TransitionComponent={Transition}
        PaperProps={paperProps}
        {...props}>
        {header && <DialogTitle color="error">{header}</DialogTitle>}
        {body && <DialogContent>{body}</DialogContent>}
        {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
}

interface SelectProps<T extends IEntity> extends ModalDialogProps {
    tableProps: DataTableProps<T>
    addCmd: string
    addText?: ReactNode
    urlParams?: any
    onAdd?: (ids: number[]) => void
}

export function SelectDialog<T extends IEntity>({ tableProps, addCmd, onAdd, urlParams, open, addText, ...props }: SelectProps<T>) {
    const [ids, _setIds] = useState<number[]>([]);
    const [count, setCount] = useState(0);
    const [opened, setOpened] = useState<boolean | undefined>(open);

    function setIds(s:number[]){
        _setIds(s);
        setCount(s.length || 0);
    }

    function addRange() {
        setOpened(false);
        Proxy.post(addCmd, ids, urlParams).then(x => {
            if (x.success) {
                close();
                onAdd && onAdd(x.result as number[]);
            }
        })
    }

    function close() {
        setIds([]);
        setOpened(undefined);
        props.onClose && props.onClose({}, 'backdropClick');
    }

    return <ModalDialog
        open={opened !== false}
        body={<DataTable<T>
            multiple={true}
            {...tableProps}
            onSelectionChange={(s, t) => { 
                setIds(s); 
                tableProps?.onSelectionChange && tableProps.onSelectionChange(s, t); 
            }} />}
        actions={<>
            <Button key={'add'+ids.length} variant="contained" disabled={!opened} onClick={addRange}>{addText || 'Добавить'}{count ? ' (' + count + ')' : ''}</Button>
            <Button onClick={() => close()}>Отмена</Button>
        </>}
        {...props} />;
}

const paperProps = {
    sx: {
        //position: 'absolute',
        //m: 0,
        //pr: 1,
        //top: '5rem',
        width: 1200,
        maxWidth: '100%',
        height: 'calc(100% - 10rem)',
        maxHeight: 'calc(100% - 5rem)',
        //right: 0
    }
}
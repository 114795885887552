import { Tariff } from "../../models/Types";
import RemoteCombo, { RemoteComboProps, configureSuggestStore } from "../../components/form/RemoteCombo";

const cmd = 'TariffList';
const valueField = 'name';

export const tariffStore = configureSuggestStore<Tariff>(cmd, valueField, 30 * 60);

export default function TariffCombo(props: RemoteComboProps<Tariff>) {
    return <RemoteCombo<Tariff>
        typeId="Tariff"
        valueField={valueField}
        store={tariffStore}
        cmd={cmd}
        {...props}
        ref={undefined} />;
}
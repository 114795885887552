import DataView, { Form as DataViewForm } from '../../components/data/DataView';
import { ColumnData } from '../../components/data/DataTable';
import { ClientStatus } from '../../models/Types';
import { ClientStatusForm } from './ClientStatusForm';
import { Circle } from '@mui/icons-material';
import { guides } from '../../guides/Guide';

guides.сlientStatusGuide = [
    {
      target: '#club-menu-сlientstatus',
      placementBeacon: 'right',
      title: 'Статусы клиента',
      content: <>С помощью <b>Статусов клиента</b> можно разделять клиентов например на лидов, активных клиентов с которыми заключены договора, завершивших взаимоотношения по той или иной причине.
        <p>Для удобства каждый <b>Статус клиента</b> имеет цветовую метку.</p>
        <p>При создании нового Клуба в нем по умолчанию уже есть статусы <b>Первичный контакт</b>, <b>В работе</b> и <b>Отказ</b></p>
      </>,
    }
  ];

const columns: ColumnData<ClientStatus>[] = [{
    name: 'color',
    width: 50,
    renderer: (val, obj, theme) => val && <Circle htmlColor={val} />,
},
{
    label: 'Имя',
    name: 'name',
    filter: true,
    routerLink: true
},
{
    width: 75,
    label: 'Конечный',
    name: 'isFinished',
    type: 'boolean',
    filter: true
},
{
    width: 50,
    label: 'Порядковый номер',
    name: 'index',
    type: 'numeric',
    dnd: true,
    sortDirection: -1
}];

export default () => <DataView<ClientStatus>
    title="Статус клиента"
    titleMultiple="Статусы клиента"
    typeId="PersonStatus"
    form={[{
        title: 'Статус',
        element: (id, apiRef) => <ClientStatusForm id={id} apiRef={apiRef} />
    }]}
    tableProps={{
        reorderCmd: 'PersonStatusReorder',
        columns: columns
    }} />
import { ChevronRight, Menu } from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Title from './icons/Title';
import { isAuthentificated, listenUser } from './models/Identity';
import AppBar from './nav/AppBar';
import { menu, useAppRoute } from './nav/AppRoute';
import Drawer, { drawerWidth } from './nav/Drawer';
import TenantSelector from './nav/TenantSelector';
import UserMenu from './nav/UserMenu';
import Login from './screens/Login';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Typography } from '@mui/material';
import moment from 'moment';
import 'moment/locale/ru';
import { useState } from 'react';
import Logo from './icons/Logo';
import ChatStatus from './nav/ChatStatus';
import { theme } from './theme';
import { isMobile } from './utils';
import RouterGuide from './guides/RouterGuide';

moment.locale('ru');

const title = () => <div style={{ width: drawerWidth as number - 48, marginRight: 24, display: 'flex', justifyContent: 'center' }}>
  <Title />
</div>;

function Body() {
  const [route, path, current, menuItem] = useAppRoute(menu);
  return <>{menuItem?.element && menuItem.element()}</>;
}

export default function App() {
  const [open, setOpened] = useState(!isMobile());
  const [auth, setAuth] = useState(isAuthentificated());

  listenUser(() => setAuth(isAuthentificated()));

  function buildCloseDrawer(open: boolean) {
    return <IconButton onClick={() => setOpened(!open)}>
      {open ? <ChevronLeftIcon /> : isMobile() ? <Menu /> : <ChevronRight />}
    </IconButton>;
  };

  function buildLayout() {
    return <>
      <CssBaseline />

      <AppBar position="relative" open={open} color="secondary">
        <Toolbar style={isMobile() ? { paddingLeft: 0 } : undefined}>
          {isMobile() ? buildCloseDrawer(open) : title()}
          <TenantSelector />
          <div style={{ flex: 1 }} />
          <ChatStatus />
          <UserMenu />
        </Toolbar>
      </AppBar>

      <div style={{ flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
        <Drawer key={'drawer' + open} id="right-menu" open={open} menu={menu} title="Главное меню">
          <div style={{ flex: 1, zIndex: -1 }}>
            <div style={{ width: '80%', margin: '10%', bottom: 25, position: 'absolute', display: 'flex', justifyContent: 'center' }}>
              <Logo color={theme.palette.action.selected} />
            </div>
          </div>

          {isMobile() || !open ? null : <Typography variant="caption" style={{ textAlign: 'center' }}>Версия 0.1.0</Typography>}
        </Drawer>

        <Box component="main"
          sx={{ flexGrow: 1, backgroundColor: theme.palette.background.default, overflow: isMobile() ? undefined : 'hidden' }}
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
          <Body />
        </Box>
      </div>

      <RouterGuide />
    </>;
  }

  return <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {auth ? buildLayout() : <Login />}
    </LocalizationProvider>
  </ThemeProvider>;
}
import { Step } from 'react-joyride';

const tenantCreate: Step[] = [/**
    {
        target: '#tenant-selector',
        content: 'Откройте список Клубов',
        placementBeacon: 'right-end'
    },/**/
    {
        target: '#tenant-selector-new',
        title: 'Создайте свой Клуб',
        placementBeacon: 'right',
        content: 'Клуб - отдельный аккаунт в котором можно вести независимый список клиентов, сотрудников, расписание, занятия, расчеты и многое другое',
    }
];

export default tenantCreate;
import { FormControl, FormLabel, OutlinedInput, TextareaAutosize } from "@mui/material";
import React from "react";
import { AbstractTypedDataForm } from "../../../components/data/DataForm";
import { FormTextInput } from "../../../components/form/TextInput";
import { Account } from "../../../models/Types";
import CurrencyCombo from "../../currency/CurrencyCombo";

export class AccountForm extends AbstractTypedDataForm<Account> {
    componentDidMount(): void {
        super.componentDidMount();
    }

    protected getTypeId(): string {
        return 'Account';
    }

    protected createEntity(): Account {
        return new Account();
    }

    protected buildItems(entity?: Account) {
        return <>            
            <FormControl sx={{ zIndex: 1024 }}>
                <FormLabel>Валюта</FormLabel>
                <CurrencyCombo name="currency" nullableText="Рубль" />
            </FormControl>

            <FormTextInput label="Количество" form={this} name="amount" entity={entity} />
        </>;
    }
}

const styles = {
    map: {
        flex: 1,
        overflow: 'hidden',
        margin: '1rem 0',
        minHeight: 350
    }
}
import DataView from '../../components/data/DataView';
import { ColumnData } from '../../components/data/DataTable';
import { ExchangeOperation } from '../../models/Types';
import DataListView from '../../components/data/DataListView';
import { OperationType, operationTypes } from '../../models/Enums';
import { ExchangeOperationForm } from './ExchangeOperationForm';
import ExchangeOperationTypeCombo from './ExchangeOperationTypeCombo';
import CurrencyCombo, { currencyStore } from '../currency/CurrencyCombo';

export const columns: ColumnData<ExchangeOperation>[] = [ {
    label: 'Тип',
    name: 'type',
    routerLink: true,
    width: 175,
    filter: (onChange) => <ExchangeOperationTypeCombo onChange={e => onChange(e.target.value)} />,
    renderer: v => operationTypes[v as OperationType]
}, {
    label: 'Дата/время',
    name: 'ts',
    type: 'date',
    filter: 'date',
    sortDirection: 1
}, {
    label: 'Сумма',
    name: 'amount',
    type: 'numeric',
    width: 120,
    filter: true,
    renderer: Math.abs
}, {
    label: 'Услуга',
    name: 'currencyId',
    store: currencyStore,
    filter: (onChange) => <CurrencyCombo onChange={e => onChange(e.target.value)} />,
    renderer: v => v && currencyStore.data?.filter(x => x.id == v).map(x => x.value)[0] || 'рублей'
}, {
    label: 'Описание',
    name: 'description',
    filter: true
}];

class ExchangeOperationList extends DataListView<ExchangeOperation> {
    buildAddButton1(newTitleText: string, props?: any) {
        return [
            super.buildAddButton('начисление',props),
            super.buildAddButton('платеж',props),
            super.buildAddButton('зачисление',props)
        ];
    }
}

export default () => <DataView<ExchangeOperation>
    title="Расчет"
    titleMultiple="Расчеты"
    typeId="ExchangeOperation"
    listView={(props, setListApi) => <ExchangeOperationList {...props} apiRef={setListApi} />}
    form={[{
        title: 'Расчет',
        element: (id, apiRef) => <ExchangeOperationForm id={id} apiRef={apiRef} />
    }]}
    tableProps={{
        columns: columns,
        dataParser: ExchangeOperation.parse
    }} />
import React from "react";
import { Account } from "../../models/Types";
import { accountColumns } from "../profile/account";
import DataTable from "../../components/data/DataTable";

export default ({ id }: { id: number }) => {
    return <DataTable<Account>
        key={id}
        typeId="Account"
        className="datatable-compact person-accounttab-table"
        cmd="AccountByPerson"
        loadParams={{
            personId: id
        }}
        columns={accountColumns} />;
}
import { Add, Check, DisabledByDefaultOutlined, GroupAdd } from "@mui/icons-material";
import { Box, Button, LinearProgress, styled, Typography } from "@mui/material";
import { LoadingForm } from "../../components/base/LoadingForm";
import TriStateCheckbox from "../../components/form/TriStateCheckbox";
import Proxy from "../../models/Proxy";
import { EventParticipant } from "../../models/Types";
import { SelectDialog } from "../../components/ModalDialog";
import { ClientListDto, clientListColumns } from "../clients/ClientList";

const PartBox = styled(Box)`
    & {
        display: flex;
        flexDirection: row;
        margin-bottom: 1px;
        background-color: #fffa;
        padding: 1rem;
    }

    & > img {
        max-height: 5rem;
        max-width: 5rem;
    }
`;

export class EventParticipantsForm extends LoadingForm<{ params?: any }, { success?: boolean, participants?: EventParticipant[], addDialogOpen?: boolean }> {
    constructor(p: any) {
        super(p);

        this.setAttended = this.setAttended.bind(this);
        this.setSuccess = this.setSuccess.bind(this);
        this.addFromGroups = this.addFromGroups.bind(this);

        this.onAdded = this.onAdded.bind(this);
    }

    protected fetchNew(): boolean {
        return false;
    }

    protected getCmdGet(): string {
        return 'EventParticipantByEvent';
    }

    protected dataLoaded(response: any) {
        if (response.result) {
            response = response.result;
        }

        return {
            success: response.success,
            participants: (response.data as any[]).map(x => Object.assign(new EventParticipant(), x))
        };
    }

    private setAttended(p: EventParticipant, attended?: boolean) {
        return this.withLoading(() => Proxy.post('EventParticipantSetAttended', {
            eventId: this.props.id,
            participantId: p.participantId,
            attended
        })
            .then(x => {
                p.attended = attended
                return x;
            }));
    }

    private setSuccess() {
        return this.withLoading(() => Proxy.post('EventSetSuccess', this.props.id)
            .then(x => this.setState({ success: x.result })));
    }

    addFromGroups() {
        this.withLoading(() => Proxy.post('EventParticipantAddFromGroups', this.props.id)
            .then(this.onAdded));
    }

    onAdded(x: any) {
        this.setState({
            participants: this.dataLoaded(x).participants
        });
    }

    buildParticipant(p: EventParticipant) {
        return <PartBox key={p.eventId + '-' + p.participantId}>
            <img src="https://dscontrol.ru/wp-content/uploads/promo-img.png" />
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', pl: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="h5" sx={{ flex: 1 }}>{p.name}</Typography>
                    {p.groups?.length ? <Box component={p => <span {...p} />} sx={{ textAlign: 'right' }}>
                        Групп{p.groups?.length > 1 ? 'ы' : 'а'}: <Typography component={p => <span {...p} />} variant="h6">{p.groups.join(', ')}</Typography>
                    </Box> : null}
                </Box>

                <TriStateCheckbox sx={{ mt: 1, ml: 'auto' }}
                    text={['Присутствовал', 'Отсутствовал']}
                    activeColor={['success', 'error']}
                    icons={[undefined, <DisabledByDefaultOutlined />]}
                    value={p.attended}
                    onChange={e => this.setAttended(p, e.target.value)} />
            </Box>
        </PartBox>;
    }

    buildDialog() {
        return <SelectDialog<ClientListDto>
            header="Выберите клиентов"
            addCmd="EventParticipantAddPersons"
            open={!!this.state.addDialogOpen}
            urlParams={{ eventId: this.props.id }}
            tableProps={{
                columns: clientListColumns,
                typeId: 'Person',
                cmd: 'EventParticipantForEvent?eventId=' + this.props.id,
                dataParser: ClientListDto.parse
            }}
            onAdd={this.onAdded}
            onClose={() => this.setState({ addDialogOpen: undefined })}
        />;
    }

    render() {
        return <>
            <Box key={this.props.id} sx={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                <LinearProgress sx={{ p: 0, m: -3, marginBottom: 3 }} style={{ opacity: this.state?.loading == 2 ? 1 : 0 }} />
                {this.state?.id && !this.state?.success ?
                    <Box>
                        <Button startIcon={<GroupAdd />} color="success" onClick={this.addFromGroups}>Добавить из групп</Button>
                        <Button sx={{ float: 'right' }} startIcon={<Add />} color="success" onClick={() => this.setState({ addDialogOpen: true })}>Добавить участника</Button>
                    </Box> :
                    null}

                <Box sx={{ overflowY: 'auto', flex: 1, my: 1 }}>
                    {this.state?.participants?.sort((x, y) => x.participantId - y.participantId).map(x => this.buildParticipant(x))}
                </Box>

                <Box>
                    {this.state?.success ?
                        <Typography variant="button" sx={{ float: 'right' }} color="success.main"><Check /> завeршено</Typography> :
                        <Button sx={{ float: 'right' }} disabled={this.state?.success !== false} startIcon={<Check />} variant="contained" onClick={this.setSuccess}>
                            Завершить
                        </Button>}
                </Box>
            </Box >
            {this.state?.addDialogOpen && this.buildDialog()}
        </>;
    }
}
import { Box, FormControl, FormLabel, OutlinedInput, TextField } from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import React from "react";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput } from "../../components/form/TextInput";
import { EducationGroup } from "../../models/Types";
import EmployeeCombo from "../employees/EmployeeCombo";
import LocationCombo from "../locations/LocationCombo";
import ProgramCombo from "../programs/ProgramCombo";

export class GroupForm extends AbstractTypedDataForm<EducationGroup> {
    protected getTypeId(): string {
        return 'EducationGroup';
    }

    protected getTypeName(): React.ReactNode {
        return 'Группа';
    }

    protected createEntity(): EducationGroup {
        return new EducationGroup();
    }

    protected buildItems(entity?: EducationGroup): React.ReactNode {
        return <>
            <FormTextInput required={true} label="Наименование" name="name" form={this} entity={entity} />
            <Box sx={{ display: 'flex' }}>
                <FormControl fullWidth={false}>
                    <FormLabel>Начало занятий</FormLabel>
                    <DatePicker
                        value={entity?.start}                        
                        maxDate={entity?.end}
                        onChange={value => {
                            this.onChange({ target: { name: 'start', value } });
                            this.forceUpdate();
                        }}
                        renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, placeholder:undefined  }} />} />
                </FormControl>
                <div style={{ width: '1rem', flex: 'none' }} />
                <FormControl fullWidth={false}>
                    <FormLabel>Окончание занятий</FormLabel>
                    <DatePicker
                        value={entity?.end}
                        minDate={entity?.start}
                        onChange={value => {
                            this.onChange({ target: { name: 'end', value } });
                            this.forceUpdate();
                        }}
                        renderInput={(params) => <TextField {...params} inputProps={{ ...params.inputProps, placeholder:'бессрочно'  }} />} />
                </FormControl>
            </Box>
            <FormControl>
                <FormLabel>Расписание текстом</FormLabel>
                <OutlinedInput name="scheduleText" defaultValue={entity?.scheduleText} onChange={this.onChange} />
            </FormControl>
            <FormControl>
                <FormLabel>Основной преподаватель</FormLabel>
                <EmployeeCombo name="mainTeacherId" value={entity?.mainTeacherId} onChange={this.onChange} />
            </FormControl>
            <FormControl>
                <FormLabel>Программа</FormLabel>
                <ProgramCombo name="programId" value={entity?.programId} onChange={this.onChange} />
            </FormControl>
            <FormControl>
                <FormLabel>Основная локация</FormLabel>
                <LocationCombo name="locationId" value={entity?.locationId} onChange={this.onChange} />
            </FormControl>
            <FormControl>
                <FormLabel>Ответственный сотрудник</FormLabel>
                <EmployeeCombo name="responsibleEmployeeId" value={entity?.responsibleEmployeeId} onChange={this.onChange} />
            </FormControl>
            <FormControl>
                <FormLabel>Примечания, заметки</FormLabel>
                <OutlinedInput name="description" multiline={true} defaultValue={entity?.description} onChange={this.onChange} />
            </FormControl>
        </>;
    }
}
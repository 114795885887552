import { Forum, Podcasts, RssFeed, ThreePOutlined } from "@mui/icons-material";
import { Avatar, Button, FormControl, FormLabel, List, ListItemAvatar, ListItemButton, ListItemText, OutlinedInput, Step, StepButton, StepContent, StepContentProps, StepLabel, Stepper, styled } from "@mui/material";
import { useState } from "react";
import { clientListColumns, ClientListDto } from "../../screens/clients/ClientList";
import DataTable from "../data/DataTable";
import { Chat, SaveChatDto, SaveChatMemberDto } from "./entities";
import { ChatType, MemberType } from "./enums";
import { resolveHub } from "./Model";

const chatTypes: [ChatType, string, JSX.Element][] = [
    [ChatType.Person, 'Личный', <ThreePOutlined />],
    [ChatType.Group, 'Группа', <Forum />],
    [ChatType.Chanel, 'Канал', <RssFeed />],
    [ChatType.Broadcast, 'Широковещательный канал', <Podcasts />],
]

const FlexStepper = styled(Stepper)`
    & .MuiStepContent-root {
        padding-left: 0;
    }

    & .MuiStepConnector-root{
        flex: none;
    }

    & .MuiList-root{
        padding: 0;
    }
`;

const FlexStep = styled(Step)`
    flex: 1;
    display: flex;
    flex-direction: column;

    & .MuiStepContent-root{
        flex: 1;
    }

    & .MuiCollapse-root,
    & .MuiCollapse-wrapper {
        height: 100% !important;
    }

    & .MuiCollapse-wrapperInner {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
`;

const MultiFlexStep = styled(FlexStep)`
    & .MuiTableCell-root:first-child {
        padding: 0;
        width: auto!important;
    }
`;

const personColumns = [{ ...clientListColumns[0], routerLink: false }];
const personsColumns = [...personColumns];

export default function NewChat({ onClose }: { onClose: Function }) {
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState<string>('Новая группа');
    const [chatType, setChatType] = useState<ChatType>();
    const [members, setMembers] = useState<number[]>();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    function createGroup() {
        resolveHub().then(hub => hub.saveChat(new SaveChatDto(
            new Chat(name, chatType),
            members?.map(m => new SaveChatMemberDto(MemberType.Member, undefined, m.toString()))))
            .then(() => onClose && onClose()));
    }

    function resolvePersonalChat(userId:number) {
        resolveHub()
            .then(hub => hub.resolvePersonalChat(undefined, userId.toString()))
            .then(() => onClose && onClose());
    }

    const steps = [
        <Step key="type">
            <StepButton onClick={() => setActiveStep(0)}>{chatType !== undefined ? 'Тип: ' + chatTypes[chatType][1] : 'Выбор типа чата'}</StepButton>
            <StepContent>
                <List>
                    {chatTypes.map(x => <ListItemButton selected={chatType == x[0]} onClick={() => { setChatType(x[0]); handleNext(); }}>
                        <ListItemAvatar><Avatar>{x[2]}</Avatar></ListItemAvatar>
                        <ListItemText>{x[1]}</ListItemText>
                    </ListItemButton>)}
                </List>
            </StepContent>
        </Step>
    ];

    switch (chatType) {
        case ChatType.Person:
            steps.push(<FlexStep key="member">
                <StepLabel>Выбор собеседника</StepLabel>
                <StepContent>
                    <DataTable<ClientListDto>
                        multiple={false}
                        rowSelector="simple"
                        columns={personColumns}
                        typeId="Person"
                        dataParser={ClientListDto.parse}
                        onSelectionChange={s => {
                            debugger;
                            s && s[0] && resolvePersonalChat(s[0]);
                        }} />
                </StepContent>
            </FlexStep>);
            break;

        case ChatType.Group:
            steps.push(<Step key="groupinfo">
                <StepButton onClick={() => setActiveStep(1)}>Группа {name}</StepButton>
                <StepContent>
                    <FormControl sx={{ m: 1 }}>
                        <FormLabel>Наименование</FormLabel>
                        <OutlinedInput name="name" multiline={true} value={name} onChange={e => setName(e.target.value)} />
                    </FormControl>
                    <Button onClick={handleNext} variant="contained" sx={{ m: 1 }}>Выбрать участников</Button>
                </StepContent>
            </Step>,
                <MultiFlexStep key="members">
                    <StepLabel>{members && members.length ? 'Выбрано участников: ' + members.length : 'Выбор участников'}</StepLabel>
                    <StepContent>
                        <DataTable<ClientListDto>
                            columns={personsColumns}
                            style={{ height: 'auto', flex: 1 }}
                            multiple={true}
                            typeId="Person"
                            cmd="PersonUserList"
                            dataParser={ClientListDto.parse}
                            onSelectionChange={(s, t) => setMembers(t.getData().filter(x => x.userId && s.indexOf(x.id) >= 0).map(x => x.userId!))} />
                        <Button onClick={createGroup} variant="contained" sx={{ m: 1 }}>Создать группу</Button>
                    </StepContent>
                </MultiFlexStep>);
            break;
    }

    return <FlexStepper activeStep={activeStep} orientation="vertical" sx={{ height: '100%', pl: 1, pt: 1 }}>
        {steps}
    </FlexStepper>;
}
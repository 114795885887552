import { Box } from "@mui/material";
import ObservableComponent from "../base/ObservableComponent";
import Chat from "./chat";
import ChatList from "./ChatList";
import { model } from "./Model";

export default class Messenger extends ObservableComponent {
    componentDidMount() {
        this._unlisten.push(model.onChange(() => this.forceUpdate()));
    }

    render() {
        return <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
            <ChatList />
            {model.chatId ? <Chat key={'chat' + model.chatId} chatId={model.chatId} /> : <div style={{ flex: 1 }} />}
        </Box>;
    }
}
import { Currency } from '../../models/Types';
import { ColumnData } from '../../components/data/DataTable';
import DataView from '../../components/data/DataView';
import { CurrencyAbout, CurrencyForm } from './CurrencyForm';
import { guides } from '../../guides/Guide';
import { Button, Card, CardActions, CardContent, CardHeader, Typography, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';
import NavLink from '../../components/NavLink';
import route from '../../Router';

guides.currencyGuide = [
  {
    target: '#club-menu-currency',
    placementBeacon: 'right',
    title: 'Услуги',
    content: <>В разделе <b>Услуги</b> можно настроить стоимость предоставляемых услуг.
      <p>При создании нового Клуба в нем по умолчанию уже есть услуга <b>Занятие</b> стоимостью <b>1000 рублей</b></p>
    </>,
  }
];

const columns: ColumnData<Currency>[] = [{
  label: 'Наименование',
  name: 'name',
  filter: true,
  routerLink: true
}, {
  label: 'Стоимость в рублях',
  name: 'mainCurrencyRate',
  filter: true,
  type: 'numeric'
}];

export default function CurrencyView() {
  const theme = useTheme();

  return <DataView<Currency>
    title="Услуга"
    titleMultiple="Услуги"
    typeId="Currency"
    form={[{
      title: 'Услуга',
      element: (id, apiRef, p, setTabApi) => <CurrencyForm id={id} apiRef={apiRef} setTabApi={setTabApi} />
    }]}
    emptyContent={<Card sx={{ maxWidth: 500 }}>
      <CardHeader style={{ backgroundColor: theme.palette.secondary.main }} title="Услуги не найдены" />
      <CardContent>
        <Typography>
          В разделе <b>Услуги</b> можно настроить стоимость предоставляемых услуг.
        </Typography>
        <CurrencyAbout />
      </CardContent>
      <CardActions>
        <Button variant="contained" color="success" startIcon={<Add />} sx={{ mx: 'auto' }} onClick={() => route.setState([['id', 'new']])}>
          Добавить Услугу
        </Button>
      </CardActions>
    </Card>}
    tableProps={{
      columns: columns
    }} />;
}
import DataView from '../../../components/data/DataView';
import { ColumnData } from '../../../components/data/DataTable';
import { AccountForm } from './AccountForm';
import { Account } from '../../../models/Types';
import DataListView from '../../../components/data/DataListView';
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import { tariffStore } from '../../tariffs/TariffCombo';
import Proxy from '../../../models/Proxy';
import { guides } from '../../../guides/Guide';
import { currencyStore } from '../../currency/CurrencyCombo';

guides.accountGuide = [{
    target: '[data-list-view-typeid="Account"] .datatable-toolbar',
    disableBeacon: true,
    title: 'Баланс пользователя',
    content: <>Информация о балансе в конкретном или во всех Клубах, в которых Вы состоите</>
}, {
    target: '[data-name="currency"]',
    title: 'Баланс. Услуги Клуба',
    placementBeacon: 'right-start',
    content: <>
        В столбце <b>Наименование</b> отображаются названия услуги Клуба.
        <p>Например, <i>Индивидуальное</i> или <i>Групповое</i> занятие.</p>
        <p>Также всегда есть строка <i>Рубль</i> с денежным балансом в рублях.</p>
    </>
}, {
    target: '[data-name="currencyId"]',
    title: 'Баланс. Пополнение',
    placementBeacon: 'right-start',
    content: <>
    </>
}, {
    target: '#account-all-tenants',
    title: 'Баланс по Клубам',
    placementBeacon: 'right-start',
    content: <>Для отображения баланса по всем Клубам, необходимо поставить галочку <b>Все Клубы</b></>
}];

export const accountColumns: ColumnData<Account>[] = [{
    label: 'Наименование',
    name: 'currency',
    filter: true,
    renderer: v => v || 'Рубль'
}, {
    label: 'Количество',
    name: 'amount',
    filter: true
}, {
    label: 'Пополнение',
    name: 'currencyId',
    store: tariffStore,
    sx: () => {
        return {
            textAlign: 'center'
        }
    },
    renderer: (v, obj, theme, table) => <Button variant="outlined" sx={{ my: .5 }}>
        {v ? 'Купить (' + obj.mainCurrencyRate + '₽)' : 'Пололнить'}
    </Button>
}, {
    label: 'Входит в состав Абонемента',
    name: 'currencyId',
    store: tariffStore,
    renderer: (v, obj, theme, table) => v ?
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {tariffStore.data?.filter(x => x.obj?.currenciesId && x.obj.currenciesId.indexOf(v) >= 0)
                .map(x => <Button variant="outlined"
                    sx={{ my: .5 }}
                    onClick={() => {
                        Proxy.post('BuyTariff', {
                            tariffId: x.id,
                            count: 1
                        }).then(x => {
                            debugger;
                        });
                    }}>{x.value} ({x.obj?.amount}₽)</Button>)}
        </Box> : null
}, {
    label: 'Ближайшее списание',
    name: 'currencyId',
    renderer: v => ''
}];

export class AccountListView extends DataListView<Account>{
    buildAddButton() {
        return <FormControlLabel id="account-all-tenants" control={<Checkbox />} label="Все Клубы" />;
    }
}

export default () => <DataView<Account>
    title="Баланс"
    titleMultiple="Баланс"
    typeId="Account"
    listView={(props, setListApi) => <AccountListView {...props} apiRef={setListApi} />}
    form={[{
        title: 'Локация',
        element: (id, apiRef, params) => <AccountForm id={id} apiRef={apiRef} />
    }]}
    guide="accountGuide"
    newTitle=''
    tableProps={{
        columns: accountColumns
    }} />
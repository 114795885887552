import { useTheme } from '@emotion/react';
import { Circle, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { IconButton, Theme } from '@mui/material';
import React, { useState, Dispatch, SetStateAction, ReactNode } from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { IHierarchicalEntity } from '../../models/Types';
import { BaseDataTable, DataTableProps, DraggableDataTableComponent, DraggableProps, Renderer, ThemeProps } from './DataTable';

function TreeCell<T extends IHierarchicalEntity>({ renderer, onOpen, parents, hasChildren, val, obj, theme, table }: {
    parents?: number[],
    hasChildren?: boolean,
    renderer: Renderer<T>,
    onOpen: (open: boolean) => void,
    val: any, obj: T, theme: Theme, table: BaseDataTable<T>
}) {
    const [open, setOpen] = useState(false);

    return <>
        {parents?.map(x => <IconButton size="small" disabled><Circle color="disabled" sx={{ height: '.5em' }} /></IconButton>)}
        {hasChildren ?
            <IconButton size="small"
                onClick={e => {
                    onOpen(!open);
                    setOpen(!open);
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton> : <IconButton size="small" disabled><Circle color="disabled" sx={{ height: '.5em' }} /></IconButton>}
        {renderer(val, obj, theme, table)}
    </>;
}

function TreeRow({ renderer, refApi, hidden }: {
    renderer: () => ReactNode,
    hidden: boolean,
    refApi: (api: { setHidden: Dispatch<SetStateAction<boolean>> }) => void
}) {
    const [_h, setHidden] = useState(hidden);

    refApi && refApi({ setHidden });

    return <>{_h ? null : renderer()}</>;
}

class DraggableDataTreeComponent<T extends IHierarchicalEntity> extends DraggableDataTableComponent<T>{
    private nodes: { [id: number]: { obj: T, parents?: number[], hasChildren?: boolean, open?: boolean, setHidden?: Dispatch<SetStateAction<boolean>> } };

    constructor(p: DraggableProps<T>) {
        super(p);

        this.nodes = {};
    }

    protected buildRow(row: T, i: number, draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) {
        return <TreeRow key={row.id}
            hidden={!!row.parentId}
            refApi={api => api && (this.nodes[row.id].setHidden = api.setHidden)}
            renderer={() => super.buildRow(row, i, draggableProvided, snapshot)} />
    }

    protected buildRows(data: T[]) {
        var pids: number[] = [];
        var pchains: number[][] = [];
        var result: T[] = [];
        while (true) {
            var pid = pids[pids.length - 1];
            var next = data.find(x => result.indexOf(x) < 0 && x.parentId == pid);
            if (next) {
                this.nodes[next.id] = {
                    obj: next,
                    parents: pchains.length ? pchains[pchains.length - 1] : undefined
                }

                pid && (this.nodes[pid].hasChildren = true);

                result.push(next);
                pids.push(next.id);
                pchains.push(pids.slice());
            } else if (pids.length) {
                pids.pop();
                pchains.pop();
            } else {
                break;
            }
        }

        return super.buildRows(result);
    }

    protected getNodes() {
        return this.nodes;
    }

    protected applyColumns(p: ThemeProps<T>) {
        super.applyColumns(p);

        const col = p.columns[0];
        const renderer = col.renderer!;
        if (!(renderer as any)._treeApplied) {
            col.renderer = (val: any, obj: T, theme: Theme, table: BaseDataTable<T>) => {
                var nodes = (table as DraggableDataTreeComponent<T>).getNodes();

                return <TreeCell
                    hasChildren={nodes[obj.id]?.hasChildren}
                    renderer={renderer}
                    parents={nodes[obj.id]?.parents}
                    val={val}
                    obj={obj}
                    theme={theme}
                    table={table}
                    onOpen={o => {
                        nodes[obj.id].open = o;
                        for (var id in nodes) {
                            let node = nodes[id];
                            if (node.setHidden && node.parents && node.parents.indexOf(obj.id) >= 0) {
                                node.setHidden(o ? !!node.parents.find(p => !nodes[p].open) : true);
                            }
                        }
                    }} />;
            };

            (col.renderer as any)._rendererSet = (renderer as any)._rendererSet;
            (col.renderer as any)._treeApplied = true;
        }
    }
}

export default function DataTree<T extends IHierarchicalEntity>(props: DataTableProps<T>) {
    const theme = useTheme();
    return <DraggableDataTreeComponent<T> {...({ ...props, theme } as DraggableProps<T>)} />;
}
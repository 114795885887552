import { Step } from 'react-joyride';
import { userMenuGuide } from '../nav/UserMenu';
import { Typography } from '@mui/material';

const welcome = [
    {
        target: '#root',
        title: <>Добро пожаловать <Typography component={p => <span {...p} />} color="error">ВКлуб</Typography></>,
        content: 'Познакомьтесь с основными инструментами ВКлубе за несколько простых шагов!',
        styles: {
            tooltipContent: {
                textAlign: 'center'
            }
        },
        placement: "center"
    },
    {
        target: '#desktop-menu',
        title: <>Рабочий стол</>,
        placement: 'right'
    },
    {
        target: '#schedule-menu',
        title: <>Расписание</>,
        placement: 'right'
    },
    {
        target: '#programs-menu',
        title: <>Программы</>,
        placement: 'right'
    },
    {
        target: '#locations-menu',
        title: <>Локации</>,
        placement: 'right'
    },
    ...userMenuGuide,
    {
        target: '#chat-status',
        title: 'Чаты',
        content: <>Все внутренние коммуникации с представителями Клубов, коллегами, клиентами, а также тех поддержкой доступны в разделе <b>Чаты</b></>,
    },
    {
        target: '#tenant-selector-new',
        title: 'Создайте свой Клуб',
        placementBeacon: 'right',
        content: <>
            Клуб - отдельный бизнес-аккаунт в котором можно вести независимый список клиентов, сотрудников, расписание, занятия, расчеты и многое другое
        </>,
    }
] as Step[];

export default welcome;
export enum ResponseStatus{
    /// <summary>
    /// Статус неопределен
    /// </summary>
    None = 0,

    /// <summary>
    /// Требуется ответ
    /// </summary>
    Waiting = 1,

    /// <summary>
    /// Приглашение принято
    /// </summary>
    Accepted = 2,

    /// <summary>
    /// Приглашение отклонено
    /// </summary>
    Declined = 3,

    /// <summary>
    /// Возможно
    /// </summary>
    Maybe = 4
}

export enum OperationType {
    /// <summary>
    /// Начисление
    /// </summary>
    Invoice = 0,

    /// <summary>
    /// Платеж по начислению
    /// </summary>
    Payment = 1,

    /// <summary>
    /// Зачисление на счет
    /// </summary>
    Enrollment = 2,

    /// <summary>
    /// Возврат
    /// </summary>
    Refund = 3
}

export const operationTypes = {
    [OperationType.Invoice]: 'Начисление',
    [OperationType.Payment]: 'Платеж', // по начислению
    [OperationType.Enrollment]: 'Зачисление', // на счет
    [OperationType.Refund]: 'Возврат'
}

export enum PaymentState {
    None = 0,

    Open = 1,

    Payment = 2,

    Success = 3,
    Error = 4
}
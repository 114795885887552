import { FormControl, FormLabel, OutlinedInput, OutlinedInputProps } from "@mui/material";
import React from "react";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { Location } from "../../models/Types";
import { AddressSuggestions, DaDataSuggestion, DaDataAddress } from 'react-dadata';
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { LatLngTuple, Map, icon, LatLng } from "leaflet";
import markerShadowUrl from "leaflet/dist/images/marker-shadow.png"
import markerIconUrl from "leaflet/dist/images/marker-icon.png"
import { FormTextInput } from "../../components/form/TextInput";

interface ILocation {
    lat?: number
    lon?: number
}

const markerIcon = icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: markerIconUrl,
    shadowUrl: markerShadowUrl
});

function AddressOutlinedInput({ onChange, ...props }: OutlinedInputProps) {
    return <OutlinedInput onChange={e => {
        e.target.parentElement?.setAttribute('value', e.target.value);
        onChange && onChange(e);
    }} {...props} />;
}

const defaultCenter: LatLngTuple = [55.794438, 49.111451];

export class LocationForm extends AbstractTypedDataForm<Location, ILocation, {}> {
    private address?: DaDataSuggestion<DaDataAddress>;
    private map?: Map | null;

    constructor(p: ILocation) {
        super(p);

        this.onAdressChange = this.onAdressChange.bind(this);
        this.onMapRef = this.onMapRef.bind(this);
    }

    protected getTypeName(): React.ReactNode {
        return 'Локация';
    }

    componentDidMount(): void {
        super.componentDidMount();

        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) =>
            this.map?.setView([position.coords.latitude, position.coords.longitude], 13));
    }

    componentWillReceiveProps(n: Readonly<{ id: number } & ILocation>, nextContext: any): void {
        super.componentWillReceiveProps(n, nextContext);
        const p = this.props;
        if (p.id == n.id && n.lat && n.lon && (p.lat != n.lat || p.lon != n.lon)) {
            this.setLatLon(new LatLng(n.lat, n.lon));
        }
    }

    clear(): void {
        this.address = undefined;
        super.clear();
    }

    protected getTypeId(): string {
        return 'Location';
    }

    protected createEntity(): Location {
        return new Location();
    }

    protected responseToState(response: any) {
        var result = super.responseToState(response);
        var e = result?.entity as Location;
        if (e) {
            this.address = {
                value: e.address || '',
                unrestricted_value: e.suggestAddress || '',
                data: {
                    geo_lat: e.lat?.toString() || null,
                    geo_lon: e.lon?.toString() || null
                }
            } as any;

            requestAnimationFrame(() => {
                if (this.map) {
                    let ll = this.getLLFromAddress();
                    if (ll) {
                        this.map.setView(ll, 15);
                    }
                }
            });
        }

        return result;
    }

    private onAdressChange(s?: DaDataSuggestion<DaDataAddress>) {
        this.address = s;

        this.applyMapEntity(this.state.entity) && this.forceUpdate();;
    }

    private applyMapEntity(entity: Location) {
        if (this.address) {
            entity.address = this.address.value;
            entity.suggestAddress = this.address.unrestricted_value;

            const ll = this.getLLFromAddress(entity);
            if (ll) {
                entity.lat = ll[0];
                entity.lon = ll[1];

                this.map?.setView(ll, 15);
            }

            return true;
        }
    }

    private getLLFromAddress(entity?: Location): LatLngTuple | undefined {
        const d = this.address?.data;
        if (d && d.geo_lat && d.geo_lon) {
            return [parseFloat(d.geo_lat), parseFloat(d.geo_lon)];
        } else if (entity && entity.lat && entity.lon) {
            return [entity.lat, entity.lon];
        }
    }

    onMapRef(map?: Map | null) {
        if (this.map = map) {
            map.on('click', e => this.setLatLon(e.latlng));
        }
    }

    setLatLon(latlng: LatLng) {
        this.state.entity.address = undefined;
        this.state.entity.suggestAddress = undefined;
        this.address = undefined;
        this.applyMapEntity(this.state.entity);

        const coords = {
            lat: latlng.lat,
            lon: latlng.lng
        }

        Object.assign(this.state.entity, coords);
        this.forceUpdate();

        fetch('https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address', {
            method: 'post',
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token 6cc7b3db9c2133e72e059ccd0f58d553e283297d"
            },
            body: JSON.stringify(coords)
        })
            .then(x => x.json())
            .then(x => {
                this.address = x.suggestions[0];
                if (this.address) {
                    this.address.data.geo_lat = coords.lat.toString();
                    this.address.data.geo_lon = coords.lon.toString();
                    this.applyMapEntity(this.state.entity);
                    Object.assign(this.state.entity, coords);
                    this.forceUpdate();
                }

            });
    }

    protected buildItems(entity?: Location | undefined): React.ReactNode {
        const ll = this.getLLFromAddress(entity);
        return <>
            <FormTextInput required={true} label="Наименование" name="name" form={this} entity={entity} />

            <FormControl sx={{ zIndex: 1024 }}>
                <FormLabel>Адрес</FormLabel>
                <AddressSuggestions
                    customInput={AddressOutlinedInput}
                    token="6cc7b3db9c2133e72e059ccd0f58d553e283297d"
                    value={this.address}
                    onChange={this.onAdressChange} />
            </FormControl>
            <MapContainer ref={this.onMapRef} style={styles.map} center={ll || defaultCenter} zoom={13}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {ll && <Marker position={ll} icon={markerIcon}><Popup>{this.address?.unrestricted_value}</Popup></Marker>}
            </MapContainer>
            <FormControl>
                <FormLabel>Примечания по схеме проезда, прохода к локации</FormLabel>
                <OutlinedInput name="description" multiline={true} defaultValue={entity?.description} onChange={this.onChange} />
            </FormControl>
        </>;
    }
}

const styles = {
    map: {
        flex: 1,
        overflow: 'hidden',
        margin: '1rem 0',
        minHeight: 350
    }
}
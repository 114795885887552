import { FormControl, FormLabel } from "@mui/material";
import { PersonForm } from "../../components/person/PersonForm";
import { Person } from "../../models/Types";
import ClientStatusCombo from "../clientStatus/ClientStatusCombo";

export class ClientForm extends PersonForm {
    protected getTypeName(): React.ReactNode {
        return 'Клиент';
    }

    protected buildItems(entity?: Person) {
        const items = super.buildItems.call(this, entity);
        items.splice(2, 0,
            <FormControl>
                <FormLabel>Статус обработки</FormLabel>
                <ClientStatusCombo
                    name="statusId"
                    allowAdd={true}
                    value={entity?.statusId}
                    onDataLoad={values => this.state.id ||
                        values?.length &&
                        this.state.entity &&
                        !this.state.entity.statusId &&
                        (this.state.entity.statusId = (values.sort((a, b) => a.obj.index - b.obj.index).map(x => x.id)[0] || values[0].id)) &&
                        this.forceUpdate()
                    }
                    onChange={this.onChange} />
            </FormControl>);

        return items;
    }
}
import { Add, CalendarMonth, ChevronLeft, ChevronRight, Forum, Search, ThreePOutlined, Videocam } from "@mui/icons-material";
import { Avatar, Badge, List, ListItemAvatar, ListItemButton, ListItemText, OutlinedInput, Tab, Tabs, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import ObservableComponent from "../base/ObservableComponent";
import { model } from "./Model";
import NewChat from "./NewChat";
import { MemberDto } from "./entities";
import { ChatType } from "./enums";

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        color: theme.palette.success.main,
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.1)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(1.33)',
            opacity: 0,
        },
    },
}));

const groups: [string, JSX.Element, (x: any) => boolean][] = [
    ['Все', <Forum />, x => true],
    ['Личные', <ThreePOutlined />, x => x.chat.type == ChatType.Person]
]

const FlexTabs = styled(Tabs)`
    & .MuiTabs-flexContainer {
        height: 100%;
    }
`;

function ChatListItem({ chat, start, end }: { chat: MemberDto, start?: Date, end?: Date }) {
    const now = start && end ? new Date() : undefined;

    var avatar = chat.avatarUrl ? <Avatar src={chat.avatarUrl} /> : <Avatar>{chat.displayName[0].toUpperCase()}</Avatar>;
    if (chat.chat.activeStreamId) {
        avatar = <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            badgeContent={<Videocam color="success" sx={{ opacity: 2/3 }} />}
        >
            {avatar}
        </StyledBadge>
    }

    return <ListItemButton
        selected={chat.chat.id == model.chatId}
        onClick={() => model.chatId = chat.chat.id}>
        {start && end && now && start <= now && end > now ?
            <Box key="currentTs" style={{ top: `calc(${(now.getTime() - start.getTime()) * 100 / (end.getTime() - start.getTime())}% - .25rem)` }}
                sx={{
                    position: "absolute", width: 0, height: 0, left: 0,
                    borderTop: '.5rem solid transparent',
                    borderBottom: '.5rem solid transparent',
                    borderLeft: '.5rem solid', borderLeftColor: 'error.main'
                }} /> :
            null}
        <ListItemAvatar>{avatar}</ListItemAvatar>
        <ListItemText
            secondaryTypographyProps={{ sx: { display: 'flex' } }}
            secondary={<>
                <Typography variant="caption" sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: 1, overflow: 'hidden' }}>
                    {chat.chat.lastText}
                </Typography>
                <Typography variant="caption">{moment(chat.chat.lastTs).format('LT')}</Typography>
            </>}>
            {chat.displayName}{start && end ? <Typography variant="body2" className="event-times" fontWeight="bold">{moment(start).format('LT')} - {moment(end).format('LT')}</Typography> : null}
            {chat.unreaded ? <Badge badgeContent={chat.unreaded} color="primary" sx={{ right: '1em', top: '1.25em', position: "absolute" }} /> : null}
        </ListItemText>
    </ListItemButton>;
}

export default class ChatList extends ObservableComponent<{}, { search?: string, groupId: number, collapsed: boolean }> {
    constructor(p: any) {
        super(p);

        this.state = {
            collapsed: false,
            groupId: 0
        };
    }

    componentDidMount() {
        this._unlisten.push(model.onChange(() => this.forceUpdate()));
    }

    render() {
        var body: React.ReactNode;
        const groupId = this.state.groupId;
        if (groupId < groups.length) {
            var chats = model.chats.filter(groups[groupId][2]);

            const s = this.state?.search?.toUpperCase();
            if (s) {
                chats = chats.filter(x => x.displayName[0].toUpperCase().indexOf(s) >= 0);
            }

            body = <>
                <Box sx={{ p: 1 }}>
                    <OutlinedInput startAdornment={<Search color="disabled" />} placeholder="Поиск по группам" value={this.state?.search} onChange={e => this.setState({ search: e.target.value })} />
                </Box>
                <List>
                    {chats.map(x => <ChatListItem chat={x} />)}
                </List>
            </>
        } else if (groupId == groups.length) {
            var s = new Date(),
                e = s;
            s = new Date(s.getFullYear(), s.getMonth(), s.getDate(), 9);

            body = <>
                <List>
                    {model.chats.map(x => {
                        e = new Date(s.getFullYear(), s.getMonth(), s.getDate(), s.getHours() + 1);
                        var result = <ChatListItem chat={x} start={s} end={e} />;
                        s = e;
                        return result;
                    })}
                </List>
            </>
        } else {
            body = <NewChat onClose={() => this.setState({ groupId: 0 })} />;
        }

        return <Box sx={{flex: 'none', display: 'flex' }}>
            <Box sx={{ flex: 'none', display: 'flex', flexDirection: 'column', borderRight: '1px solid', borderColor: 'divider' }}>
            <Tab 
                icon={this.state.collapsed? <ChevronRight />: <ChevronLeft />} 
                sx={{ backgroundColor: 'secondary.light', borderBottom: '1px solid', borderColor: 'divider' }} 
                onClick={()=>this.setState({collapsed: !this.state.collapsed})} />

            <FlexTabs orientation="vertical"
                variant="scrollable"
                sx={{ backgroundColor: 'secondary.light', flex: 1 }}
                value={groupId}
                onChange={(e, groupId) => this.setState({ groupId })}>


                {groups.map((x, i) => <Tab label={x[0]} icon={x[1]} />)}

                <Tab label='Календарь' icon={<CalendarMonth />} sx={{ borderTop: '1px solid', borderColor: 'divider' }} />

                <Tab label='Добавить' icon={<Add />} sx={{ mt: 'auto' }} />
            </FlexTabs>
            </Box>
            {this.state.collapsed?
                null:
                <Box sx={{ flex: 1, minWidth: '20rem',borderRight: '1px solid', borderColor: 'divider'  }}>
                    {body}
                </Box>}
        </Box>;
    }
}
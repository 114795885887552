import { Add, Circle, ColorLens } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, FormControl, FormLabel, IconButton, Link, Menu, OutlinedInput, Paper, Theme, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult, ResponderProvided, DraggableLocation, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import Proxy from "../../models/Proxy";
import { ClientStatus } from "../../models/Types";
import { ClientStatusForm } from "../clientStatus/ClientStatusForm";
import { ClientListDto } from "./ClientList";
import { guides } from "../../guides/Guide";
import NavLink from "../../components/NavLink";

/**
 * Moves an item from one list to another list.
 */
const move = (source: ClientListDto[], destination: ClientListDto[], droppableSource: DraggableLocation, droppableDestination: DraggableLocation) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {
    [droppableSource.droppableId]: sourceClone,
    [droppableDestination.droppableId]: destClone
  };

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle, status?: ClientStatus) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderLeft: '3px solid ' + status?.color,
  // change background colour if dragging
  background: isDragging ? "lightgreen" : undefined,
  // styles we need to apply on draggables
  ...draggableStyle
} as any);

const getListStyle = (theme: Theme, isDraggingOver: boolean) => ({
  background: isDraggingOver ? theme.palette.grey[100] : theme.palette.background.default,
  verticalAlign: 'initial',
  borderRight: '1px solid ' + theme.palette.divider,
  padding: grid,
  minWidth: 250
});

export default function ClientBoard({ style }: { style?: React.CSSProperties }) {
  const [state, setState] = useState<ClientListDto[]>();
  const [statuses, setStatuses] = useState<ClientStatus[]>();
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState<any | undefined>(undefined);

  const theme = useTheme();

  if (!state && !loading) {
    setLoading(true);

    Proxy.get('PersonList')
      .then(x => { setState(x.result.data.map((d: any) => Object.assign(new ClientListDto(), d, d.entity))); applyEmptyStatus(); });

    Proxy.get('PersonStatusList').then(x => { setStatuses(x.result.data.map((x: any) => Object.assign(new ClientStatus(), x))); applyEmptyStatus() });
  }

  function applyEmptyStatus() {
    if (statuses && state) {
      if (state.find(x => !x.statusId)) {
        if (statuses[0].id) {
          setStatuses([Object.assign(new ClientStatus(), { name: '' }), ...statuses]);
        }
      } else if (!statuses[0].id) {
        setStatuses(statuses.filter((x, i) => i));
      }

      setLoading(false);
    }
  }

  function onDragEnd(result: DropResult, provided: ResponderProvided) {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const sInd = statuses![+source.droppableId].id;
    const dInd = statuses![+destination.droppableId].id;
    const client = state!.filter(x => x.statusId == sInd)[source.index];

    client.statusId = dInd;
    setState(state);
    Proxy.post('PersonSetStatus', dInd, { id: client.id })
      .then(x => {
        if (!x.success || !x.result) {
          client.statusId = sInd;
          setState([...state!]);
        }
      });
  }

  return <div style={{ width: '100%', maxHeight: '100%', ...style, overflow: 'auto' }}>
    <table>
      <thead>
        <tr>
          {statuses?.sort((a: any, b: any) => a.index - b.index)?.map((el, ind) => (
            <th>
              <Typography variant="subtitle1" sx={{ display: "flex" }}><Circle htmlColor={el.color} sx={{ marginRight: 1 }} /> {el.name}</Typography>
              <Divider sx={{ marginY: 1 }} />
            </th>
          ))}

          <th />
        </tr>
      </thead>
      <tbody>
        <tr>
          <DragDropContext onDragEnd={onDragEnd}>
            {statuses?.sort((a: any, b: any) => a.index - b.index)?.map((el, ind) => (
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <td ref={provided.innerRef} style={getListStyle(theme, snapshot.isDraggingOver)} {...provided.droppableProps}>
                    {state?.filter(x => x.statusId == el.id).map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => {
                          var c = item.contacts;
                          return <Paper ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, el)}>
                            <NavLink href={item?.id.toString() as string} sx={{ display: "flex", color: 'primary.main', fontWeight: 500, textDecoration: "none" }}>
                              <Avatar src="https://dscontrol.ru/wp-content/uploads/promo-img.png" sx={{ marginRight: .5 }} />
                              {item.fio}
                            </NavLink>
                            {c.length ? [<Divider sx={{ marginY: .7 }} />, c] : null}
                          </Paper>
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </td>
                )}
              </Droppable>
            ))}
          </DragDropContext>

          <td>
            {edit?.id === 0 ? <ClientStatusForm id={0} /> : <Button startIcon={<Add />} onClick={() => setEdit({ id: 0 })}>Новый статус</Button>}
          </td>
        </tr>
      </tbody>
    </table >
  </div>;
}
import { Circle } from "@mui/icons-material";
import RemoteCombo, { configureSuggestStore, RemoteComboProps } from "../../components/form/RemoteCombo";
import { ClientStatus } from "../../models/Types";

const cmd = 'PersonStatusList';
const valueField = 'name';

export const personStatusStore = configureSuggestStore<ClientStatus>(cmd, valueField, 30 * 60);

export default function ClientStatusCombo({ name, value, onDataLoad, onChange, allowAdd }: RemoteComboProps<ClientStatus>) {
    return <RemoteCombo<ClientStatus>
        name={name}
        typeId="PersonStatus"
        allowAdd={allowAdd}
        valueField={valueField}
        store={personStatusStore}
        value={value}
        itemRenderer={x => <><Circle htmlColor={x.obj?.color} sx={{ marginRight: 1 }} />{x.value}</>}
        onDataLoad={onDataLoad}
        onChange={onChange} />;
}
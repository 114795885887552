import { EducationProgram } from "../../models/Types";
import RemoteCombo, { configureSuggestStore, RemoteComboProps } from "../../components/form/RemoteCombo";

const cmd = 'EducationProgramList';
const valueField = 'name';

export const programStore = configureSuggestStore<EducationProgram>(cmd, valueField, 30 * 60);

export default function ProgramCombo(props: RemoteComboProps<EducationProgram>) {
    return <RemoteCombo<EducationProgram>
        typeId="EducationProgram"
        valueField={valueField}
        store={programStore}
        cmd={cmd}
        {...props}
        ref={undefined} />;
}
import { HubConnection } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { generateToken, getUser, listenUser } from "../../models/Identity";
import Observer from "../../models/Observer";
import { ChatDto, MemberDto, User } from "./entities";

const root = document.location.hostname == 'localhost' ? 'http://localhost:5005' : 'https://intragram.chat';

declare var IntragramHub: any;

var hub: Promise<any> | undefined;

class Model extends Observer {
	user?: User
	users: User[]
	private _chats: MemberDto[];
	private _chatId?: number;

	constructor() {
		super();

		this._chats = [];
		this.users = [];
	}

	get chatId(): number | undefined {
		return this._chatId;
	}

	set chatId(id: number | undefined) {
		this._chatId = id;
		this.fireChange();
	}

	get chats(): MemberDto[] {
		return this._chats;
	}

	set chats(chats: MemberDto[]) {
		this._chats = chats;
		this.fireChange();
	}

	getChat(id: number) {
		return this._chats.find(x => x.chat.id == id);
	};

	onChange(fn: Function) {
		return this.listen('change', fn);
	}

	fireChange() {
		return this.fire('change', [this]);
	}
};

export const model = new Model();

export function resolveHub(): Promise<any> {
	if (hub) {
		return hub;
	}

	return hub = generateToken().then(x => getHub(x.result));
}

export function useChatStatus() {
	const [status, setStatus] = useState<string | null>(null);

	useEffect(() => {

		const listeners: Function[] = [];

		resolveHub().then(hub => {
			function handleStatusChange() {
				setStatus(hub.getStatus());
			}

			if (hub) {
				listeners.push(hub.onConnect(handleStatusChange));
				listeners.push(hub.onDisconnect(handleStatusChange));
				listeners.push(hub.onConnecting(handleStatusChange));
			}
		});

		return () => listeners.forEach(x => x());
	});

	return status;
}

listenUser(() => {
	if (!getUser()?.isAuthenticated && hub) {
		IntragramHub.disconnect();
		hub = undefined;
	}
})

function getHub(token: string) {
	var e: any = null;

	IntragramHub
		.setUrl(root)
		.setAppId(4)
		.setAuth("Token", {
			clientId: getUser().id,
			token: token
		});

	IntragramHub.onConnect(function (token: string, user: User) {
		model.user = user;
		model.users = [user];

		model.fireChange();

		IntragramHub.chatList({ query: null, types: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], limit: 100 })
			.then((x: any) => {
				model.chats = x;
			});
	})/**
		.onDisconnect(function () {
			console.log("Chat disconnected"),
				e.callEvent("onConnectionState", ["connecting"]),
				e.connectAttemptCount = e.connectAttemptCount + 1,
				e.connectAttemptCount > 2 ? (console.log("Ошибка соединения с чатами. Превышено количество попыток: " + e.connectAttemptCount),
					void e.callEvent("onConnectionState", ["fail"])) : void window.setTimeout(function () {
						console.log("Ошибка соединения с чатами. Повторное соединение... Попытка: " + e.connectAttemptCount),
							IntragramHub.connect()
					}, 2e3)
		})/**/
	IntragramHub.onMessage(function (t: any) {
		// getList().data.add(processMessage(t));
	})
	IntragramHub.onSaveChat(function (c: MemberDto) {
		var chatDto = model.chats.find(x => x.chat.id == c.chat.id);
		if (chatDto) {
			chatDto.chat = c.chat;
			chatDto.members = c.members;
		} else {
			model.chats.push(c);
		}

		model.fireChange();
	})
	IntragramHub.onDelivered(function (t: any, i: any) {
		//webix.findBy(e, "conversation").markDelivered(t, i)
	})
	IntragramHub.onMessageRead(function (t: any, i: any) {
		// webix.findBy(e, "dialogs").resetUnread(t);
		// webix.findBy(e, "allgroups").resetUnread(t);
		//webix.findBy(e, "conversation").markRead(t, i)
	})

	return hub = IntragramHub.connect()
		.then(
			(x: HubConnection) => {
				IntragramHub.getStatus = () => x.state;
				return hub = Promise.resolve(IntragramHub)
			},
			(t: any) => hub = undefined
		);
}
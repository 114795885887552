import { Box, FormControl, FormLabel, OutlinedInput } from "@mui/material";
import { Tenant } from "../../models/Types";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import ColorPicker from "../../components/ColorPicker";
import { fetchUser, getUser } from "../../models/Identity";
import route from "../../Router";
import { getTenantId } from "../../models/Tenant";
import { FormApiRef } from "../../components/data/ApiRef";

export class TenantForm extends AbstractTypedDataForm<Tenant> {
    protected getTypeId(): string {
        return 'Tenant';
    }

    protected createEntity() {
        return new Tenant();
    }

    protected buildItems(entity?: Tenant) {
        return <>
            <FormControl>
                <FormLabel>Наименование</FormLabel>
                <OutlinedInput name="name" defaultValue={entity?.name} onChange={this.onChange} />
            </FormControl>

            <FormControl>
                <FormLabel>Цвет в интерфейсе</FormLabel>
                <ColorPicker value={entity?.color} onChange={(e, value) => { this.onChange({ target: { name: 'color', value } }); this.forceUpdate() }} />
            </FormControl>
        </>;
    }

    buildButtons(entity?: Tenant) {
        if (!entity?.id || entity?.ownerId == getUser().id) {
            return super.buildButtons(entity);
        }

        return [];
    }
}

function apiRef(api?: FormApiRef) {
    api?.onSave(()=>fetchUser());
    api?.onDelete(()=>fetchUser());
}

export default function TenantView({ idRoute }: { idRoute: string }) {
    const id = route.get(idRoute) || getTenantId();
    return <Box sx={{ p: 1, width: 500 }}>
        <TenantForm id={parseInt(id) || 0} apiRef={apiRef} />
    </Box>;
}
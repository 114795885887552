import { Call, Link, Mail } from "@mui/icons-material";
import SvgIcon from '@mui/material/SvgIcon';
import React from "react";
import { ColumnData } from "../data/DataTable";
import Logo from "../../icons/Logo";
import { Person } from "../../models/Types";

type SvgIconComponent = typeof SvgIcon;
const buildContacts = (client: PersonListDto, field: keyof PersonListDto, icon: SvgIconComponent, type: string) => {
    const contacts = client[field] as string[];
    return contacts
        .map((x, i) => <a key={field + i} className="contact-info-link" href={type + ':' + x}>{React.createElement(icon, { color: 'error' })}{x}</a>);
}

export class PersonListDto extends Person {
    static parse(d: any) {
        const dto = new PersonListDto();
        Object.assign(dto, d);
        d.createDate && (dto.createDate = new Date(d.createDate));
        return dto;
    }

    get contacts() {
        var result = [];
        if (this.phones?.length) {
            result.push(buildContacts(this, 'phones', Call, 'tel'));
        }

        if (this.emails?.length) {
            result.push(buildContacts(this, 'emails', Mail, 'mailto'));
        }

        if (this.links?.length) {
            result.push(this.links.map((x, i) => <div key={'link' + i} className="contact-info-link"><Link color='error' />{x}</div>));
        }

        return result;
    }
}

export const personListColumns: ColumnData<PersonListDto>[] = [
    {
        label: 'Имя',
        name: 'fio',
        filter: true,
        routerLink: true
    },
    {
        width: 185,
        label: 'Контакты',
        name: 'contacts',
        sx: () => {
            return { fontSize: undefined }
        },
        filterFn: (filterValue, e) => {
            const fv = (filterValue as string).toLowerCase();
            return !!e.emails.find(x => x.toLowerCase().indexOf(fv) >= 0)
                || !!e.phones.find(x => x.toLowerCase().indexOf(fv) >= 0)
                || !!e.links.find(x => x.toLowerCase().indexOf(fv) >= 0)
                || !!e.emailNames.find(x => x.toLowerCase().indexOf(fv) >= 0)
                || !!e.phoneNames.find(x => x.toLowerCase().indexOf(fv) >= 0)
                || !!e.linkNames.find(x => x.toLowerCase().indexOf(fv) >= 0);
        },
        filter: true
    },
    {
        width: 205,
        label: 'Дата регистрации',
        name: 'createDate',
        type: 'date',
        filter: 'date'
    },
    {
        width: 75,
        label: 'ВКлубе',
        name: 'userId',
        filter: 'boolean',
        filterFn: (filterValue, entity) => !!entity.userId == filterValue,
        renderer: (val) => val ? <div style={styles.cellIcon}><Logo style={{ height: '100%' }} /></div> : null
    },
];

const styles = {
    cellIcon: {
        height: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    }
}
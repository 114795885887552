import { Box, Card, CardContent, CardHeader, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import NavLink from "../../components/NavLink";

export default function Dashboard() {
    return <Box sx={{ p: 2 }}>
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader title="Задачи" subheader={<>Активных: <b>1</b> Просрочено: <b>0</b></>} />
            <CardContent>
                <FormGroup>
                    <FormControlLabel control={<Checkbox />} label={<NavLink route={[['guide', 'welcome']]}>Пройти ознакомительный тур</NavLink>} />
                </FormGroup>
            </CardContent>
        </Card>
    </Box>
}
import { Box, FormControl, FormLabel, Grid, OutlinedInput } from "@mui/material";
import React from "react";
import { Person, Contact } from "../../models/Types";
import { AbstractTypedDataForm } from "../data/DataForm";
import { FormTextInput } from "../form/TextInput";
import { Contacts } from "./Contacts";
import { PersonUser } from "./PersonUser";
import { DataGridApiRef } from "../data/ApiRef";

export class PersonForm<T extends Person = Person, TProps = {}, TState = {}> extends AbstractTypedDataForm<T, TProps, TState & { contacts: Contact[] }> {
    protected contactsApi?: DataGridApiRef<T>
    // protected responseEntityProp = 'entity';
    protected getTypeId() {
        return 'Person';
    }

    protected createEntity() {
        return new Person() as T;
    }

    clear(): void {
        this.setState({
            id: 0,
            contacts: [],
            entity: new Person()
        });
    }

    protected responseToState(response: any) {
        const state = super.responseToState.call(this, response);
        state && (state.contacts = state.entity.getContacts());
        return state;
    }

    save(e: React.FormEvent<HTMLFormElement>) {
        this.state.entity.setContacts(this.state.contacts);
        return super.save.call(this, e);
    }

    protected buildItems(entity?: T) {
        return [
            <Grid container rowSpacing={2}>
                <Grid item xs={4}>
                    <img src="https://dscontrol.ru/wp-content/uploads/promo-img.png" style={{ width: '100%' }} />
                </Grid>
                <Grid item xs={8}>
                    <FormTextInput required={true} label="Имя (обращение)" name="name" form={this} entity={entity} />
                    <FormTextInput label="Фамилия" name="surname" form={this} entity={entity} />
                    <FormTextInput label="Отчество" name="patronomic" form={this} entity={entity} />
                </Grid>
            </Grid>,

            <Box sx={{ my: 1 }}>
                <Contacts contacts={this.state?.contacts} apiRef={x => this.contactsApi = x} />
            </Box>,

            <FormControl>
                <FormLabel>Примечание</FormLabel>
                <OutlinedInput name="description" multiline={true} defaultValue={entity?.description} onChange={this.onChange} />
            </FormControl>,

            <PersonUser
                typeName={this.getTypeName()}
                contacts={this.state?.contacts}
                client={this.state?.entity}
                onCreate={userId => this.onChange({ target: { name: 'userId', value: userId } })}
                onAddClick={() => this.contactsApi?.add()} />
        ];
    }
}
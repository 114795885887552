import { FormControl, FormLabel } from "@mui/material";
import React from "react";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput } from "../../components/form/TextInput";
import { EducationProgram } from "../../models/Types";
import ProgramCombo, { programStore } from "./ProgramCombo";
import route from "../../Router";
import { arrayReplace } from "../../utils";

export class ProgramForm extends AbstractTypedDataForm<EducationProgram, { parentId?: number }> {
    protected getTypeId(): string {
        return 'EducationProgram';
    }

    protected getTypeName(): React.ReactNode {
        return 'Программа';
    }

    protected createEntity(): EducationProgram {
        var entity = new EducationProgram();
        entity.parentId = this.props.parentId;
        return entity;
    }

    protected onSave(): boolean | void {
        const obj = this.state.entity;
        const id = obj.id;
        arrayReplace(programStore.data,
            x => x.id == id || x.obj?.id == id,
            {
                id,
                value: obj.name,
                obj
            });
    }

    componentWillReceiveProps(nextProps: any, nextContext: any): void {
        super.componentWillReceiveProps(nextProps, nextContext);
        if (this.props.id == nextProps?.id && this.state?.entity && nextProps.parentId && this.state.entity.parentId != nextProps.parentId) {
            this.state.entity.parentId = nextProps.parentId;
            route.setState([['parentId']]);

            this.forceUpdate();
        }
    }

    protected buildItems(entity?: EducationProgram) {
        return <>
            <FormTextInput required={true} label="Наименование" name="name" form={this} entity={entity} />
            <FormControl>
                <FormLabel>Родительская программа</FormLabel>
                <ProgramCombo name="parentId" value={entity?.parentId} onChange={this.onChange} filter={x => x.id !== entity?.id && x.obj?.parentId !== entity?.id} />
            </FormControl>
        </>;
    }
}
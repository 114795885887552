import { Directions, LeakAdd, LockPerson, ViewList } from "@mui/icons-material";
import Logo from "../../icons/Logo";
import { IAppMenu } from "../../nav/AppRoute"
import CurrencyView from "../currency";
import TenantView from "./TenantForm";
import Guide, { guides } from "../../guides/Guide";
import ClientStatus from "../clientStatus";
import Positions from "../positions";

guides.clubMenu = [
  {
    target: '#club-menu-сlientstatus',
    title: 'Статусы клиента',
    content: <>С помощью <b>Статусов клиента</b> можно разделять клиентов например на лидов, активных клиентов с которыми заключены договора, завершивших взаимоотношения по той или иной причине.
      <p>Для удобства каждый <b>Статус клиента</b> имеет цветовую метку.</p>
      <p>При создании нового Клуба в нем по умолчанию уже есть статусы <b>Первичный контакт</b>, <b>В работе</b> и <b>Отказ</b></p>
    </>,
  },
  {
    target: '#positions-menu',
    placementBeacon: 'right',
    placement: 'right',
    title: 'Должности',
    content: <><b>Должности</b> разделяют права <b>Сотрудников</b> в Клубе с помощью ключей доступа.
      <p>Например, для доступа к <b>Клиентам</b>, <b>Группам</b> и Управлению Клубом</p>
      <p>При создании нового Клуба по умолчанию создаются преднастроенные <b>Должности</b>
        <ul>
          <li><i>Руководитель</i> - с полным доступом к всем функциям</li>
          <li><i>Администратор</i> - с доступами к ведению <b>Клиентов</b> и <b>Групп</b></li>
          <li><i>Преподователь</i> - с доступом к ведению закрепленными за ним <b>Групп</b></li>
        </ul>
      </p>
    </>,
  },
].map(x => {
  return {
    ...x,
    placementBeacon: 'right',
    placement: 'right',
  }
});

const clubMenu: IAppMenu = [
  {
    path: "",
    title: 'Основное',
    icon: <Logo />,
    element: () => <TenantView idRoute="subpage" />,
  },
  {
    id: 'club-menu-сlientstatus',
    path: "clientstatus/*",
    icon: <ViewList />,
    tenanted: true,
    title: 'Статусы клиентов',
    element: () => <ClientStatus />,
  },
  {
    id: 'positions-menu',
    path: "positions/*",
    icon: <LockPerson />,
    tenanted: true,
    title: 'Должности',
    element: () => <Positions />,
  }
];

clubMenu.forEach(x => x && (x.path = 'club/' + x.path));

export default clubMenu;
import { Component } from 'react';
import resolveWebRTC, { DeviceType, WebRTCApi } from '../webRTC';
import { ListenerDestructor } from '../../../models/Observer';
import { Button } from '@mui/material';
import { CallEnd } from '@mui/icons-material';
import './stream.css';

interface Props {
    chatId: number
}

interface State {
    chat?: any
}

export default class StreamChat extends Component<Props, State> {
    private wrtc: WebRTCApi;
    private _wrtcListener?: ListenerDestructor;

    constructor(p: Props) {
        super(p);

        this.wrtc = resolveWebRTC();
    }

    componentDidMount() {
        this._wrtcListener = this.wrtc.listen(() => this.forceUpdate());
    }

    componentWillUnmount() {
        this._wrtcListener && this._wrtcListener();
        // this.wrtc && this.wrtc.stop();
    }

    toggleStream(type: DeviceType) {
        if (this.wrtc) {
            this.wrtc.start(this.props.chatId, type);
        }
    }

    onStartVideo() {
        if (this.state.chat.chat.activeStreamId) {
            return this.toggleStream('camera');
        }
    }

    onStartCapture() {
        this.toggleStream('screen');
    }

    buildClients() {
        return this.wrtc?.clients?.filter((x: any) => x.stream)
            .map((client: any) => <video
                key={client.id}
                // name={client.id}
                onClick={(e: any) => {
                    e.target.classList.toggle('full-screen-element');
                    e.target.parentElement.classList.toggle('full-screen');
                }}
                autoPlay
                playsInline
                muted={client.local}
                className={client.local ? 'local-stream' : undefined}
                ref={e => e && (e.srcObject = client.stream)}
            />);
    }

    render() {
        if (this.wrtc && this.wrtc.clients && this.wrtc.clients.length) {
            return <div className="streams-wrapper">
                {this.buildClients()}
                <Button
                    aria-label="Завершить"
                    color="error"
                    variant="contained"
                    startIcon={<CallEnd />}
                    onClick={() => this.wrtc.stop()}
                    sx={{
                        position: 'absolute',
                        bottom: 5,
                        right: 5
                    }}
                >
                    Завершить
                </Button>
            </div>;
        }
    }
}
import DataView from '../../components/data/DataView';
import { ColumnData } from '../../components/data/DataTable';
import { Tariff } from '../../models/Types';
import DataListView from '../../components/data/DataListView';
import { TariffAbout, TariffForm } from './TariffForm';
import { currencyStore } from '../currency/CurrencyCombo';
import { Button, Card, CardActions, CardContent, CardHeader, Typography, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';
import route from '../../Router';
import NavLink from '../../components/NavLink';

const columns: ColumnData<Tariff>[] = [{
    label: 'Наименование',
    name: 'name',
    routerLink: true,
    store: currencyStore
}, {
    label: 'Стоимость',
    name: 'amount',
    type: 'numeric',
    renderer: v => v ? <>{v}<Typography sx={{ display: 'inline-block', ml: .5 }}>₽</Typography></> : undefined
}];

const staticColLength = columns.length;

applyCurrencies();
currencyStore.onLoad(applyCurrencies);

function applyCurrencies() {
    columns.splice(staticColLength, columns.length - staticColLength);

    currencyStore.data?.forEach(x => {
        columns.push({
            label: x.value,
            name: 'name',
            width: 1,
            renderer: (v, o) => {
                if (o.amounts && o.currenciesId) {
                    var i = o.currenciesId.indexOf(x.id);
                    if (i >= 0) {
                        return o.amounts[i];
                    }
                }
            }
        })
    })
}

class TariffList extends DataListView<Tariff> {
    buildAddButton1(newTitleText: string, props?: any) {
        return [
            super.buildAddButton('начисление', props),
            super.buildAddButton('платеж', props),
            super.buildAddButton('зачисление', props)
        ];
    }
}

export default () => {
    const theme = useTheme();
    return <DataView<Tariff>
        title="Абонемент"
        titleMultiple="Абонементы"
        typeId="Tariff"
        listView={(props, setListApi) => <TariffList {...props} apiRef={setListApi} />}
        form={[{
            title: 'Абонемент',
            element: (id, apiRef, p, setTabApi) => <TariffForm id={id} apiRef={apiRef} setTabApi={setTabApi} />
        }]}
        emptyContent={<Card sx={{ maxWidth: 500 }}>
            <CardHeader style={{ backgroundColor: theme.palette.secondary.main }} title="Абонементы не найдены" />
            <CardContent>
                <Typography>
                    <b>Абонементы</b> - продукты Клуба, которые используются для продаж.
                </Typography>
                <TariffAbout />
            </CardContent>
            <CardActions>
                <Button variant="contained" color="success" startIcon={<Add />} sx={{ mx: 'auto' }} onClick={() => route.setState([['id', 'new']])}>
                    Добавить Абонемент
                </Button>
            </CardActions>
        </Card>}
        tableProps={{
            columns: columns,
            dataParser: Tariff.parse,

        }} />
}
import { RecordsDiff, TLRecord, Tldraw, createTLStore, defaultShapeUtils } from '@tldraw/tldraw';
import '@tldraw/tldraw/tldraw.css';
import { useState } from 'react';
import resolveWebRTC from '../messenger/webRTC';

export default function Whiteboard({ chatId }: { chatId: number }) {
    const [store] = useState(() => {
        // Create the store
        const store = createTLStore({
            shapeUtils: defaultShapeUtils,
        })

        // Get the snapshot
        const stringified = localStorage.getItem('my-editor-snapshot')
        if (stringified) {
            const snapshot = JSON.parse(stringified)

            // Load the snapshot
            store.loadSnapshot(snapshot)
        }

        const wrtc = resolveWebRTC();
        wrtc.onData((d: RecordsDiff<TLRecord>, c, e) => {
            store.mergeRemoteChanges(() => {
                store.put(Object.values(d.added));
                store.put(Object.values(d.updated).map(([_, r]) => r));
                store.remove(Object.values(d.removed).map(x => x.id));
            })
        }, 'wb');

        store.listen(e => {
            if (e.source === 'user') {
                wrtc.sendData('wb', e.changes);
            }
        });

		return store
	});

	return <Tldraw store={store} />;
}
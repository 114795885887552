export enum ChatType {
    Person = 0,
    Group = 1,
    Chanel = 2,
    Broadcast = 3
}

export enum MessageType {
    Default = 0,
    Text = 1,
    Photo = 2,
    Audio = 3,
    Video = 4,
    Voice = 5,
    ChatMembersAdded = 16,
    ChatMemberLeft = 17,
    ChatTitleChanged = 18,
    ChatPhotoChanged = 19,
    GroupCreated = 22,
    StreamScheduled = 32,
    StreamStarted = 33,
    StreamEnded = 34,
    ChatMemberRoleChanged = 101,
    GroupDeleted = 102
}

export const systemMessageTypes = [
    MessageType.ChatMembersAdded,
    MessageType.ChatMemberLeft,
    MessageType.ChatTitleChanged,
    MessageType.ChatPhotoChanged,
    MessageType.GroupCreated,
    MessageType.ChatMemberRoleChanged,
    MessageType.GroupDeleted,
]

export enum MemberType {
    Member = 0,
    Administrator = 1,
    Owner = 2
}
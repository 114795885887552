import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0075A2'
    },
    secondary: {
      main: '#EEE0CB',
      light: '#FBF8F3'
    }
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: "secondary"
          }
        }
      }
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: "small"
      }
    },
    MuiToggleButton: {
      defaultProps: {
        size: "small"
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          overflow: "hidden",
          backgroundColor: '#fff'
        },
        input: {
          backgroundColor: '#fff',
          fontSize: '1.1735rem'
        }
      },
      defaultProps: {
        size: "small",
        fullWidth: true,
        minRows: 2,
        maxRows: 6
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          marginLeft: 0
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginTop: 8,
          fontWeight: 500,
          fontSize: '1.0745rem'
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true
      }
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          backgroundColor: '#fff'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: '#fff'
        }
      }
    }
  }
});
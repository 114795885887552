import { Location, Person } from "../../models/Types";
import RemoteCombo, { RemoteComboProps } from "../../components/form/RemoteCombo";

export default function LocationCombo({ name, value, onDataLoad, onChange, allowAdd }: RemoteComboProps<Location>) {
    return <RemoteCombo<Location>
        name={name}
        typeId="Location"
        allowAdd={allowAdd}
        value={value}
        onDataLoad={onDataLoad}
        onChange={onChange} />;
}
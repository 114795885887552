import DataView from '../../components/data/DataView';
import { ColumnData } from '../../components/data/DataTable';
import { EducationProgram } from '../../models/Types';
import { ProgramForm } from './ProgramForm';
import route from '../../Router';

const columns: ColumnData<EducationProgram>[] = [{
    label: 'Наименование',
    name: 'name',
    filter: true,
    routerLink: true
}];

export default () => <DataView<EducationProgram>
    title="Программа"
    titleMultiple="Программы"
    typeId="EducationProgram"
    form={[{
        title: 'Программа',
        element: (id, apiRef) => <ProgramForm id={id} apiRef={apiRef} parentId={route.get('parentId')} />
    }]}
    modes={[{
        text: 'Список',
        mode: 'tree'
    }]}
    tableProps={{
        columns: columns
    }} />
import { FormControl, FormControlProps, FormLabel, OutlinedInput, OutlinedInputProps } from "@mui/material";
import RowFormGroup from "../../components/form/RowFormGroup";
import CurrencyCombo from "./CurrencyCombo";
import { Currency, IEntity } from "../../models/Types";
import { RemoteComboProps } from "../../components/form/RemoteCombo";
import { AbstractDataForm } from "../../components/data/DataForm";

interface PriceProps extends FormControlProps {
    title?: string
    excludeСurrency?: boolean
    amount: OutlinedInputProps
    currency: RemoteComboProps<Currency>
}

interface PriceFormProps<T extends IEntity> extends PriceProps {
    form?: AbstractDataForm<T>
    entity: any
}

export function PriceField({ title, amount, currency, required, excludeСurrency, ...formControl }: PriceProps) {
    return <FormControl required={required} {...formControl}>
        {title && <FormLabel>{title}</FormLabel>}
        <RowFormGroup>
            <OutlinedInput name="amount" type="number" fullWidth={false} sx={{ width: 150 }} required={required} placeholder="количество" {...amount} />
            <CurrencyCombo name="currencyId"
                sx={{ flex: 1 }}
                nullableText={excludeСurrency ? undefined : 'рублей'}
                SelectDisplayProps={{ style: { borderTopLeftRadius: 0 } }}
                required={required}
                {...currency} />
        </RowFormGroup>
    </FormControl>;
}

function PriceFormField<T extends IEntity>({ form, entity, ...props }: PriceFormProps<T>) {
    return <PriceField {...props} />;
}
import DataView from '../../components/data/DataView';
import { EducationGroupListDto } from '../../models/Types';
import { GroupForm } from './GroupForm';
import DataListView from '../../components/data/DataListView';
import { ServiceAgreementForm } from './ServiceAgreementForm';
import route from '../../Router';
import { Theme, useTheme } from '@mui/material';
import { groupColumns } from './GroupList';
import { aggrementColumns, ServiceAgreementList, ServiceAgreementListDto } from './ServiceAgreementList';
import { useState } from 'react';
import { ListApiRef } from '../../components/data/ApiRef';

route.register('clientId', undefined, undefined, x => parseInt(x)?.toString() == x ? parseInt(x) : x);

route.addRoute('groups')
    .addUrlPath('page')
    .addUrlPath('id')
    .addUrlPath('clientId')
    .addUrlPath('tab');

export default function GroupView() {
    const theme = useTheme();
    const [groupName, setGroupName] = useState<string>();
    const [api, setApi] = useState<ListApiRef<EducationGroupListDto>>();

    return <DataView<EducationGroupListDto>
        title="Группа"
        newTitle=""
        hideAnyFilter={true}
        titleMultiple="Группы"
        typeId="EducationGroup"
        listView={(props, apiRef) => {
            return <>
                <DataListView<EducationGroupListDto> key="groups" {...props}
                    apiRef={x => { setApi(x); apiRef && apiRef(x); }}
                    className="group-table"
                    sx={styles.groupTable(theme)}
                    compact={true} />
                <DataView<ServiceAgreementListDto>
                    key={'ags' + route.get('id')}
                    title="Договор"
                    newTitle="Зачислить"
                    titleMultiple="Договоры"
                    typeId="ServiceAgreement"
                    form={[{
                        title: 'Договор',
                        element: (id, apiRef) => <ServiceAgreementForm id={id} apiRef={apiRef} />
                    }]}
                    listView={(props, apiRef) => <ServiceAgreementList {...props}
                        apiRef={apiRef}
                        groupId={route.get('id')}
                        groupName={groupName}
                        onAdd={ids => api?.update && api?.update(route.get('id'))} />}
                    tableProps={{
                        cmd: 'ServiceAgreementByGroup?id=' + route.get('id'),
                        dataParser: ServiceAgreementListDto.parse,
                        columns: aggrementColumns,
                        onBeforeLoad: t => route.get('id') > 0
                    }}
                    route={{
                        id: 'clientId'
                    }} />
            </>;
        }}
        form={() => (route.get('hideForm') || route.get('clientId')) ? undefined : [{
            title: 'Группа',
            element: (id, apiRef, params, setTabApi) => <GroupForm id={id} apiRef={x=>{
                apiRef(x);
                x?.onLoad(x => x && setGroupName(x.name));
            }} setTabApi={setTabApi} />
        }]}
        tableProps={{
            columns: groupColumns,
            rowSelectorBorder: true,
            routerParams: routeParams => {
                routeParams.push(['hideForm', true]);
                routeParams.push(['clientId']);
                return routeParams;
            }
        }} />;
}

const styles = {
    groupTable: (theme: Theme) => {
        return {
            flex: 'none',
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.palette.grey[300],
            width: 250,
        }
    }
}
import { useState } from 'react';
import { Box, SxProps, Theme } from "@mui/material";
import { isMobile } from '../../utils';
import './dataview.css';
import { IEntity } from '../../models/Types';
import TabPanel, { Disabled, SetTabApi } from '../TabPanel';
import { useRoute, useTenantId } from '../../models/Hooks';
import DataListView, { getId, ListViewProps } from './DataListView';
import route from '../../Router';
import { FormApiRef, ListApiRef } from './ApiRef';
import { ListenerDestructor } from '../../models/Observer';

type FormElement = (id: number, apiRef: (api?: FormApiRef) => void, params?: any, setTabApi?: SetTabApi) => React.ReactNode | IFormInfo[] | undefined;

interface IFormInfo {
    title: string;
    disabled?: Disabled;
    element: FormElement
}

export interface ViewProps<TistDto extends IEntity> extends ListViewProps<TistDto> {
    form: IFormInfo[] | FormElement;
    hideFormAfterSave?: boolean
    hideFormRowSelector?: boolean;
    listView?: (props: ViewProps<TistDto>, apiRef?: (api?: ListApiRef<TistDto>) => void) => React.ReactNode
}

export default function DataView<TistDto extends IEntity>(props: ViewProps<TistDto>) {
    useRoute();

    var [listApi, setListApi] = useState<(ListApiRef<TistDto> | undefined)>();
    var [formApi, setFormApi] = useState<(FormApiRef | undefined)>();
    const [formApiDestructors, setApiDestructors] = useState<ListenerDestructor[]>();

    const tenantId = useTenantId();

    function apiRef(fa?: FormApiRef, la?: ListApiRef<TistDto>) {
        if (!fa) {
            fa = formApi;
        }

        if (fa) {
            if (!la) {
                la = listApi;
            }

            formApiDestructors?.forEach(x => x());
            setApiDestructors([
                fa.onSave(id => {
                    la?.update && la.update(id);
                    const state: [string, any?][] = [[props.route?.id || 'id', id], ['copy']];
                    props.hideFormAfterSave !== false && state.push(['hideForm', true]);
                    route.setState(state);
                }),
                fa.onDelete(id => {
                    la?.delete && la.delete(id);
                    const state: [string, any?][] = [[props.route?.id || 'id'], ['tab']];
                    route.setState(state);
                }),
                fa.onChange((f, n, o, e) => la?.change && la.change(e.id, f, n))
            ]);
        }
    }

    function formApiRef(api?: FormApiRef) {
        formApi = api;
        setFormApi(api);
        apiRef(api);
    }

    function listApiRef(api?: ListApiRef<TistDto>) {
        listApi = api;
        setListApi(api);
        apiRef(undefined, api);
    }

    function buildForm() {
        if (route.get('hideForm')) {
            return null;
        }

        const id = getId(props);
        if (id === undefined) {
            return null;
        }

        let params: any = {};
        var formInfos: IFormInfo[];
        if (Array.isArray(props.form)) {
            formInfos = props.form as IFormInfo[];
        } else {
            let result = (props.form as FormElement)(id, formApiRef, params);
            if (result !== undefined && Array.isArray(result)) {
                formInfos = result as IFormInfo[];
            } else {
                return result;
            }
        }

        return <Box key="form" sx={isMobile() ? styles.formMobile : styles.formDesktop} className="dataview-form-wrapper">
            <TabPanel
                route={{ param: 'tab' }}
                style={{ height: '100%' }}
                isNew={!id}
                tabs={formInfos.map(x => {
                    return {
                        title: x.title,
                        disabled: x.disabled,
                        element: setTabApi => x.element(id, formApiRef, params, setTabApi) as React.ReactNode
                    };
                })} />
        </Box>;
    }

    const form = buildForm();

    return <Box key={tenantId} sx={styles.box} className={'dataview' + (form ? ' dataview-form' : '')}>
        {props.listView ? props.listView(props, listApiRef) : <DataListView<TistDto> {...props} apiRef={listApiRef} />}

        {form}
    </Box>;
}

export function Form({ children }: any) {
    return <Box sx={styles.form}>
        {children}
    </Box>;
}

const styles: { [name: string]: SxProps<Theme> } = {
    box: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        overflow: "hidden"
    },
    form: {
        marginTop: 3,
        padding: 2,
        width: 350,
        alignSelf: 'start',
        borderTopLeftRadius: '.5rem',
        borderBottomLeftRadius: '.5rem',
        backgroundColor: 'secondary.main'
    },
    formMobile: {
        width: '100%',
        height: '100%',
        flex: 1,
        justifyContent: 'space-between'
    },
    formDesktop: {
        maxWidth: '100%',
        width: '36rem',
        paddingTop: '1rem',
        paddingBottom: '1rem'
    }
}
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { OperationType, operationTypes } from "../../models/Enums";
import { useState } from "react";

interface ExchangeOperationTypeProps {
    multiple?: boolean
    name?: string
    value?: (OperationType | OperationType[])
    onChange?: (e: SelectChangeEvent<any>, child: React.ReactNode) => void
}

export default function ExchangeOperationTypeCombo({ multiple, value, onChange, name }: ExchangeOperationTypeProps) {
    const [v, sv] = useState(value);

    const items = [];
    for (var ot in operationTypes) {
        items.push(<MenuItem key={ot} value={ot.toString()}>{(operationTypes as any)[ot]}</MenuItem>);
    }

    function setValue(e: SelectChangeEvent<any>, child: React.ReactNode) {
        var value = Array.isArray(e.target.value) ?
            (e.target.value as []).map(x => parseInt(x)) as OperationType[] :
            (parseInt(e.target.value as string) || 0) as OperationType;

        e.target.value = value;

        onChange && onChange(e, child);

        sv(value);
    }

    return <Select
        name={name}
        SelectDisplayProps={{ style: { display: 'flex' } }}
        value={multiple ? (v as number[]).map(x => x.toString()) : (v?.toString() || '')}
        onChange={setValue}>
        {items}
    </Select>;
}
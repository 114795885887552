import { Position } from "../../models/Types";
import RemoteCombo, { configureSuggestStore, RemoteComboProps } from "../../components/form/RemoteCombo";

const cmd = 'PositionList';
const valueField = 'name';

export const positionStore = configureSuggestStore<Position>(cmd, valueField, 30 * 60);

export default function PositionCombo(props: RemoteComboProps<Position>) {
    return <RemoteCombo<Position>
        typeId="Position"
        valueField={valueField}
        store={positionStore}
        cmd={cmd}
        multiple={true}
        {...props}
        ref={undefined} />;
}
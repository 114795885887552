import { Location } from "../../models/Types";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { LatLngTuple, icon, Map, latLngBounds } from "leaflet";
import markerShadowUrl from "leaflet/dist/images/marker-shadow.png"
import markerIconUrl from "leaflet/dist/images/marker-icon.png"
import Proxy from "../../models/Proxy";
import { useState } from "react";
import route from "../../Router";

const markerIcon = icon({
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    iconUrl: markerIconUrl,
    shadowUrl: markerShadowUrl
});

const defaultCenter: LatLngTuple = [55.794438, 49.111451];

export function LocationMap() {
    const [data, setData] = useState<Location[]>();
    const [map, setMap] = useState<Map>();
    const [loading, setLoading] = useState(false);
    const [center, setCenter] = useState<LatLngTuple | undefined>(defaultCenter);

    if (!data && !loading) {
        setLoading(true);

        Proxy.get('LocationList')
            .then(x => {
                setLoading(false);

                var data = (x.result.data as []).map((d: any) => Object.assign(new Location(), d) as Location);
                setData(data);

                fitMarkers();
            });
    }

    function _setMap(map: Map | null) {
        setMap(map || undefined);
        setCenter(undefined);
        map?.on('click', e => route.setState([['id', 'new'], ['lat', e.latlng.lat], ['lon', e.latlng.lng]]));
        fitMarkers();
    }

    function fitMarkers() {
        if (map && data?.length) {
            let markerBounds = latLngBounds([]);
            data.forEach(x => x.lat && x.lon && markerBounds.extend([x.lat, x.lon]))
            markerBounds.isValid() && map.fitBounds(markerBounds);
        }
    }

    return <MapContainer style={styles.map} ref={_setMap} center={center} zoom={10}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {data?.filter(x => x.lat && x.lon).map(x => <Marker position={[x.lat!, x.lon!]} icon={markerIcon} eventHandlers={{ click: () => route.setState('id', x.id) }} />)}
    </MapContainer>;
}

const styles = {
    map: {
        flex: 1,
        overflow: 'hidden',
        height: '100%'
    }
}
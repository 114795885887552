import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { getUser, isAuthentificated, setTenantId } from '../models/Identity';
import { getTenantId } from '../models/Tenant';
import BaseUserComponent from '../components/base/BaseUserComponent';
import { Divider, IconButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import Logo from '../icons/Logo';
import { isMobile } from '../utils';
import { Settings } from '@mui/icons-material';
import route from '../Router';
import tenantCreate from '../guides/tenantCreate';
import Guide from '../guides/Guide';
import { CallBackProps } from 'react-joyride';

export const CreateNewTenant = () => <>
    <ListItemIcon>
        <Logo height={24} />
    </ListItemIcon>
    <ListItemText primaryTypographyProps={{ color: 'error' }}>Создать новый Клуб</ListItemText>
</>

export default class TenantSelectorComponent extends BaseUserComponent<{}, { guideIndex?: number }> {
    constructor(p: any) {
        super(p);

        this.onOpen = this.onOpen.bind(this);
        this.onGuide = this.onGuide.bind(this);
    }

    onChange(e: any) {
        const id = parseInt(e.target.value);
        if (id) {
            setTenantId(id);
        } else {
            route.setPath('/club/new')
        }
    }

    buildMenu() {
        return getUser().tenants.map(x => <MenuItem key={x.id} value={x.id.toString()}>
            <Typography sx={{ flex: 1 }}>{x.name}</Typography>
            <IconButton onClick={() => route.setPath('/club/' + x.id)}><Settings /></IconButton >
        </MenuItem>);
    }

    buildNewItem(props?: MenuItemProps) {
        return <MenuItem key="new" value="0" onClick={this.onChange} {...props}>
            <CreateNewTenant />
        </MenuItem>;
    }

    renderValue(v: string) {
        if (v) {
            var id = parseInt(v);
            return getUser().tenants
                .filter(x => x.id == id)
                .map(x => x.name)[0];
        }
    }

    onOpen(event: React.SyntheticEvent) {
        this.setState({ guideIndex: 1 });
    }

    onGuide(data: CallBackProps) {
    }

    render() {
        if (!isAuthentificated()) {
            return <></>;
        }

        if (!getUser().tenants?.length) {
            return <>
                {this.buildNewItem({ id: 'tenant-selector-new' })}
                <Guide guide="tenantCreate" />
            </>
        }

        const id = getTenantId()?.toString();
        const sx = isMobile() ? { minWidth: 50, width: 'auto' } : { minWidth: 250, width: 350 };

        return <>
            <Select id="tenant-selector" key={id} value={id} onChange={this.onChange.bind(this)} sx={sx} size="small" renderValue={this.renderValue} onOpen={this.onOpen}>
                {this.buildMenu()}
                <Divider />
                {this.buildNewItem()}
            </Select>
        </>
    }
}
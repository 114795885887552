import { EducationGroup } from "../../models/Types";
import RemoteCombo, { RemoteComboProps } from "../../components/form/RemoteCombo";

export default function GroupCombo({ name, value, onDataLoad, onChange, allowAdd, multiple }: RemoteComboProps<EducationGroup>) {
    return <RemoteCombo<EducationGroup>
        name={name}
        typeId="EducationGroup"
        allowAdd={allowAdd}
        multiple={multiple}
        value={value}
        onDataLoad={onDataLoad}
        onChange={onChange} />;
}
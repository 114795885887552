import DataView from '../../components/data/DataView';
import { ColumnData } from '../../components/data/DataTable';
import { Position } from '../../models/Types';
import { PositionForm } from './PositionForm';
import NavLink from '../../components/NavLink';
import route from '../../Router';

class PositionListDto extends Position {
    employees?: string
}

const columns: ColumnData<PositionListDto>[] = [{
    label: 'Наименование',
    name: 'name',
    filter: true,
    routerLink: true
}, {
    label: 'Сотрудники',
    name: 'employees',
    filter: true,
    renderer: val => (val as [])?.join(', ')
}, {
    name: 'id',
    sx: () => {
        return { fontSize: undefined }
    },
    renderer: (id, obj, theme) => <NavLink route={[['id', id], ['copy', true], ['hideForm']]}>Копировать</NavLink>,
}];

export default () => <DataView<Position>
    title="Должность"
    titleMultiple="Должности"
    typeId="Position"
    form={[{
        title: 'Должность',
        element: (id, apiRef) => <PositionForm id={id} apiRef={apiRef} copy={route.get('copy')} />
    }]}
    tableProps={{
        columns: columns
    }} />
import { Button, ButtonBase, Card, CardActions, CardContent, Checkbox, FormControl, FormLabel, OutlinedInput, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { OperationType, PaymentState } from "../../models/Enums";
import { ExchangeOperation } from "../../models/Types";
import ExchangeOperationTypeCombo from "./ExchangeOperationTypeCombo";
import { PriceField } from "../currency/PriceInput";
import AccountCombo from "../profile/account/AccountCombo";
import PaymentForm from "./PaymentForm";

interface ExchangeOperationState {
    paymentState: PaymentState
}

export class ExchangeOperationForm extends AbstractTypedDataForm<ExchangeOperation, {}, ExchangeOperationState> {
    constructor(p: any) {
        super(p);

        this.pay = this.pay.bind(this);
    }

    protected getTypeId(): string {
        return 'ExchangeOperation';
    }

    protected createEntity(): ExchangeOperation {
        return new ExchangeOperation();
    }

    onChange(e: any) {
        super.onChange(e);
        this.forceUpdate();
    }

    protected buildItems(entity?: ExchangeOperation | undefined): React.ReactNode {
        return <>
            {entity?.ts && <FormLabel>Дата и время: {moment(entity?.ts)?.format('LD LT')}</FormLabel>}

            <FormControl>
                <FormLabel>Тип операции</FormLabel>
                <ExchangeOperationTypeCombo name="type" value={entity?.type} onChange={this.onChange} />
            </FormControl>

            <PriceField
                title="Сумма"
                amount={{ value: entity?.amount ? Math.abs(entity.amount) : entity?.amount, onChange: this.onChange }}
                currency={{ value: entity?.currencyId, onChange: this.onChange }} />

            {entity?.refOperations?.length ? <>
                <Typography variant="h5">Связанные операции:</Typography>
                {entity.refOperations.map(x => this.buildItems(x))}
            </> : undefined}
        </>;
    }

    buildButtons(entity?: ExchangeOperation) {
        if (this.state?.paymentState && entity) {
            return [<PaymentForm amount={entity.amount} currencyId={entity.currencyId} setState={paymentState => this.setState({paymentState})} />];
        }

        const buttons: React.ReactNode[] = [];
        switch (entity?.type) {
            case OperationType.Invoice:
                entity?.refOperations?.find(x => x.type == OperationType.Payment) ||
                 buttons.push(<Button key="pay" variant="contained" onClick={()=>this.setState({paymentState : PaymentState.Open})}>Оплатить</Button>);
                break;
        }

        return buttons;
    }

    pay() {

    }
}
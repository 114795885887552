import Box, { BoxProps } from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';
import { useRoute } from '../models/Hooks';
import './tabpanel.css'
import { isFunc } from '../utils';
import { Close, Edit } from '@mui/icons-material';
import { Button, IconButton, SxProps, Theme, styled } from '@mui/material';

interface BaseTabProps {
    index: number;
    selectedIndex: number;
}

interface TabBodyProps extends BaseTabProps {
    hiddable: boolean
    children?: React.ReactNode;
}

interface TabTabProps extends BaseTabProps {
    title: string,
    isNew: boolean
    disabled?: Disabled,
    setDirtyHandler: (h: OnDirtyHandler) => void
}

function TabBody(props: TabBodyProps) {
    const { children, selectedIndex, index, hiddable, ...other } = props;

    return (
        <div
            role="tabpanel"
            className="tabpanel-body"
            hidden={selectedIndex !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {selectedIndex === index || hiddable ? <Box sx={styles.bodyInner}>{children}</Box> : null}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CloseButton = styled(Button)`
& {
    position: absolute;
    margin-left: -2rem;
    margin-top: 0.5rem;
    padding: 0.25rem;
    min-width: 2rem;
    border-radius: 50% 0 0 50%;
    border-right: none !important;
}`;

export type OnDirtyHandler = (dirty: boolean, desc?: string) => void;
export interface TabApi {
    show: () => void
    hide: () => void
    onDirty: (handler: OnDirtyHandler) => void
}

export type SetTabApi = (tabApi: TabApi) => void;
export type Disabled = true | false | 'new';

type tabElement = (setTabApi: SetTabApi) => React.ReactNode;
interface TabConfig {
    title: string,
    name?: string,
    disabled?: Disabled,
    element: React.ReactNode | tabElement;
}

function TabTab({ index, selectedIndex, isNew, title, setDirtyHandler, disabled, ...props }: TabTabProps) {
    const [dirty, setDirty] = React.useState(false);
    setDirtyHandler((d: boolean, t?: string) => {
        setDirty(d);
    });

    return <Tab
        key={index}
        label={title}
        icon={dirty ? <Edit sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            zoom: .75
        }} /> : undefined}
        iconPosition="end"
        disabled={disabled == 'new' ? isNew : disabled}
        {...a11yProps(index)}
        sx={{
            py: 1,
            px: 3,
            minHeight: 36,
            textTransform: 'none',
            fontSize: '1.2245rem',
            fontWeight: 'normal',
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
            backgroundColor: selectedIndex == index ? 'secondary.main' : 'secondary.dark'
        }}
        {...props} />;
}

export default function TabPanel({ isNew, tabs, ...props }: { tabs: TabConfig[], isNew?: boolean, route?: { param: string, root?: string } } & BoxProps) {
    const [tabApis] = React.useState<TabApi[]>([]);
    const [dirtyHandlers] = React.useState<OnDirtyHandler[]>([]);
    const [dirties] = React.useState<boolean[]>([]);
    const [route] = useRoute();

    const tabParam = route.get('tab');
    var initialTab: number = 0;
    if (tabParam) {
        initialTab = tabs.findIndex(x => x.name == tabParam);
        if (initialTab < 0) {
            initialTab = tabs.findIndex(x => x.title == tabParam);
        }
    }
    /**/
    const [value, setValue] = React.useState(initialTab >= 0 ? initialTab : 0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        route.setState('tab', tabs[newValue].name || tabs[newValue].title);

        setValue(newValue);
    };

    var tabList = tabs.map((x, i) => <TabTab key={i}
        isNew={!!isNew}
        index={i}
        selectedIndex={value}
        setDirtyHandler={dh => dirtyHandlers[i] = dh}
        title={x.title}
        disabled={x.disabled} />);

    var tabPanels = tabs.map((x, i) => <TabBody key={i} selectedIndex={value} index={i} hiddable={isFunc(x.element)}>
        {isFunc(x.element) ?
            (x.element as tabElement)(api => {
                tabApis[i] = api;
                value == i ? api.show() : api.hide();

                api.onDirty((d, t) => {
                    dirties[i] = d;
                    dirtyHandlers[i](d, t);
                });
            }) :
            x.element as React.ReactNode}
    </TabBody>);

    return <>
        <CloseButton key="close-button"
            title="Закрыть форму без сохранения"
            disableElevation
            color="secondary"
            variant="contained"
            className="tabpanel-close"
            onClick={() => route.setState('hideForm', true)}>
            <Close color="primary" />
        </CloseButton>
        <Box className="tabpanel" {...props}>
            <Tabs className="tabpanel-tabs" value={value} onChange={handleChange} sx={{ minHeight: 36 }}>
                {tabList}
            </Tabs>
            {tabPanels}
        </Box>
    </>;
}

const styles: { [name: string]: SxProps<Theme> } = {
    bodyInner: {
        paddingX: 2,
        paddingY: 3,
        flex: 1,
        backgroundColor: 'secondary.main',
        borderBottomLeftRadius: 7,
        overflow: 'hidden auto',
    }
}
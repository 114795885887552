import { Checkbox, FormControl, FormControlLabel, FormLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormTextInput } from "../../components/form/TextInput";
import { Position } from "../../models/Types";
import './positions.css';

const variants: any = {
    0: 'Запрещено',
    1: 'Разрешено',
    2: 'Если ответственный'
}

const colors: any = {
    0: 'error',
    1: 'success.main',
    2: 'primary'
}

const keys: {
    [group: string]: {
        prefix: string,
        variants: number[],
        keys: {
            [key: string]: string
        }
    }
} = {
    'Клиенты': {
        prefix: 'Clients',
        variants: [0, 1, 2],
        keys: {
            'View': 'Просмотр',
            'Create': 'Добавление',
            'ChangeStatus': 'Изменение статуса',
            'ChangeContacts': 'Изменение контактов',
            'CreateUser': 'Приглашение ВКлуб',
            'Delete': 'Удаление'
        }
    },
    'Группы': {
        prefix: 'Groups',
        variants: [0, 1, 2],
        keys: {
            'View': 'Просмотр',
            'Add': 'Создание групп',
            'Update': 'Изменение свойств',
            'AddClients': 'Добавление клиентов в группы',
            'RemoveClients': 'Удаление клиентов из группы',
            'Delete': 'Удаление'
        }
    },
    'Управление Клубом': {
        prefix: 'Manage',
        variants: [0, 1],
        keys: {
            'ClientStatus': 'Справочник статусов',
            'Position': 'Справочник должностей (ролей)',
            'Employee': 'Справочник сотрудников',
            'Program': 'Справочник программ',
            'Location': 'Справочник локаций'
        }
    },
}

export class PositionForm extends AbstractTypedDataForm<Position> {
    constructor(p: any) {
        super(p);

        this.onKeyChange = this.onKeyChange.bind(this);
    }

    protected getTypeId(): string {
        return 'Position';
    }

    protected getTypeName(): React.ReactNode {
        return 'Должность';
    }

    protected createEntity(): Position {
        return new Position();
    }

    onKeyChange(e: SelectChangeEvent) {
        this.state.entity.keys[e.target.name] = parseInt(e.target.value) || 0;
    }

    onChange(e: any) {
        super.onChange(e);

        if (e.target.name == 'superAccess') {
            this.forceUpdate();
        }
    }

    buildGroups() {
        const result = [];
        const disabled = !!this.state?.entity?.superAccess;
        for (var gr in keys) {
            result.push(<Typography key={gr} variant="h5" sx={{ my: 1 }}>{gr}</Typography>);

            var gro = keys[gr];
            for (var key in gro.keys) {
                result.push(<div key={gr + key + disabled} className="key-value">
                    <OutlinedInput key="key" disabled={true} sx={styles.input} inputProps={{ style: styles.inputInner }} value={gro.keys[key]} />
                    <Select key="value"
                        sx={styles.input}
                        name={gro.prefix + key}
                        onChange={this.onKeyChange}
                        defaultValue={this.state?.entity?.keys[gro.prefix + key]?.toString() || ''}
                        disabled={disabled}>
                        {keys[gr].variants.map(i => <MenuItem key={i} value={i}><Typography color={colors[i]}>{variants[i]}</Typography></MenuItem>)}
                    </Select>
                </div>)
            }
        }

        return result;
    }

    protected buildItems(entity?: Position | undefined): React.ReactNode {
        return <>
            <FormTextInput required={true} label="Наименование" name="name" form={this} entity={entity} />
            
            <div key={entity ? (entity.id?.toString() + entity.superAccess?.toString()) : '0'} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <div key="header" style={{ display: 'flex' }}>
                    <Typography variant="h6" sx={{ my: 1, mr: 'auto' }}>Права доступа</Typography>
                    <FormControlLabel control={<Checkbox name="superAccess" checked={!!entity?.superAccess} onChange={this.onChange} />} label="Полный доступ" />
                </div>
                {this.state?.entity?.superAccess ? null : this.buildGroups()}
            </div>
        </>;
    }
}

const styles = {
    input: {
        borderRadius: 0,
        m: '-1px'
    },
    inputInner: {
        fontSize: '.95em',
        color: '#5B5B5B',
        '-webkit-text-fill-color': '#5B5B5B'
    }
}
import Observer, { ListenerDestructor } from '../../models/Observer';
import { resolveHub } from './Model';

const freeice: () => RTCIceServer[] = require('freeice');

export type DeviceType = 'screen' | 'camera';

type RTCClient = {
    id: string
    local: boolean
    stream?: MediaStream
    connection?: RTCPeerConnection
    dataChannel?: RTCDataChannel
    remoteDataChannel?: RTCDataChannel
}

class SDP {
    userId!: string
    sessionDescription?: string
    iceCandidate?: string
}

class JoinStreamInfo {
    userId!: string
    createOffer?: boolean
}

export interface WebRTCApi {
    clients: RTCClient[]
    getChatId(): number
    start(chatId: number, type: DeviceType): Promise<void>
    stop(): Promise<void>
    // join: (chatId: number) => void;
    listen(fn: Function): ListenerDestructor

    sendData(type: string, data: any, client?: RTCClient): void
    onData(fn: (data: any, client: RTCClient, e: MessageEvent, type?: string) => void, type?: string): ListenerDestructor
}

function getLocalStream(type: DeviceType) {
    var res: (x: MediaStream) => void;
    const ret = new Promise<MediaStream>(r => res = r);

    var gum: Promise<MediaStream>;
    switch (type) {
        case 'screen':
            gum = navigator.mediaDevices.getDisplayMedia({
                audio: true,
                video: {
                    width: 1280,
                    height: 720,
                }
            });
            break;

        case 'camera':
            gum = navigator.mediaDevices.getUserMedia({
                audio: true,
                video: {
                    width: 1280,
                    height: 720,
                }
            });
            break;
    }

    function applyRes(x: MediaStream) {
        if (x.active) {
            res(x);
        } else {
            debugger;
            (x as any).onactive = () => res(x);
        }
    }
    if (gum) {
        gum.then(applyRes);

        gum.catch(e => {
            debugger;/**
            var fve = document.getElementById('fakevideo') as any; // HTMLVideoElement
            if (!fve) {
                document.body.insertAdjacentHTML(
                    'beforeend',
                    '<video id="fakevideo" style="width:0;height:0" autoplay controls width="250"><source src="/IMG_2656.MP4" type="video/mp4"></video>');
            }

            fve = document.getElementById('fakevideo') as any;
            return fve.captureStream();
             **/
        });
    }

    return ret;
}

function setStream(stream: MediaStream, client: RTCClient) {
    stream && stream.getTracks()
        .forEach(track => {
            if (client.connection) {
                var sender = client.connection.getSenders().find(x => x.track?.kind == track.kind);
                if (sender) {
                    debugger;
                    sender.replaceTrack(track);
                    sender.setStreams(stream);
                } else {
                    client.connection.addTrack(track, stream);
                }
            } else {
                debugger;
            }
        });
}

var _webRTC: WebRTCApi;
var _chatId: number;

var observer = new Observer();

export default function resolveWebRTC(): WebRTCApi {
    if (_webRTC) {
        return _webRTC;
    }

    const clients: RTCClient[] = [],
        connections: { [id: string]: RTCClient } = {};

    var localStream: MediaStream | undefined,
        localClient: RTCClient | undefined;

    function cb() {
        observer.fire('change');
    }

    function setClient(cfg: RTCClient) {
        var client = clients.find(x => x.id == cfg.id);
        if (client) {
            Object.assign(client, cfg);
        } else {
            client = cfg;
            clients.push(cfg);
        }

        connections[client.id] = client;

        cb();

        return client;
    };

    resolveHub().then(hub => {
        hub.onSessionDescription(({ userId, sessionDescription }: SDP) => {
            const connection = connections[userId!].connection;
            const remoteDescription = JSON.parse(sessionDescription!);

            connection?.setRemoteDescription(new RTCSessionDescription(remoteDescription))
                .then(() => {
                    if (remoteDescription.type == 'offer') {
                        connection.createAnswer()
                            .then(answer => connection.setLocalDescription(answer)
                                .then(() => resolveHub().then(hub => hub.relaySDP(userId, JSON.stringify(connection.localDescription)))
                                    .then(() => cb())));
                    }
                });
        });

        hub.onIceCandidate(({ userId, iceCandidate }: SDP) =>
            connections[userId].connection?.addIceCandidate(new RTCIceCandidate(JSON.parse(iceCandidate!))));

        hub.onLeaveStream(({ userId }: SDP) => close(userId));
    });

    function join(chatId: number) {
        resolveHub().then(hub => hub.joinStream(chatId).then(() => _chatId = chatId));
    }

    function start(chatId: number, type: DeviceType) {
        if (_chatId) {
            return _chatId === chatId ? Promise.resolve() : Promise.reject();
        }

        return getLocalStream(type)
            .then(ls => {
                _chatId = chatId;

                if (localStream) {
                    localStream.getTracks().forEach(track => track.stop());
                    for (var id in connections) {
                        var c = connections[id];
                        if (c.stream !== localStream) {
                            // c.connection.removeStream(localStream);
                        }
                    }

                    localClient && (localClient.stream = ls);
                    cb();
                } else {
                    localClient = setClient({
                        id: type,
                        local: true,
                        stream: ls
                    });
                }

                localStream = ls;

                clients.filter(x => !x.local).forEach(client => {
                    setStream(ls, client);
                });

                join(chatId);

                cb();
            });
    }

    function stop() {
        localStream && localStream.getTracks().forEach(track => track.stop());
        localStream = undefined;

        return resolveHub().then(hub => {
            hub.leaveStream(_chatId);

            for (var id in connections) {
                close(id, true);
            }

            // clients.splice(localClient, 1);

            _chatId = 0;

            cb();
        });
    }

    function close(id: string, silent?: boolean) {
        var c = connections[id];
        if (c) {
            try {
                c.connection && c.connection.close();
            } catch (e) {
                console.warn(e);
            } finally {
                delete connections[id];
            }
        }

        const index = clients.findIndex(x => x.id === id);
        if (index > -1) {
            clients.splice(index, 1);
        }

        silent || cb();
    }

    function sendData(type: string, data: any, client?: RTCClient) {
        data = JSON.stringify({
            type,
            data
        });

        (client ? [client] : clients).forEach(c => c.dataChannel?.send(data));
    }

    resolveHub().then(hub => hub.onJoinStream(({ userId, createOffer }: JoinStreamInfo) => {
        const connection = new RTCPeerConnection({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true,
            iceServers: freeice()
        } as any),
            client = setClient({
                id: userId,
                local: false,
                connection: connection
            });

        connection.onconnectionstatechange = e => {
            switch (connection.connectionState) {
                case 'closed':
                case 'disconnected':
                case 'failed':
                    close(userId);
                    break;
            }
        };

        connection.onsignalingstatechange = e => {
            switch (connection.signalingState) {
                case 'closed':
                    debugger;
                    break;
            }
        };

        /** DataChannel **/
        let dataChannel = connection.createDataChannel('inclub');
        dataChannel.addEventListener('open', e => client.dataChannel = dataChannel);

        connection.addEventListener('datachannel', e => {
            client.remoteDataChannel = e.channel;
            client.remoteDataChannel.addEventListener('message', e => {
                try {
                    var data = JSON.parse(e.data);
                    var type: string | undefined;
                    
                    if (data.hasOwnProperty('type') && data.data) {
                        type = data.type;
                        data = data.data;
                    }

                    observer.fire('data', [data, client, e, type]);
                } catch (e) {
                    debugger;
                }
            });
        });

        setStream(localStream!, client);

        connection.ontrack = e => {
            if (e.streams && e.streams[0]) {
                client.stream = e.streams[0];
                cb();
            }
        }

        (connection as any).onremovestream = () => {
            debugger;
            client.stream = undefined;
            cb();
        };

        connection.onicecandidate = e => {
            if (e.candidate) {
                resolveHub().then(hub => hub.relayICE(userId, JSON.stringify(e.candidate)));
            }
        }

        if (createOffer) {
            connection
                .createOffer()
                .then(offer => connection.setLocalDescription(offer)
                    .then(() => resolveHub().then(hub => hub.relaySDP(userId, JSON.stringify(offer)))
                        .then(cb)));

        }
    }));

    return _webRTC = {
        clients,
        start,
        stop,
        //join
        sendData,
        getChatId: () => (_chatId || 0),
        listen: fn => observer.listen('change', fn),
        onData: (fn, t) => observer.listen('data', t ? (data: any, client: RTCClient, e: MessageEvent, type?: string) => {
            if (type === t) {
                return fn(data, client, e, type);
            }
        } : fn)
    };
}
import { ColumnData } from "../../components/data/DataTable";
import { personListColumns, PersonListDto } from "../../components/person/PersonList";
import { ClientStatus } from "../../models/Types";
import ClientStatusCombo, { personStatusStore } from "../clientStatus/ClientStatusCombo";

export class ClientListDto extends PersonListDto {
    status?: ClientStatus
}

export const clientListColumns: ColumnData<ClientListDto>[] = [...personListColumns];
clientListColumns.splice(2, 0, {
    width: 120,
    label: 'Статус',
    name: 'statusId',
    filter: (onChange) => <ClientStatusCombo onChange={e => onChange(e.target.value)} />,
    sx: val => {
        if (val) {
            var st = personStatusStore.data?.find(x => x.id == val);
            if (st?.obj?.color) {
                return { color: st?.obj?.color };
            }
        }
    },
    renderer: val => personStatusStore.data?.find(x => x.id == val)?.obj?.name
});
export default ({ color }: any) => {
    color || (color = '#DC3522');
    return <svg viewBox="0 0 69.5 9.8">
        <g>
            <path fill={color} d="M9.165 2.59c0,-1.394 -1.196,-2.59 -3.918,-2.59l-4.783 0c-0.265,0 -0.464,0.199 -0.464,0.464l0 8.367c0,0.268 0.199,0.467 0.464,0.467l5.313 0c2.789,0 4.051,-1.262 4.051,-2.723 0,-1.729 -1.991,-2.126 -1.991,-2.126l0 -0.133c0,0 1.328,-0.398 1.328,-1.726zm-3.388 2.988c0.665,0 0.997,0.332 0.997,0.796 0,0.466 -0.332,0.798 -0.997,0.798l-2.855 0 0 -1.594 2.855 0zm-0.53 -3.454c0.609,0 0.861,0.265 0.861,0.665 0,0.398 -0.265,0.663 -0.861,0.663l-2.325 0 0 -1.328 2.325 0z" />
            <path fill={color} d="M22.18 4.383c0,0 0.877,-0.266 1.596,-1.594l1.262 -2.325c0.104,-0.199 0,-0.464 -0.334,-0.464l-2.057 0c-0.533,0 -0.719,0.199 -0.865,0.464l-1.262 2.325c-0.211,0.398 -0.464,0.663 -1.195,0.663l-0.995 0 0 -2.988c0,-0.265 -0.201,-0.464 -0.466,-0.464l-1.991 0c-0.265,0 -0.467,0.199 -0.467,0.464l0 8.367c0,0.268 0.202,0.467 0.467,0.467l1.991 0c0.265,0 0.466,-0.199 0.466,-0.467l0 -3.12 1.193 0c0.732,0 0.985,0.265 1.196,0.663l1.328 2.457c0.148,0.268 0.334,0.467 0.865,0.467l2.06 0c0.331,0 0.438,-0.268 0.331,-0.467l-1.328 -2.457c-0.864,-1.594 -1.795,-1.859 -1.795,-1.859l0 -0.132z" />
            <path fill={color} d="M36.181 0.464c0,-0.265 -0.198,-0.464 -0.466,-0.464l-4.051 0c-2.657,0 -3.985,1.328 -4.515,3.452l-1.328 5.379c-0.067,0.253 0.132,0.467 0.464,0.467l2.006 0c0.252,0 0.451,-0.199 0.518,-0.467l1.328 -5.379c0.186,-0.729 0.665,-1.196 1.527,-1.196l1.596 0 0 6.575c0,0.268 0.199,0.467 0.464,0.467l1.991 0c0.268,0 0.466,-0.199 0.466,-0.467l0 -8.367z" />
            <path fill={color} d="M43.526 3.985l-1.063 0c-0.53,0 -0.862,-0.334 -1.063,-0.798l-1.127 -2.723c-0.107,-0.265 -0.334,-0.464 -0.665,-0.464l-1.991 0c-0.334,0 -0.505,0.199 -0.4,0.464l1.129 2.723c0.798,1.927 1.858,2.988 4.051,2.988l0.331 0 -0.066 0.199c-0.104,0.334 -0.464,0.665 -0.994,0.665l-0.135 0c-0.265,0 -0.663,-0.133 -0.928,-0.133l-0.599 0c-0.266,0 -0.464,0.199 -0.464,0.464l0 1.461c0,0.268 0.198,0.467 0.464,0.467l1.662 0c2.19,0 3.319,-1.13 3.985,-2.924l2.189 -5.91c0.095,-0.265 -0.066,-0.464 -0.397,-0.464l-1.928 0c-0.331,0 -0.571,0.199 -0.662,0.464l-1.329 3.521z" />
            <path fill={color} d="M58.696 6.307c0,-1.659 -1.329,-2.988 -4.118,-2.988l-2.656 0 0 -1.063 5.512 0c0.265,0 0.464,-0.199 0.464,-0.464l0 -1.328c0,-0.265 -0.199,-0.464 -0.464,-0.464l-7.97 0c-0.265,0 -0.467,0.199 -0.467,0.464l0 8.367c0,0.268 0.202,0.467 0.467,0.467l5.114 0c2.789,0 4.118,-1.328 4.118,-2.991zm-4.118 -0.861c0.663,0 1.061,0.397 1.061,0.861 0,0.467 -0.398,0.865 -1.061,0.865l-2.656 0 0 -1.726 2.656 0z" />
            <path fill={color} d="M69.123 0.464c0,-0.265 -0.202,-0.464 -0.467,-0.464l-8.102 0c-0.265,0 -0.464,0.199 -0.464,0.464l0 8.367c0,0.268 0.199,0.467 0.464,0.467l8.102 0c0.265,0 0.467,-0.199 0.467,-0.467l0 -1.328c0,-0.265 -0.202,-0.464 -0.467,-0.464l-5.644 0 0 -1.328 4.183 0c0.265,0 0.467,-0.199 0.467,-0.464l0 -1.329c0,-0.267 -0.202,-0.466 -0.467,-0.466l-4.183 0 0 -1.196 5.644 0c0.265,0 0.467,-0.199 0.467,-0.464l0 -1.328z" />
        </g>
    </svg>;
}
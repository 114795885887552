import { OperationType, ResponseStatus } from "./Enums";

export interface IEntity {
    id: number
}

export interface IHierarchicalEntity extends IEntity {
    parentId?: number

    // dto
    children: IHierarchicalEntity[]
}

export interface ISuggest<TValue = string, TId = number, TObj = any> {
    id: TId,
    value: TValue,
    obj?: TObj
}

export interface Exception {
    message?: string
    stackTrace?: string
    success?: boolean
    type?: string
}

export class BaseEntity implements IEntity {
    id!: number
}

export class BaseHierarchicalEntity extends BaseEntity implements IHierarchicalEntity {
    parentId?: number

    // dto
    children!: IHierarchicalEntity[]
}

export type TenantEntity = {
    tenantId: number
} & BaseEntity;

export class Tenant extends BaseEntity {
    name!: string
    color?: string
    ownerId?: number
};

export type User = {
    name: string
    isAuthenticated: boolean
    tenants: Tenant[]
} & BaseEntity;

export type Contact = {
    type?: string,
    value?: string,
    description?: string
}

export class Person extends BaseEntity {
    name?: string
    surname?: string
    patronomic?: string
    description?: string
    createDate?: Date
    userId?: number
    gender?: boolean

    emailNames: string[] = []
    emails: string[] = []
    linkNames: string[] = []
    links: string[] = []
    phoneNames: string[] = []
    phones: string[] = []

    // Client
    statusId?: number

    // Employee
    positionIds?: number[]

    get fio() {
        return [this.surname, this.name, this.patronomic].filter(x => x).join(' ');
    }

    setContacts(contacts: Contact[]) {
        this.emailNames = []
        this.emails = []
        this.linkNames = []
        this.links = []
        this.phoneNames = []
        this.phones = []

        contacts?.forEach(x => {
            switch (x.type) {
                case 'email':
                case 'phone':
                case 'link':
                    this.AddContact(x.type, x);
                    break;

                default:
                    debugger;
                    break;
            }
        });
    }

    getContacts() {
        const contacts: Contact[] = [];
        this.phones?.forEach((p, i) => contacts.push({
            type: 'phone',
            value: p,
            description: this.phoneNames && this.phoneNames[i]
        }));

        this.emails?.forEach((p, i) => contacts.push({
            type: 'email',
            value: p,
            description: this.emailNames && this.emailNames[i]
        }));

        this.links?.forEach((p, i) => contacts.push({
            type: 'link',
            value: p,
            description: this.linkNames && this.linkNames[i]
        }));

        return contacts;
    }

    private AddContact(type: string, contact: Contact) {
        (this as any)[type + 's'].push(contact.value);
        (this as any)[type + 'Names'].push(contact.description);
    }
}

export class ClientStatus extends BaseEntity {
    name!: string
    index?: number
    color?: string
    isStarted?: boolean
    isFinished?: boolean
}

export class EducationGroup extends BaseEntity {
    name!: string
    start?: Date
    end?: Date
    scheduleText?: string
    description?: string
    programId?: number
    mainTeacherId?: number
    locationId?: number
    responsibleEmployeeId?: number
}

export class EducationGroupListDto extends EducationGroup {
    program?: string
    teacher?: string
    location?: string
    count?: number

    persons?: Person[]
}

class BaseParticipant {
    participantId!: number
    eventId!: number
}

export class EventParticipant extends BaseParticipant {
    type?: string
    responseStatus?: ResponseStatus
    serviceAgreementId?: number
    attended?: boolean

    name?: string
    groups?: string[]

    static Create(type: string, participantId: number) {
        const ep = new EventParticipant();
        ep.type = type;
        ep.participantId = participantId;
        return ep;
    }
}

export class Event extends BaseEntity {
    name!: string
    start!: Date
    end!: Date
    allDay!: boolean
    success?: boolean
    locationId?: number
    currencyId?: number
    amount?: number
}

export class EventDto extends Event {
    participants!: EventParticipant[]
    groupParticipants!: number[]

    constructor() {
        super();

        this.participants = [];
        this.groupParticipants = [];
    }
}

export class Position extends BaseEntity {
    name!: string
    superAccess!: boolean

    constructor() {
        super();

        this.keys = {};
    }

    keys: {
        [key: string]: number
    }
}

export class Location extends BaseEntity {
    name!: string
    description?: string
    address?: string
    lat?: number
    lon?: number
    suggestAddress?: string
}

export class EducationProgram extends BaseHierarchicalEntity {
    name!: string

    // dto
    children!: EducationProgram[]
}

export class ServiceAgreement extends BaseEntity {
    personId!: string
    groupId?: string
    number?: string
    start?: Date
    end?: Date
}

export class Currency extends BaseEntity {
    name!: string
    mainCurrencyRate?: number
}

export class ExchangeOperation extends BaseEntity {
    personId!: number
    currencyId!: number
    /// <summary>
    /// - и <see cref="RefOperationId"/> == null Начисление Invoice
    /// - и <see cref="RefOperationId"/> != null Возврат Refund
    /// + и <see cref="RefOperationId"/> == null Зачисление Enrollment
    /// + и <see cref="RefOperationId"/> != null Платеж по начислению Payment
    /// </summary>
    amount!: number
    ts!: Date
    expDate?: Date
    refOperationId?: number
    description?: string

    refOperations?: ExchangeOperation[]

    static parse(obj: any) {
        var result = Object.assign(new ExchangeOperation(), obj);
        if (obj.ts && !(obj.ts instanceof Date)) {
            result.ts = new Date(obj.ts);
        }

        if (obj.refOperations) {
            result.refOperations = obj.refOperations.map(ExchangeOperation.parse);
        }

        return result;
    }

    get type() {
        if (this.amount > 0) {
            return this.refOperationId ? OperationType.Payment : OperationType.Enrollment;
        } else {
            return this.refOperationId ? OperationType.Refund : OperationType.Invoice;
        }
    }

    set type(type: OperationType) {
        this.amount = type == OperationType.Refund || type == OperationType.Invoice ? -Math.abs(this.amount) : Math.abs(this.amount);
    }
}

export class Account extends BaseEntity {
    currencyId?: number
    personId?: number
    amount?: number

    mainCurrencyRate?: number
    currency!: string
}

export class Tariff extends BaseEntity {
    name!: string
    amount!: number
    currenciesId?: number[]
    amounts?: number[]
    productId?: number
    actualVersionId?: number
    createDate!: Date
    endDate?: Date

    static parse(obj: any) {
        var result = Object.assign(new Tariff(), obj) as Tariff;

        // result.amount && (result.amount /= 100.0);

        result.createDate = new Date(obj.createDate);
        if (obj.endDate && !(obj.endDate instanceof Date)) {
            result.endDate = new Date(obj.endDate);
        }

        return result;
    }
}
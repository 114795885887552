import DataView from '../../components/data/DataView';
import { EmployeeForm } from './EmployeeForm';
import { PersonListDto } from "../../components/person/PersonList";
import { employeeListColumns } from './EmployeeList';

export default () => <DataView<PersonListDto>
    title="Сотрудник"
    titleMultiple="Сотрудники"
    typeId="Employee"
    form={[{
        title: 'Сотрудник',
        element: (id, apiRef) => <EmployeeForm key="main" id={id} apiRef={apiRef} />
    }/**, {
        title: 'Чат',
        element: <Chat key="Chat" chatId={id} />
    }/**/]}
    tableProps={{
        columns: employeeListColumns,
        dataParser: PersonListDto.parse
    }} />
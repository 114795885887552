import { Box } from "@mui/system";
import { useAppRoute } from "../../nav/AppRoute";
import Drawer from "../../nav/Drawer";
import route from "../../Router";
import profileMenu from "./ProfileMenu";

route.addRoute('profile')
    .addUrlPath('page')
    .addUrlPath('subpage')
    .addUrlPath('id')
    .addUrlPath('tab');

function Body() {
    const [route, path, current, menuItem] = useAppRoute(profileMenu);
    return <>{menuItem?.element && menuItem.element()}</>;
}

export default function ProfileView() {
    return <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
        <Drawer open={true} menu={profileMenu} title="Профиль" />
        <Body />
    </Box>;
}
import ReactJoyride, { CallBackProps, Locale, Step, StoreHelpers, BaseProps as JoyrideProps, STATUS } from "react-joyride";
import tenantCreate from "./tenantCreate";
import welcome from "./welcome";
import { getUser } from "../models/Identity";
import { useObserver } from "../models/Hooks";
import { useState } from "react";
import { userMenuGuide } from "../nav/UserMenu";
import { chatStatusGuide } from "../nav/ChatStatus";

function getHiddenKey() {
    return 'hiddenGuides_' + getUser()?.id;
}

function isHidden(name: string) {
    var hidden = JSON.parse(localStorage.getItem(getHiddenKey()) || '[]') as string[];
    return hidden && hidden.find(x => x == name);
}

const locale: Locale = {
    back: 'Назад',
    close: 'Закрыть',
    last: 'Завершить',
    next: 'Далее',
    open: 'Ознакомительный Тур',
    skip: 'Пропустить'
}

export const guides: { [name: string]: Step[] } = {
    tenantCreate,
    welcome,
    userMenu: userMenuGuide,
    chatStatus: chatStatusGuide
}

export type GuideProps = JoyrideProps & {
    guide?: string
    callback?: (data: CallBackProps) => void;
    continuous?: boolean;
    debug?: boolean;
    getHelpers?: (helpers: StoreHelpers) => any;
    run?: boolean;
    scrollDuration?: number;
    scrollOffset?: number;
    scrollToFirstStep?: boolean;
    stepIndex?: number;
    steps?: Step[];
    onClose?: (e: CallBackProps) => void
}

const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

export default function Guide({ steps, styles, guide, callback, onClose, ...props }: GuideProps) {
    const [st] = useState({});
    const [g, sg] = useObserver('guides', st);
    if (g && g !== st) {
        return <></>;
    }

    const named = !steps && guide;
    if (named) {
        steps = guides[guide]

        if (isHidden(guide)) {
            return <></>;
        }
    };

    function _callback(data: CallBackProps) {
        callback && callback(data);
        if (data.status === "finished") {
            if (named && !isHidden(guide)) {
                const hidden = JSON.parse(localStorage.getItem(getHiddenKey()) || '[]') as string[] || [];
                hidden.push(guide);
                localStorage.setItem(getHiddenKey(), JSON.stringify(hidden));
            }

            sg();
        } else if (data.lifecycle == 'tooltip') {
            sg(st);
        }

        if (onClose && finishedStatuses.includes(data.status)) {
            onClose(data);
        }
    }

    return <ReactJoyride
        locale={locale}
        steps={steps!}
        continuous={true}
        scrollToFirstStep={true}
        showSkipButton={true}
        styles={{
            ...styles,
            options: {
                zIndex: 1200,
                ...styles?.options
            },
            tooltipContent: {
                alignContent: 'start',
                textAlign: 'left',
                ...styles?.tooltipContent
            }
        }}
        callback={_callback}
        {...props} />;
}
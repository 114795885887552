import React from "react";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { Tariff } from "../../models/Types";
import { FormNumberInput, FormTextInput } from "../../components/form/TextInput";
import { DataGrid } from "../../components/form/DataGrid";
import { PriceField } from "../currency/PriceInput";
import { currencyStore } from '../currency/CurrencyCombo'
import { Typography, TypographyProps } from "@mui/material";
import NavLink from "../../components/NavLink";

type Price = {
    currencyId?: number
    amount: number
}

export function TariffAbout(props: TypographyProps) {
    return <>
        <Typography {...props} sx={{ mt: 1 }}>
            Покупка/Продажа <b>Абонемента</b> осуществляется:
            <ul>
                <li><b>Клиентом</b> самостоятельно в разделе <b><NavLink href="/profile/account">Баланс</NavLink></b> <b>Профиля</b></li>
                <li><b>Администратором</b> Клуба во вкладке <NavLink route={[['page', 'clients'], ['id'], ['tab', 'Баланс']]}>Баланс</NavLink> раздела <b><NavLink route={[['page', 'clients'], ['id'], ['tab']]}>Клиенты</NavLink></b></li>
            </ul>
        </Typography>
        <Typography {...props} sx={{ mt: 1 }}>
            При покупке <b>Абонемента</b> происходит:
            <ul>
                <li>Списание с рублевого баланса <b>Клиента</b> <u>Стоимости</u> <b>Абонемента</b></li>
                <li>Зачисление на баланс <b>Клиента</b> <b><NavLink route={[['page', 'currency'], ['id'], ['tab']]}>Услуг</NavLink></b>, определенных в перечне <u>Начисление услуг</u> <b>Абонемента</b></li>
            </ul>
        </Typography>
    </>;
}

export class TariffForm extends AbstractTypedDataForm<Tariff, {}, { details: Price[] }> {
    protected getTypeId(): string {
        return 'Tariff';
    }

    protected getTypeName(): React.ReactNode {
        return 'Абонемент';
    }

    protected createEntity(): Tariff {
        return new Tariff();
    }

    clear(): void {
        super.clear();
        this.setState({
            details: [{
                currencyId: currencyStore.data?.find(x => x)?.id
            }]
        });
    }

    protected responseToState(response: any) {
        const entity = Tariff.parse(super.responseToState(response).entity);
        return {
            entity,
            details: entity.currenciesId?.map((c, i) => {
                return {
                    currencyId: c,
                    amount: (entity.amounts && entity.amounts[i]) || 0
                }
            })
        };
    }

    protected stateToRequest() {
        this.state.entity.currenciesId = this.state.details.map(x => x.currencyId!);
        this.state.entity.amounts = this.state.details.map(x => x.amount);

        return super.stateToRequest();
    }

    onChange(e: any) {
        super.onChange(e);
        this.forceUpdate();
    }

    protected buildItems(entity?: Tariff | undefined): React.ReactNode {
        return <>
            <FormTextInput form={this} name="name" label="Наименование" required={true} />

            <FormNumberInput form={this} name="amount" label="Стоимость" inputProps={{ endAdornment: '₽' }} required={true} />

            {this.state?.details && <DataGrid<Price> label="Начисление услуг *"
                create={() => { return { amount: 0 }; }}
                entities={this.state.details}
                row={(c, i) => <PriceField
                    required={true}
                    excludeСurrency={true}
                    amount={{
                        defaultValue: c.amount,
                        onChange: e => (parseInt(e.target.value) || 0) < 0 ?
                            e.target.value = c.amount?.toString() :
                            (c.amount = parseInt(e.target.value) || 0)
                    }}
                    currency={{
                        value: c.currencyId,
                        onChange: e => c.currencyId = e.target.value as number
                    }} />}
            />}

            <TariffAbout color="grey" />
        </>;
    }
}
import { Badge, CalendarMonth, CardMembership, CreditCard, Home, LeakAdd, LocationOn, Mood, School, SportsSoccer } from "@mui/icons-material";
import Logo from "../icons/Logo";
import Clients from "../screens/clients";
import Schedule from "../screens/scheduler";
import Groups from "../screens/groups";
import Messenger from "../screens/messenger";
import Employees from "../screens/employees";
import Locations from "../screens/locations";
import Programs from "../screens/programs";
import { useRoute } from "../models/Hooks";
import { UrlRoute } from "../Router";
import ClubView from "../screens/club";
import Finance from "../screens/finance";
import ProfileView from "../screens/profile";
import Tariffs from "../screens/tariffs";
import Guide, { guides } from "../guides/Guide";
import CurrencyView from "../screens/currency";
import { Step } from "react-joyride";
import Dashboard from "../screens/dashboard";

const clubManageMenu: Step[] = [
  {
    target: '#club-manage-menu',
    title: 'Управление Клубом',
    content: <>В меню <b>Управление Клубом</b> собраны основные разделы системы, необходимые для повседневное работы</>,
  },
  {
    target: '#program-menu',
    title: 'Программы',
    content: <>В разделе <b>Программы</b> можно вести учебные программы...
    </>,
  },
  {
    target: '#tariffs-menu',
    title: 'Абонементы',
    content: <><b>Абонементы</b> - продукты Клуба, которые используются для продаж.
      <p>При покупке <b>Абонемента</b> покупателю (<b>Клиенту</b>) начисляются соотвествующие количество единиц <b>Услуг</b></p>
    </>,
  },
  {
    target: '#emploees-menu',
    title: 'Сотрудники',
    content: <><b>Сотрудники</b> Клуба могут
      <ul>
        <li>Вести занятия с помощью закрепления за ним конкретного события в <b>Расписании</b></li>
        <li>Курировать <b>Группы</b></li>
        <li>Администрировать Клуб</li>
        <li>Для каждого <b>Сотрудника</b> может быть создан отдельный пользовательский аккаунт</li>
      </ul>
    </>,
  },
  {
    target: '#club-menu-currency',
    title: 'Услуги',
    content: <>В разделе <b>Услуги</b> можно настроить стоимость предоставляемых услуг.
      <p>При создании нового Клуба в нем по умолчанию уже есть услуга <b>Занятие</b> стоимостью <b>1000 рублей</b></p>
    </>,
  },
  {
    target: '#locations-menu',
    title: 'Локации',
    content: <>
      В разделе <b>Локации</b> можно настраивать учебные классы, тренировочные площадки, места сбора или любые геолокационные точки.
      <p>В <b>Расписании</b> можно указывать <b>Локации</b> для любого занятия</p>
    </>,
  }
].map(x => {
  return {
    ...x,
    placementBeacon: 'right',
    placement: 'right'
  }
});
guides.clubManageMenu = clubManageMenu;

interface IAppMenuItem {
  id?: string
  path?: string,
  title?: React.ReactNode,
  tenanted?: boolean,
  icon?: React.ReactNode,
  element?: () => React.ReactNode,
  color?: string
}

export type IAppMenu = (IAppMenuItem | null)[];

export const menu: IAppMenu = [
  {
    id: 'desktop-menu',
    path: "",
    title: 'Рабочий стол',
    icon: <Home />,
    element: () => <Dashboard />,
  },
  {
    id: 'clients-menu',
    path: "clients/*",
    title: 'Клиенты',
    tenanted: true,
    icon: <Mood />,
    element: () => <Clients />,
  },
  {
    id: 'groups-menu',
    path: "groups/*",
    tenanted: true,
    title: 'Группы',
    icon: <SportsSoccer />,
    element: () => <Groups />,
  },
  {
    id: 'schedule-menu',
    path: "schedule/*",
    title: 'Расписание',
    icon: <CalendarMonth />,
    element: () => <Schedule />,
  },
  {
    id: 'finance-menu',
    path: "finance",
    title: 'Расчеты',
    tenanted: true,
    icon: <CreditCard />,
    element: () => <Finance />,
  },
  {
    tenanted: true,
    id: 'club-manage-menu',
    title: <>Управление Клубом<Guide guide="clubManageMenu" /></>
  },
  {
    id: 'programs-menu',
    path: "programs/*",
    icon: <School />,
    title: 'Программы',
    element: () => <Programs />,
  },
  {
    id: 'tariffs-menu',
    path: 'tariffs',
    icon: <CardMembership />,
    tenanted: true,
    title: 'Абонементы',
    element: () => <Tariffs />,
  },
  {
    id: 'emploees-menu',
    path: "emploees/*",
    icon: <Badge />,
    tenanted: true,
    title: 'Сотрудники',
    element: () => <Employees />,
  },
  {
    id: 'club-menu-currency',
    path: "currency",
    title: 'Услуги',
    tenanted: true,
    icon: <LeakAdd />,
    element: () => <CurrencyView />,
  },
  {
    id: 'locations-menu',
    path: "locations/*",
    icon: <LocationOn />,
    title: 'Локации',
    element: () => <Locations />,
  },
  null,
  {
    path: "club",
    icon: <Logo width={24} />,
    title: 'О клубе',
    color: 'error.main',
    element: () => <div>О клубе</div>,
  },
  {
    path: "chat",
    element: () => <Messenger />,
  },
  {
    path: "club/:id",
    element: () => <ClubView />,
  },
  {
    path: "profile/:id",
    element: () => <ProfileView />,
  }
];

export const route = menu.filter(x => x);

export function useAppRoute(menu: IAppMenu): [UrlRoute, string, number, IAppMenuItem] {
  const [route, path] = useRoute();
  var current = 0;
  menu.reduce((y, x, i) => (x?.path && !path.indexOf(x.path.replace(/\/\*$/, '').replace(/\/:.*$/, ''))) ? current = i : i, 0);
  return [route, path, current, menu[current]!];
}
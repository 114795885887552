import { ColumnData } from '../../components/data/DataTable';
import { ServiceAgreement } from '../../models/Types';
import DataListView, { DataListViewProps, DataListViewState } from '../../components/data/DataListView';
import { Link, Typography } from '@mui/material';
import { cellSx } from './GroupList';
import moment from 'moment';
import { clientListColumns, ClientListDto } from '../clients/ClientList';
import DeleteDialog from '../../components/data/DeleteDialog';
import { SelectDialog } from '../../components/ModalDialog';
import NavLink from '../../components/NavLink';

export class ServiceAgreementListDto extends ServiceAgreement {
    person!: string
    lastEvent?: Date

    get info() {
        if (!this.number && !this.start) {
            return this.person;
        }

        return <>
            {this.person}
            <br />
            <Typography fontSize=".75em">Договор{this.number ? ' №' + this.number : undefined}{this.start ? ' от ' + moment(this.start).format('L') : undefined}</Typography>
        </>;
    }

    static parse(d: any) {
        const dto = new ServiceAgreementListDto();
        Object.assign(dto, d);
        d.start && (dto.start = new Date(d.start));
        d.end && (dto.end = new Date(d.start));
        d.lastEvent && (dto.lastEvent = new Date(d.lastEvent));
        return dto;
    }
}

export const aggrementColumns: ColumnData<ServiceAgreementListDto>[] = [{
    label: 'Клиент',
    name: 'info',
    filter: true,
    routerLink: true
}, {
    label: 'Последнее занятие',
    name: 'lastEvent',
    type: 'date',
    filter: true
}, {
    label: 'Абонемент',
    name: 'end',
    type: 'date',
    filter: true
}, {
    label: 'Действия',
    name: 'id',
    filter: false,
    sort: false,
    width: 100,
    sx: cellSx,
    renderer: (id, obj, theme, table) => <>
        <NavLink>Расчеты</NavLink>
        <br />
        <Link color="error"
            sx={{ cursor: 'pointer' }}
            onClick={() => table.renderCallback(c => <DeleteDialog
                text="При исключении из группы договор на оказание услуг не удаляется, но у него проставляется дата окончания текущим числом. Исключить из группы?"
                typeId="ServiceAgreement"
                deleteText="Исключить"
                entityId={id}
                onSuccess={() => c(id)}
                onCancel={() => c()}
                open={true} />)}>
            Исключить
        </Link>
    </>,
}];

const clientColumns = [...clientListColumns];
clientColumns[0] = {
    ...clientColumns[0],
    routerLink: false
}

interface State extends DataListViewState {
    addDialogOpen?: boolean
}

interface Props extends DataListViewProps<ServiceAgreementListDto> {
    groupId: number
    groupName?: string
    onAdd?: (ids: number[]) => void
}

export class ServiceAgreementList extends DataListView<ServiceAgreementListDto, Props, State> {
    selectedIds?: number[]

    constructor(p: Props) {
        super(p);
    }

    buildAddButton(newTitleText: string, props?: any) {
        if (this.props.groupId > 0) {
            return super.buildAddButton(newTitleText, Object.assign({
                onClick: () => this.state.addDialogOpen === undefined && this.setState({ addDialogOpen: true })
            }, props));
        }
    }

    buildDialog() {
        return <SelectDialog<ClientListDto>
            header={<>Выберите клиентов<Typography variant="subtitle1">для зачисления в группу «{this.props.groupName}»</Typography></>}
            addText="Зачислить"
            addCmd="ServiceAgreementAddPersons"
            open={!!this.state.addDialogOpen}
            urlParams={{ groupId: this.props.groupId }}
            tableProps={{
                columns: clientColumns,
                typeId: 'Person',
                cmd: 'ServiceAgreementNewPersonList?groupId=' + this.props.groupId,
                dataParser: ClientListDto.parse
            }}
            onAdd={added => {
                this.api?.update && this.api?.update(added);
                this.props.onAdd && this.props.onAdd(added);
            }}
            onClose={() => this.setState({ addDialogOpen: undefined })}
        />;
    }

    render() {
        const result = super.render();
        if (this.state.addDialogOpen !== undefined) {
            return <>
                {result}
                {this.buildDialog()}
            </>;
        }

        return result;
    }
}
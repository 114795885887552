import React from "react";
import { AbstractTypedDataForm } from "../../components/data/DataForm";
import { FormNumberInput, FormTextInput } from "../../components/form/TextInput";
import { Currency } from "../../models/Types";
import { Box, Typography, TypographyProps } from "@mui/material";
import NavLink from "../../components/NavLink";

export function CurrencyAbout(props: TypographyProps) {
    return <>
        <Typography sx={{ mt: 1.5 }} {...props}>
            <b>Услуги</b> прикрепляются в <b>Занятия</b> <NavLink route={[['page', 'schedule'], ['id'], ['tab']]}>Расписания</NavLink>.
            После проведения <b>Занятия</b> с баланса участников занятия списываются <b>Услуги</b> в размере, указанных в <b>Занятии</b>
        </Typography>
        <Typography sx={{ mt: 1.5 }} {...props}>
            Начисление <b>Услуг</b> осуществляется через продажу <b>Клиентам</b> <NavLink route={[['page', 'tariffs'], ['id'], ['tab']]}>Абонемента</NavLink>,
            либо напрямую на вкладке <NavLink route={[['page', 'clients'], ['id'], ['tab', 'Баланс']]}>Баланс</NavLink> раздела <b><NavLink route={[['page', 'clients'], ['id'], ['tab']]}>Клиенты</NavLink></b>
        </Typography>
    </>;
}

export class CurrencyForm extends AbstractTypedDataForm<Currency> {
    protected getTypeId(): string {
        return 'Currency';
    }

    protected createEntity(): Currency {
        return new Currency();
    }

    protected getTypeName(): React.ReactNode {
        return 'Услуга';
    }

    protected buildItems(entity?: Currency | undefined): React.ReactNode {
        return <>
            <FormTextInput required={true} label="Наименование" name="name" form={this} entity={entity} />
            <FormNumberInput form={this} name="mainCurrencyRate" label="Стоимость" inputProps={{ endAdornment: '₽' }} />
            <CurrencyAbout color="grey" />
        </>;
    }
}
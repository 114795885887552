import { BaseEntity } from "../../models/Types"
import { ChatType, MemberType, MessageType } from "./enums"

export class User extends BaseEntity {
    name!: string
    clientId?: string
    avatarUrl?: string
}
export class Chat extends BaseEntity {
    private _lastTs?: Date;

    name!: string
    type!: ChatType
    clientId?: string
    avatarUrl?: string

    activeStreamId?: number
    lastId?: number
    lastText?: string
    lastAttachment?: string
    get lastTs() { return this._lastTs; }
    set lastTs(v: any) {
        this._lastTs = v ? v instanceof Date ? v : new Date(v) : undefined;
    }

    constructor(name?: string, type?: ChatType) {
        super();

        name && (this.name = name);
        type && (this.type = type);
    }
}

export class Member extends BaseEntity {
    name!: string
    type!: MemberType
    userId!: number
    chatId!: number
    lastReaded!: number
    lastDelivered!: number
    muted!: boolean
    pined!: boolean
    unreaded!: number

    constructor(name?: string, type?: MemberType, userId?: number, chatId?: number) {
        super();

        name && (this.name = name);
        type && (this.type = type);
        userId && (this.userId = userId);
        chatId && (this.chatId = chatId);
    }
}

class LastMessageDto {

}

class BaseChatDto {
    chat!: Chat
    last?: LastMessageDto

    constructor(chat?: Chat) {
        chat && (this.chat = chat);
    }
}

export class ChatDto extends BaseChatDto {
    members!: Member[]

    constructor(chat?: Chat, members?: Member[]) {
        super(chat);
        members && (this.members = members);
    }
}

export class SaveChatMemberDto {
    type!: MemberType
    userId?: number
    clientId?: string

    constructor(type?: MemberType, userId?: number, clientId?: string) {
        type && (this.type = type);
        userId && (this.userId = userId);
        clientId && (this.clientId = clientId);
    }
}

export class SaveChatDto extends BaseChatDto {
    members!: SaveChatMemberDto[]

    constructor(chat?: Chat, members?: SaveChatMemberDto[]) {
        super(chat);
        members && (this.members = members);
    }
}

export class MemberDto extends ChatDto {
    displayName!: string
    avatarUrl?: string
    member?: Member
    unreaded?: number
}

export class Message extends BaseEntity {
    text?: string
    ts!: string
    chatId!: number
    senderId!: number
    isDeleted?: number
    type: MessageType = MessageType.Default

    readDate?: string
    readTs?: string
    deliveredTs?: string
    dt?: string
    attachment?: Attachment
};

export class Attachment extends BaseEntity {
    title!: string
    type?: number
    contentType!: string
    thumbnailUrl?: string
    provider?: string
    fileKey?: string
    size?: number
};

export class MessageDto {
    message: Message
    attachment?: Attachment

    constructor(message: Message, attachment?: Attachment) {
        this.message = message;
        this.attachment = attachment;
    }
}

export class MessageSenderDto extends MessageDto {
    sender: User

    constructor(message: Message, attachment: Attachment, sender: User) {
        super(message, attachment)
        this.sender = sender;
    }
}

export class FetchParams {
    query: string
    types: number[]
    limit: number

    constructor(query: string, limit: number, types: number[]) {
        this.query = query;
        this.limit = limit;
        this.types = types;
    }
}
import { Send } from "@mui/icons-material";
import { Divider, IconButton, LinearProgress, List, ListItemText, OutlinedInput, Typography } from "@mui/material";
import moment from "moment";
import { LoadingForm } from "./base/LoadingForm";
import { BaseEntity } from "../models/Types";
import { FormEvent } from "react";
import Proxy from "../models/Proxy";
import { getUser } from "../models/Identity";
import './logs.css'

class Log extends BaseEntity {
    message!: string
    type?: string
    createDate!: Date
    userId?: number
    userName?: string

    static parse(x: any) {
        const createDate = x.createDate && moment(x.createDate).toDate();
        var log = new Log();
        Object.assign(log, x, { createDate });
        return log;
    }
}

interface Props {
    refType: string
}

export class Logs extends LoadingForm<Props, { logs: Log[] }> {
    message: any

    constructor(p: any) {
        super(p);

        this.state = Object.assign({
            logs: []
        }, this.state);

        this.onSubmit = this.onSubmit.bind(this);
    }

    protected getCmdGet(): string {
        return 'LogList';
    }

    protected getLoadParams(id: number) {
        return {
            refType: this.props.refType,
            refId: id
        }
    }

    protected dataLoaded(response: any) {
        this.setState({
            logs: response.result.map(Log.parse)
        });
    }

    protected onSubmit(e: FormEvent) {
        e.stopPropagation();
        e.preventDefault();

        if (this.message?.value) {
            let entity = {
                message: this.message.value,
                type: 'remark'
            };

            this.withLoading(() => Proxy.post('LogSave', {
                entity,
                refs: [{
                    type: this.props.refType,
                    id: this.props.id
                }]
            })
                .then(x => {
                    if (x.success) {
                        this.message && (this.message.value = '');

                        const log = new Log();
                        log.createDate = new Date();
                        log.id = x.result as number;
                        log.message = entity.message;
                        log.type = entity.type;
                        log.userId = getUser().id;
                        log.userName = getUser().name;

                        this.state.logs.push(log);
                        this.forceUpdate();
                    }
                }),
                500);
        }
    }

    render() {
        const items: React.ReactNode[] = [];

        this.state?.logs?.sort((a, b) => a.id - b.id).forEach((l, i) => items.push(
            i ? <Divider /> : null,
            <ListItemText
                primary={l.message}
                primaryTypographyProps={{
                    whiteSpace: 'break-spaces'
                }}
                secondary={l.userName + ' ' + moment(l.createDate).format('L LT')}
                secondaryTypographyProps={{ textAlign: 'right' }} />
        ));

        return <div key={this.props.id} className="logs-form" style={{ display: "flex", maxHeight: '100%', flexDirection: 'column' }}>
            <LinearProgress sx={{ p: 0, m: -3, marginBottom: 3 }} style={{ opacity: this.state?.loading == 2 ? 1 : 0 }} />

            {items?.length ?
                <List sx={{ width: '100%', flex: 1, overflow: 'auto' }}>{items}</List> :
                <Typography textAlign="center" color="background">Лента пуста</Typography>}

            <form onSubmit={this.onSubmit}>
                <OutlinedInput inputRef={x => this.message = x} sx={styles.remarkInput} placeholder="Добавить заметку" multiline maxRows={4} endAdornment={<IconButton type="submit"><Send /></IconButton>} />
            </form>
        </div >
    }
}

const styles = {
    remarkInput: {
        bgcolor: 'background.paper'
    }
}
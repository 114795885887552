import { getTenantId } from "./Tenant";

export const root = document.location.port == '3000' ? 'https://localhost:7233/' : '/';

const buildQueryParams = (params: any, cmd: string) => params ? (cmd.indexOf('?') < 0 ? '?' : '&') + buildUrlQuery(params) : '';
export const buildUrl = (cmd: string, params?: any) => `${root}api/${getTenantId() || 1}/${cmd}${buildQueryParams(params, cmd)}`;
export function buildUrlQuery(obj: any) {
	var str = [];
	for (var p in obj) {
		if (obj.hasOwnProperty(p)) {
			str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
		}
	}

	return str.join("&");
}

export default {
	get(cmd: string, params?: any) {
		return fetch(buildUrl(cmd, params), {
			credentials: 'include'
		}).then(x => x.json());
	},

	post(cmd: string, body?: any, params?: any) {
		return fetch(buildUrl(cmd, params), {
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body),
			method: 'POST',
			credentials: 'include'
		}).then(x => x.json());
	}
};
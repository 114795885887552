import { useTheme } from '@emotion/react';
import { CheckBoxOutlined, CheckCircleOutline, Edit, ExpandLess, ExpandMore, Filter, FilterAlt, FilterList, Reorder, SquareOutlined, SwapVert } from '@mui/icons-material';
import { Box, Button, Checkbox, InputAdornment, Link, OutlinedInput, SxProps, Theme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { isDate, isMoment } from 'moment';
import React, { CSSProperties } from 'react';
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, Droppable, DroppableProvided, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { TableComponents, TableVirtuoso, TableVirtuosoProps } from 'react-virtuoso';
import CachedStore from '../../models/CachedStore';
import Proxy from '../../models/Proxy';
import { IEntity } from '../../models/Types';
import route from '../../Router';
import { arrayReplace, isFunc } from '../../utils';
import TriStateCheckbox from '../form/TriStateCheckbox';
import { ListApiRef } from './ApiRef';
import './datatable.css';
import NavLink from '../NavLink';

const minDate = new Date(1900, 1, 1);

type filterBuilder = (onChange: (v: any) => void) => React.ReactNode;
type filterFunction<T> = (v: any, entity: T) => boolean;

type Align = 'inherit' | 'left' | 'center' | 'right' | 'justify';
type FieldType = 'string' | 'boolean' | 'numeric' | 'date' | 'array';

enum SortDirection {
    Asc = -1,
    None = 0,
    Desc = 1,
}

export type RouteParamsApplier = (routeParams: [string, any?][]) => [string, any?][] | undefined;

export type Renderer<T extends IEntity> = (val: any, obj: T, theme: Theme, table: BaseDataTable<T>) => React.ReactNode;
export interface ColumnData<T extends IEntity> {
    name: keyof T;
    label?: string;
    align?: Align;
    type?: FieldType,
    filter?: boolean | string | filterBuilder;
    filterFn?: filterFunction<T>
    width?: number;
    renderer?: Renderer<T>;
    sx?: (val?: any, obj?: T, theme?: Theme) => (SxProps<Theme> | undefined | null);
    store?: CachedStore,
    dnd?: boolean,
    sortDirection?: SortDirection;
    sort?: boolean;
    routerLink?: boolean;
    routerParams?: RouteParamsApplier;
    rowSelector?: boolean
}

export type RowSelector = 'simple' | 'routerLink';

export interface DataTableProps<T extends IEntity> extends TableVirtuosoProps<T, any> {
    columns: ColumnData<T>[];
    reorderCmd?: string
    typeId?: string
    cmd?: string
    selectedId?: number
    anyFilter?: string,
    showFilter?: boolean,
    route?: {
        params?: {
            [name: string]: string | undefined
        },
        id?: string,
        root?: string
    },
    rowSelector?: RowSelector
    rowSelectorBorder?: boolean
    loadParams?: any
    routerParams?: RouteParamsApplier
    dataParser?: (dataJson: any) => T
    apiRef?: (api?: ListApiRef<T>) => void
    onSelectionChange?: (selectedIds: number[], table: BaseDataTable<T>) => void;
    onBeforeLoad?: (table: BaseDataTable<T>) => boolean | void;
    onAfterLoad?: (data: T[]) => void;
}

export interface ThemeProps<T extends IEntity> extends DataTableProps<T> {
    theme: Theme
}

interface State<T> {
    data?: T[],
    selectedIds: number[],
    filteredData?: T[],
    loading: boolean,
    callbackWidget?: React.ReactNode
}

interface HeadRowProps<T extends IEntity> extends DataTableProps<T> {
    onFilterChange: (filters: any) => void
    onSortChange: () => void
}

interface HeadRowState {
    filters?: any
}

const sortIcons = {
    [-1]: <ExpandLess />,
    [0]: <SwapVert color="disabled" />,
    [1]: <ExpandMore />,
    next(cur?: number) {
        return cur == 1 ? 0 : cur == -1 ? 1 : -1;
    }
}

const aligns: { [name in FieldType]: Align } = {
    string: 'left',
    date: 'left',
    array: 'left',
    boolean: 'center',
    numeric: 'right'
}

const widths: { [name in FieldType]?: number | undefined } = {
    date: 200,
    boolean: 100
}

class HeadRow<T extends IEntity> extends React.Component<HeadRowProps<T>, HeadRowState>{
    buildFilterAdorment() {
        return <InputAdornment position="start"><FilterList /></InputAdornment>;
    }

    buildFilterTitle(col: ColumnData<T>) {
        return 'Фильтр' + (col.label ? ' по полю ' + col.label : '');
    }

    buildFilter(col: ColumnData<T>) {
        if (this.props.showFilter !== false && col.filter) {
            let filter = col.filter === true ? col.type : col.filter;
            switch (filter) {
                case 'date': return <DatePicker
                    value={this.state?.filters ? this.state.filters[col.name] || null : null}
                    onChange={v => this.onFilterChange(col.name, v)}
                    renderInput={(params) => <TextField {...params}
                        title={this.buildFilterTitle(col)}
                        inputProps={{
                            ...params.inputProps,
                            placeholder: undefined
                        }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: this.buildFilterAdorment()
                        }} />} />;

                case 'boolean':
                    var fv = this.state?.filters ? this.state.filters[col.name] : null;
                    return <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <TriStateCheckbox
                            root={{
                                title: this.buildFilterTitle(col)
                            }}
                            value={fv}
                            onChange={e => this.onFilterChange(col.name, e.target.value)} />
                    </div>

                default:
                    if (isFunc(col.filter)) {
                        return (col.filter as filterBuilder)(v => this.onFilterChange(col.name, v));
                    } else {
                        return <OutlinedInput
                            title={this.buildFilterTitle(col)}
                            sx={{ width: '100%' }}
                            onChange={e => this.onFilterChange(col.name, e.target.value)}
                            startAdornment={this.buildFilterAdorment()} />;
                    }
            }
        }
    }

    private onFilterChange(name: keyof T, value: any) {
        var filters = {
            ...this.state?.filters,
            [name]: value
        };

        this.setState({ filters });

        this.props.onFilterChange(filters);
    }

    private onSortChange(column: ColumnData<T>) {
        column.sortDirection = sortIcons.next(column.sortDirection);

        this.forceUpdate();

        this.props.onSortChange();
    }

    render() {
        return <TableRow>
            {this.props.columns.map((column, i) => (
                <TableCell
                    key={column.name.toString()}
                    data-name={column.name.toString()}
                    variant="head"
                    align={column.align}
                    style={{ width: column.width }}
                    sx={styles.headCell}
                >
                    {column.label &&
                        <Button variant="text" sx={styles.headCellButton} color="inherit" fullWidth onClick={() => this.onSortChange(column)}>
                            {column.label}
                            {column.sort === false ? null : sortIcons[column.sortDirection || 0]}
                        </Button>}
                    {this.buildFilter(column)}
                </TableCell>
            ))}
        </TableRow>
    }
}

type FilterFunction = ((x: any) => boolean);
export abstract class BaseDataTable<T extends IEntity, TProps extends ThemeProps<T> = ThemeProps<T>> extends React.Component<TProps, State<T>> {
    private ffs: FilterFunction[] = []
    private _unlisten: Function[]

    protected key = 0;

    constructor(p: TProps) {
        super(p);

        this.fixedHeaderContent = this.fixedHeaderContent.bind(this);
        this.rowContent = this.rowContent.bind(this);

        this.onRowClick = this.onRowClick.bind(this);

        this._unlisten = [];

        this.applyColumns(p);
    }

    componentDidMount(): void {
        this.applyRefApi();

        this.setState({
            loading: true,
            selectedIds: this.props.selectedId ? [this.props.selectedId] : []
        });

        this.props.columns.forEach(x => x.store?.load());

        this.loadData();
    }

    componentWillReceiveProps(nextProps: Readonly<TProps>, nextContext: any): void {
        this.applyColumns(nextProps);
    }

    componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<State<T>>, snapshot?: any): void {
        if (prevProps.anyFilter != this.props.anyFilter) {
            this.applyData();
        }
    }

    componentWillUnmount(): void {
        this._unlisten.forEach(x => x());
    }

    render() {
        if (!this.state?.data) {
            return <></>;
        }

        return <>
            {this.renderTable()}
            {this.state.callbackWidget}
        </>;
    }

    renderCallback(callbackWidget: (callback: (id?: number | number[]) => void) => React.ReactNode) {
        var d = () => this.setState({ callbackWidget: undefined });

        this.setState({ callbackWidget: callbackWidget(id => id ? this.updateRows(id).then(d) : d()) });
    }

    protected abstract renderTable(): React.ReactNode

    public getData() {
        return (this.state.filteredData || this.state.data)!;
    }

    protected fixedHeaderContent() {
        return <HeadRow<T> key="heads"
            {...this.props}
            onSortChange={() => this.applyData()}
            onFilterChange={filters => {
                this.ffs = [];
                for (var f in filters) {
                    let fv = filters[f];
                    if (fv !== undefined && fv !== null && fv !== '') {
                        this.ffs.push(this.buildFilterFunction(f, fv));
                    }
                }

                this.applyData();
            }} />;
    }

    protected onRowClick(e: any) {
        const id = e.target.getAttribute('data-id') || e.target.parentElement.getAttribute('data-id');
        if (id && this.props.rowSelector) {
            switch (this.props.rowSelector) {
                case 'routerLink':
                    let rp = this.rowRouterParams(id, this.props.routerParams);
                    rp && route.setState(rp);
                    break;

                case 'simple':
                    this.selectItem(id, true);
                    break;
            }
        }
    }

    protected rowContent(_index: number, row: T): React.ReactNode {
        const result = this.props.columns.map((column, i) => (
            <TableCell key={column.name.toString()}
                align={column.align}
                sx={{ fontSize: '1.3rem', ...(column.sx && column.sx(row[column.name], row, this.props.theme)) }}>
                {column.renderer!(row[column.name], row, this.props.theme, this)}
            </TableCell>
        ));

        if (this.props.rowSelectorBorder && this.props.selectedId == row.id) {
            result.splice(0, 0, <Box sx={styles.rowSelectorBorder(this.props.theme)} />);
        }

        return result;
    }

    protected selectItem(id: number, selected: boolean) {
        const s = this.state.selectedIds;
        if (this.props.multiple) {
            const i = s.indexOf(id);
            if (i >= 0) {
                s.splice(i, 1);
            }

            if (selected) {
                s.push(id);
            }
        } else {
            if (selected) {
                s[0] = id;
                s.length = 1;
            } else {
                s.length = 0;
            }
        }

        this.forceUpdate();

        this.props.onSelectionChange && this.props.onSelectionChange(s, this);
    }

    protected applyColumns(p: ThemeProps<T>) {
        if (this.props.multiple) {
            let cc = p.columns.find(x => x.rowSelector),
                renderer = (id: number) => <Checkbox
                    defaultChecked={this.state.selectedIds?.indexOf(id) >= 0}
                    onChange={(e, c) => this.selectItem(id, c)} />;

            if (cc) {
                cc.renderer = renderer;
            } else {
                p.columns.splice(0, 0, {
                    name: 'id',
                    type: 'boolean',
                    rowSelector: true,
                    renderer
                });
            }
        }

        p.columns.forEach(c => {
            if (c.routerLink) {
                if (!(c.renderer as any)?._rendererSet) {
                    const _renderer = c.renderer;
                    c.renderer = (val, obj, theme, table) => {
                        if (val || _renderer) {
                            return <NavLink route={this.rowRouterParams(obj!.id, c.routerParams)} sx={styles.navLink}>
                                {_renderer ? _renderer(val, obj, theme, table) : val}
                                <Edit className="routerlink-icon" />
                            </NavLink>;
                        };
                    }
                    (c.renderer as any)._rendererSet = true;
                }
            } else {
                if (!c.renderer) {
                    switch (c.type) {
                        case 'boolean':
                            c.renderer = val => (val === 1 || val === true) ?
                                <CheckCircleOutline color="success" /> :
                                /** (val === 0 || val === false) ? <SquareOutlined /> :/**/
                                null;
                            break;

                        case 'date':
                            c.renderer = d => (d && d > minDate) ? moment(d).calendar() : null
                            break;

                        default:
                            c.renderer = val => val as React.ReactNode;
                            break;
                    }
                }
            }

            if (!c.align && c.type) {
                c.align = aligns[c.type];
            }

            if (c.width === undefined && c.type) {
                c.width = widths[c.type];
            }

            if (c.store) {
                this._unlisten.push(c.store.onLoad(() => this.forceUpdate()));
            }
        });
    }

    protected rowRouterParams(id: number, routeParamsApplier?: RouteParamsApplier) {
        const routeParams: [string, any?][] | undefined = [
            [this.props.route?.id || 'id', id],
            ['hideForm'],
            ['parentId']
        ];
        return routeParamsApplier ? routeParamsApplier(routeParams) : routeParams;
    }

    protected getSelected() {
        return this.state.selectedIds;
    }

    protected load() {
        return this.loadData();
    }

    protected deleteRow(id: number) {
        if (this.state.data) {
            const i = this.state.data?.findIndex(x => x.id == id);
            if (i >= 0) {
                this.state.data.splice(i, 1);
                this.forceUpdate();
                return true;
            }
        }

        return false;
    }

    protected updateRows(id: number | number[]) {
        return Proxy.get(this.props.cmd || (this.props.typeId + 'List'), {
            query: JSON.stringify({
                filters: [{
                    property: 'Id',
                    value: JSON.stringify(id)
                }]
            })
        }).then(x => {
            if (x.success) {
                let data = x.result.data || x.result;
                let dataIds: number[] = [];
                let callback = () => this.props.onAfterLoad && this.props.onAfterLoad(this.state.data!);
                let result = data.map((x: any) => {
                    var entity = this.parse(x);
                    if (entity) {
                        dataIds.push(entity!.id);
                        if (this.state.data) {
                            arrayReplace(this.state.data, x => x.id == entity.id, entity);
                            this.forceUpdate(callback);
                        } else {
                            this.setState({ data: [entity] }, callback);
                        }
                    }

                    return entity;
                });

                (Array.isArray(id) ? id : [id])
                    .filter(id => dataIds.indexOf(id) < 0)
                    .forEach(id => this.deleteRow(id));

                return result;
            }
        })
    }

    private applyRefApi() {
        this.props.apiRef && this.props.apiRef({
            getSelected: this.getSelected.bind(this),
            load: this.load.bind(this),
            getData: this.getData.bind(this),
            delete: this.deleteRow.bind(this),
            update: this.updateRows.bind(this)
        });
    }

    private parse(jsonData: any) {
        return this.props.dataParser ? this.props.dataParser(jsonData) : jsonData;
    }

    private loadData() {
        if (this.props.onBeforeLoad && this.props.onBeforeLoad(this) === false) {
            return Promise.resolve([]);
        }

        return Proxy.get(this.props.cmd || (this.props.typeId + 'List'), this.getLoadParams())
            .then(x => {
                const data = (Array.isArray(x.result) ? x.result : x.result.data)?.map((x: any) => this.parse(x));
                this.props.onAfterLoad && this.props.onAfterLoad(data);
                this.applyData(data);
            });
    }

    protected getLoadParams(): any {
        return this.props.loadParams;
    }

    protected applyData(data?: T[]) {
        const hasData = !!data;
        if (!data) {
            data = this.state.data;
        }

        var filteredData = this.ffs.length ? data?.filter(d => !this.ffs.find(ff => !ff(d))) : undefined;
        if (this.props.anyFilter) {
            var affs = this.props.columns.map(c => this.buildFilterFunction(c.name.toString(), this.props.anyFilter?.toLowerCase()));
            filteredData = (filteredData || data)?.filter(d => affs.find(ff => ff(d)))
        }

        const sfs: ((a: T, b: T) => -1 | 0 | 1)[] = [];
        this.props.columns
            .forEach(c => {
                if (c.sortDirection) {
                    const dir = c.sortDirection > 0 ? -1 : 1;
                    const mdir = c.sortDirection > 0 ? 1 : -1;
                    sfs.push((a, b) => a[c.name] < b[c.name] ? mdir : a[c.name] > b[c.name] ? dir : 0);
                }
            });

        if (sfs.length) {
            filteredData = (filteredData || data)?.sort((a, b) => {
                var s: number;
                for (var i = 0; i < sfs.length; i++) {
                    s = sfs[i](a, b);
                    if (s != 0) {
                        return s;
                    }
                }

                return 0;
            });
        }

        this.key++;

        this.setState({
            filteredData,
            data,
            selectedIds: hasData && this.state.selectedIds ? [] : this.state.selectedIds,
            loading: false
        });
    }

    private buildFilterFunction(f: string, filterValue: any) {
        const col = this.props.columns.find(x => x.name == f);
        const ff = col?.filterFn;
        if (ff) {
            return (x: any) => ff(filterValue, x as T);
        }
        else if (Array.isArray(filterValue)) {
            let fa = filterValue as any[];
            return (x: any) => (fa?.indexOf(x[f]) >= 0);
        } else if (typeof filterValue == typeof '') {
            switch (col?.type) {
                case 'date':
                    return (x: any) => x[f] && (
                        (x[f] as any).toString().toLowerCase().indexOf(filterValue) >= 0 ||
                        moment(x[f] as Date).calendar().toLowerCase().indexOf(filterValue) >= 0
                    );
                default:
                    return (x: any) => x[f] && (x[f] as any).toString().toLowerCase().indexOf(filterValue) >= 0;
            }
        } else if (isDate(filterValue) || isMoment(filterValue)) {
            let d = (isMoment(filterValue) ? filterValue.toDate() : filterValue) as Date;
            let nextDay = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1);
            return (x: any) => x[f] >= filterValue && x[f] < nextDay;
        } else {
            return (x: any) => filterValue == x[f];
        }
    }
}

class DataTableComponent<T extends IEntity> extends BaseDataTable<T> {
    protected scrollerRef?: any
    protected _virtuosoTableComponents: TableComponents<T>

    constructor(p: ThemeProps<T>) {
        super(p);

        this._virtuosoTableComponents = {
            Scroller: React.forwardRef<HTMLDivElement>((props, ref) => <TableContainer component={Paper} {...props} ref={ref} />),
            Table: (props) => <Table {...props} style={{ borderCollapse: 'separate' }} />,
            TableHead,
            TableRow: ({ item: _item, ...props }) => <TableRow {...props}
                {...{ 'data-id': _item.id }}
                hover={true}
                onClick={this.onRowClick}
                sx={{ position: 'relative' }}
                selected={!this.props.rowSelectorBorder && (this.props.selectedId == _item.id || this.props.multiple && this.state.selectedIds?.indexOf(_item.id) >= 0)} />,
            TableBody: React.forwardRef<HTMLTableSectionElement>((props, ref) => <TableBody {...props} ref={ref} />),
        };
    }

    renderTable() {
        if (this.props.multiple) {
            return <TableVirtuoso
                scrollerRef={this.scrollerRef}
                style={{ border: 'none', boxShadow: 'none', ...this.props.style }}
                context={{ loading: this.state.loading }}
                data={this.getData()}
                multiple={this.props.multiple}
                components={this._virtuosoTableComponents}
                fixedHeaderContent={this.fixedHeaderContent}
                itemContent={this.rowContent}
                className={this.props.className}
            />;
        }

        return <TableContainer
            component={Paper}
            className={this.props.className}
            style={{ border: 'none', boxShadow: 'none', ...this.props.style }}>
            <Table style={{ borderCollapse: 'separate' }} stickyHeader={true}>
                <TableHead>
                    {this.fixedHeaderContent()}
                </TableHead>
                <TableBody>
                    {this.getData().map((item, i) => <TableRow
                        {...{ 'data-id': item.id }}
                        hover={true}
                        onClick={this.onRowClick}
                        sx={{ position: 'relative' }}
                        selected={!this.props.rowSelectorBorder && (this.props.selectedId == item.id || this.props.multiple && this.state.selectedIds?.indexOf(item.id) >= 0)}>
                        {this.rowContent(i, item)}
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>;
    }
}

export interface DraggableProps<T extends IEntity> extends ThemeProps<T> {
    reorderCmd: string
}

export class DraggableDataTableComponent<T extends IEntity> extends BaseDataTable<T, DraggableProps<T>>{
    protected draggableProvided?: DraggableProvided;

    constructor(p: DraggableProps<T>) {
        super(p);

        this.onDragEnd = this.onDragEnd.bind(this);
    }

    protected buildRow(row: T, i: number, draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot) {
        const style: CSSProperties = {
            ...draggableProvided.draggableProps.style,
            width: '100%'
        };

        if (snapshot.isDragging) {
            style.background = "rgba(245,245,245, 0.75)";
        }

        return <TableRow
            ref={draggableProvided.innerRef}
            onClick={this.onRowClick}
            {...{ 'data-id': row.id }}
            selected={!this.props.rowSelectorBorder && (this.props.selectedId == row.id || this.props.multiple && this.state.selectedIds?.indexOf(row.id) >= 0)}
            {...draggableProvided.draggableProps}
            style={style}
        >
            {this.rowContent(i, row)}
        </TableRow>
    }

    protected buildRows(data: T[]) {
        return data.map((row, i) => <Draggable key={row.id} draggableId={row.id.toString()} index={i}>
            {(draggableProvided, snapshot) => {
                this.draggableProvided = draggableProvided;
                return this.buildRow(row, i, draggableProvided, snapshot);
            }}
        </Draggable>);
    }

    protected buildBody(data: T[], provided: DroppableProvided) {
        return <TableBody ref={provided.innerRef} {...provided.droppableProps}>
            {this.buildRows(data)}
            {provided.placeholder}
        </TableBody>
    }

    protected renderTable(): React.ReactNode {
        const data = this.getData();

        return <TableContainer sx={{ maxHeight: '100&' }}>
            <Table stickyHeader={true}>
                <TableHead>
                    {this.fixedHeaderContent()}
                </TableHead>
                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" direction="vertical">
                        {(provided) => this.buildBody(data, provided)}
                    </Droppable>
                </DragDropContext>
            </Table>
        </TableContainer>;
    }

    protected applyColumns(p: ThemeProps<T>) {
        p.columns.forEach(c => {
            if ((!c.renderer || (c.renderer as any)._dnd) && c.dnd) {
                c.renderer = () => <div {...this.draggableProvided?.dragHandleProps}><Reorder /></div>;
                (c.renderer as any)._dnd = true;
            }
        });

        super.applyColumns(p);
    }

    private onDragEnd(result: DropResult, provided: ResponderProvided) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }

        let data = this.getData();
        let after = data[destination.index - 1] as any;
        let statusId = data[source.index].id;

        const idIndxs: { [name: string]: number } = {};
        this.state.data?.forEach((x: any) => {
            idIndxs[x.id.toString()] = x.index;

            if (after) {
                if (x.id == statusId) {
                    x.index = after.index + 1;
                } else if (x.index > after.index) {
                    x.index++;
                }
            } else if (x.id == statusId) {
                x.index = 0;
            } else {
                x.index++;
            }
        });

        this.applyData();

        Proxy.post(this.props.reorderCmd, after?.id, { statusId })
            .then(r => {
                if (!r.success) {
                    this.state.data?.forEach((x: any) => x.index = idIndxs[x.id.toString()]);
                    this.applyData();
                }
            })
    }
}

export default function DataTable<T extends IEntity>(props: DataTableProps<T>) {
    const theme = useTheme();
    return props.reorderCmd ?
        <DraggableDataTableComponent<T> {...({ ...props, theme } as DraggableProps<T>)} /> :
        <DataTableComponent<T> {...({ ...props, theme } as ThemeProps<T>)} />;
}

const styles = {
    navLink: {
        color: 'primary.main',
        textDecoration: 'none'
    },
    headCell: {
        backgroundColor: 'background.paper',
        p: 1,
    },
    headCellButton: {
        fontWeight: 'normal',
        textTransform: 'none',
        fontSize: '0.925rem'
    },
    rowSelectorBorder: (theme: Theme) => {
        return {
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: '10px',
            backgroundColor: theme.palette.secondary.main
        }
    }
}
import { Button, Card, CardActions, CardContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { PaymentState } from "../../models/Enums";
import { useState } from "react";
import { ISuggest } from "../../models/Types";
import Proxy from "../../models/Proxy";

interface Props {
    amount: number
    currencyId?: number
    setState: (state: PaymentState) => void
}

export default function PaymentForm({ amount, currencyId, setState }: Props) {
    const [state, setPaymentState] = useState(PaymentState.Open);
    const [accounts, setAccounts] = useState<ISuggest[]>();

    if (!accounts) {
        Proxy.get('AccountSuggest').then(x => {
            const data: ISuggest[] = [];
            for (var id in x.result) {
                data.push({ id: parseInt(id), value: x.result[id] });
            }

            setAccounts(data);
        });
    }

    function _setState(state: PaymentState) {
        setPaymentState(state);
        setState(state);
    }

    function pay() {

    }

    return <Card elevation={5} sx={{ flex: 1 }}>
        <CardContent>
            <FormControl>
                <FormLabel>Выберите способ оплаты</FormLabel>
                <RadioGroup>
                    {accounts?.map(x => <FormControlLabel value={x.id} control={<Radio />} label={x.value} />)}
                </RadioGroup>
            </FormControl>
        </CardContent>

        <CardActions sx={{ p: 2 }}>
            <Button onClick={pay} disabled={state == PaymentState.Payment} variant="contained">
                {state == PaymentState.Payment ? 'Произодится оплата...' : 'Оплатить'}
            </Button>
            {state == PaymentState.Payment ?
                null :
                <Button style={{ marginLeft: 'auto' }} onClick={() => _setState(PaymentState.None)}>Отмена</Button>}
        </CardActions>
    </Card>;
}